import { IDepartment } from '@/app/shared/models'
export class IUser {
  id: string
  name?: string
  avatar?: string
  position: string
  department?: IDepartment
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  roles?: string[] = []
  inviteDate?: string
  status?: string
  timeZone?: string
}