import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CORE_CONFIG } from '../core.config'

@Injectable({
  providedIn: 'root'
})
export class OcpApimKeyInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      setHeaders: {
        'Ocp-Apim-Subscription-Key': CORE_CONFIG.OCP_APIM_SUBSCRIPTION_KEY
      }
    })

    return next.handle(modifiedRequest)
  }
}
