// ang
import { Component, OnInit, Input } from '@angular/core'

// lib

// app

@Component({
  selector: 'ef-search-global-results-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class SearchGlobalResultsActionsComponent implements OnInit {
  @Input() searchValue: string

  constructor() {}

  ngOnInit() {}
}
