// ang
import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'ef-hightlight-search-text',
  templateUrl: './hightlight-search-text.component.html',
  styleUrls: ['./hightlight-search-text.component.scss'],
})
export class HightlightSearchTextComponent implements OnInit {
  @Input() searchValue: string
  @Input() value: string

  constructor() {}

  ngOnInit() {}

  init() {}
}
