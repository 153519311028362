// types - 'user', 'process', 'action' ....
// each type has own route path.
// the route path have to exist and if it will changed app will have err.
// for avoid of error each route path (existing) have to be tested in tests

export class ISearchGlobalHintType {
  value: string
  title: string
  icon: string
  routePath: string // !!! very important to check exists of all routes that defined into core config file
}
