// ang
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ISearchGlobalFilterItem, ISearchGlobalResultProcess } from '../../../../../core/models'
// lib
// app
import { SearchGlobalHttpService } from '../../../../../core/services'

@Component({
  selector: 'ef-search-global-results-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss']
})
export class SearchGlobalResultsProcessesComponent implements OnInit, OnChanges {
  @Input() searchValue: string

  userList: ISearchGlobalFilterItem[] = []
  folderList: ISearchGlobalFilterItem[] = []
  processList: ISearchGlobalResultProcess[] = []
  throttle: any[] = []
  selectedFolderIdList: string[]
  selectedAssignedToIdList: string[]

  constructor(private _httpService: SearchGlobalHttpService) {}

  ngOnInit() {
    this._getUserListAsync()
    this._getFolderListAsync()
    this._updateView()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchValue && !changes.searchValue.firstChange) {
      this._updateViewWithDelay(1000)
    }
  }

  setSelectedFolderIdList(folderIdList: string[]) {
    this.selectedFolderIdList = folderIdList
    this._updateViewWithDelay(1000)
  }

  setSelectedAssignedToIdList(assignedToIdList: string[]) {
    this.selectedAssignedToIdList = assignedToIdList
    this._updateViewWithDelay(1000)
  }

  private _updateViewWithDelay(delayMs: number) {
    this.throttle.forEach(func => clearTimeout(func))
    const delay = setTimeout(() => {
      this._updateView()
    }, delayMs)
    this.throttle.push(delay)
  }

  private _updateView() {
    this._httpService
      .getProcessList({
        searchValue: this.searchValue,
        userIdList: this.selectedAssignedToIdList,
        folderIdList: this.selectedFolderIdList
      })
      .subscribe(res => {
        this.processList = res
      })
  }

  private _getUserListAsync() {
    this._httpService.getTeamList().subscribe(res => {
      this.userList = res.map(f => {
        return {
          id: f.id,
          name: f.name,
          isSelected: false
        }
      })
    })
  }

  private _getFolderListAsync() {
    this._httpService.getFolderList().subscribe(res => {
      this.folderList = res.map(f => {
        return {
          id: f.id,
          name: f.name,
          isSelected: false
        }
      })
      if (this.folderList) {
        this._sortByAlphabit(this.folderList)
      }
    })
  }

  private _sortByAlphabit(list: ISearchGlobalFilterItem[]) {
    list.sort(function(a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }
}
