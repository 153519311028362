// ang
import { Component, OnInit, Input } from '@angular/core'

// app
import * as models from '../../../../../core/models'
import { NotificationService } from '../../../../../core/services'

@Component({
  selector: 'ef-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: models.IAppNotification

  constructor(private _notificationService: NotificationService) {}

  ngOnInit() {}

  close() {
    this._notificationService.deleteNotificPush(this.notification.id)
  }
}
