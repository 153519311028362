// ang
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
// app
import { SAVE_CHANGES_ACTIONS } from '../../../shared/enum'

@Component({
  selector: 'ef-save-changes',
  templateUrl: './save-changes.component.html',
  styleUrls: ['./save-changes.component.scss']
})
export class SaveChangesComponent implements OnInit {
  message: string = undefined
  onSaveLoader: boolean = false

  @Output() onAction: EventEmitter<string> = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  save() {
    this.onAction.emit(SAVE_CHANGES_ACTIONS.SAVE)
  }

  dontSave() {
    localStorage.clear()
    this.onAction.emit(SAVE_CHANGES_ACTIONS.DONT_SAVE)
  }

  cancel() {
    this.onAction.emit(SAVE_CHANGES_ACTIONS.CANCEL)
  }
}
