import {
  Directive,
  ElementRef,
  SimpleChanges,
  OnChanges,
  Input,
} from '@angular/core'

@Directive({
  selector: '[efAutoFocus]',
})
export class AutoFocusDirective implements OnChanges {
  @Input() efAutoFocusIsFocused: boolean = false

  constructor(private _elemRef: ElementRef) {}

  ngOnInit() {
    if (this.efAutoFocusIsFocused) {
      setTimeout(() => {
        this._elemRef.nativeElement.focus()
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.efAutoFocusIsFocused && !changes.efAutoFocusIsFocused.firstChange) {
      setTimeout(() => {
        changes.efAutoFocusIsFocused ? this._elemRef.nativeElement.focus() : this._elemRef.nativeElement.blur()
      })
    }
  }
}
