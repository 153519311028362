// ang
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { NgSelectComponent } from '@ng-select/ng-select'
// app
import * as models from '../../../shared/models'

interface IUserSelectorItem {
  type: 'all' | 'role' | 'user'
  id: string
  name?: string
  link?: models.IUser
  isCollapsed?: boolean
  isHidden?: boolean
  isSelected?: boolean
  childs?: Array<IUserSelectorItem>
  parent?: IUserSelectorItem
}

@Component({
  selector: 'ef-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit, OnChanges {
  @Input() currentUsersIds: string[] = [] // selected
  @Input() currentRoleNames: string[] = [] // selected
  @Input() roleNamesList: models.IRoleName[] = [] // full list
  @Input() userList: models.IUser[] = [] // full list
  @Input() multiple = true // todo - create single (not multiple)

  // form
  @Input() parentForm: FormGroup // todo
  @Input() submitted: boolean
  @Input() controlName: string
  @Input() required: boolean

  // view
  @Input() disabled = false
  @Input() showErrorStyles = false
  @Input() placeholder: string

  isOpen = false
  searchValue: string
  formControl: FormControl
  domId: string
  roleHashMap = {}
  selectedRoles = {}
  isAllCollapsed: boolean
  isAllSelected: boolean
  selectedList: IUserSelectorItem[]
  selectedRolesHashMap: object
  selectedUsersHashMap: object
  hiddenRoles: object = {} // by toggle
  abstractControl: AbstractControl
  items: IUserSelectorItem[]

  @Output() changeSelected: EventEmitter<models.IUserSelectorOutput> = new EventEmitter<models.IUserSelectorOutput>()
  @Output() open = new EventEmitter()
  @Output() close = new EventEmitter()

  @ViewChild('ngSelect') ngSelect: NgSelectComponent

  constructor() {
    this.domId = 'ef-user-selector-' + Math.round(Math.random() * 10000000)
  }

  ngOnInit() {
    // form binding - todo
    const validators = [this.required ? Validators.required : null].filter(validator => validator)
    this.controlName = this.controlName || 'user_selector_' + Math.ceil(Math.random() * 10000)
    if (!this.parentForm) {
      this.parentForm = new FormGroup({})
    }
    this.formControl = new FormControl(undefined, validators)
    this.parentForm.addControl(this.controlName, this.formControl)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roleNamesList) {
      this._setRoleNames(changes.roleNamesList.currentValue)
      this._sortRoles()
    }

    if (changes.userList) {
      this._createModel(changes.userList.currentValue)
      this._setRoleNames(this.roleNamesList)
      this._sortRoles()
      this._initSelectionForTreeModel()
    }

    if (changes.currentUsersIds) {
      this._initSelectionForTreeModel()
    }

    if (changes.currentRoleNames) {
      this._initSelectionForTreeModel()
    }

    if (changes.disabled && !changes.disabled.firstChange) {
      if (changes.disabled.currentValue) {
        this.parentForm.controls[this.controlName].disable()
      } else {
        this.parentForm.controls[this.controlName].enable()
      }
    }
  }

  // set names of positions to tree model  that was got from server
  private _setRoleNames(roleNamesList: models.IRoleName[]): void {
    if (roleNamesList && this.items) {
      roleNamesList.forEach((item: models.IRoleName) => {
        const roleTree = this.items[0].childs.find(i => i.id === item.id)
        if (roleTree) {
          roleTree.name = item.name
        }
      })
    }
  }

  private _createModel(usersList: models.IUser[]): void {
    if (!usersList) return
    const all: IUserSelectorItem = {
      type: 'all',
      id: 'all',
      name: 'all',
      childs: []
    }
    usersList.forEach(user => {
      const roles: IUserSelectorItem[] = all.childs.filter(i => user.roles.includes(i.name))
      if (roles.length > 0) {
        roles.forEach(role => {
          this.setUsersAndRoles(user, role, all)
        })
      } else {
        this.setUsersAndRoles(user, undefined, all)
      }
    })

    this.items = [all]
    this._sortRoles()
  }

  private setUsersAndRoles(user: models.IUser, role: IUserSelectorItem, all: IUserSelectorItem) {
    const userTree: IUserSelectorItem = {
      type: 'user',
      id: user.id,
      name: user.name,
      link: user
    }
    if (role) {
      role.childs.push(userTree)
      userTree.parent = role
    } else {
      user.roles.forEach(userRole => {
        role = {
          type: 'role',
          id: userRole,
          name: userRole,
          childs: [userTree],
          parent: all
        }
        all.childs.push(role)
        userTree.parent = role
      })
    }
  }

  // sort roles
  private _sortRoles() {
    if (!this.items) return
    this.items[0].childs.sort(function(a, b) {
      if (a.name < b.name) {
        return 1
      }
      if (a.name > b.name) {
        return -1
      }
      return 0
    })
    this.items[0].childs = [...this.items[0].childs]
    this.items = [...this.items]
  }

  // selection - set for childs
  private _setSelectedValueForChilds(item: IUserSelectorItem, val: boolean): void {
    if (item.childs && item.childs.length > 0) {
      item.childs.forEach(i => {
        i.isSelected = val
      })
    }
  }

  // selection
  changeSelectStatus(ev: any, item: IUserSelectorItem): void {
    ev.stopPropagation()
    const newVal = !item.isSelected
    item.isSelected = newVal
    if (item.type === 'all') this._setSelectedValueForChilds(item, newVal)
    this._updateSelectionStatusForTree()
    this._onValueChangeEvent()
  }

  // selection - update tree
  private _updateSelectionStatusForTree(): void {
    const all = this.items[0]
    all.childs.forEach(role => {
      const users = role.childs
      let selectedUsersAm = 0
      users.forEach(user => {
        user.isSelected ? selectedUsersAm++ : null
      })
    })
  }

  // set selection for childs and parents
  private _initSelectionForTreeModel(): void {
    if (this.items && this.items[0]) {
      const all = this.items[0]
      all.childs.forEach(role => {
        if (this.currentRoleNames && this.currentRoleNames.indexOf(role.id) > -1) {
          role.isSelected = true
        }
        role.childs.forEach(user => {
          if (this.currentUsersIds && this.currentUsersIds.indexOf(user.id) > -1) {
            user.isSelected = true
          }
        })
      })
      this._updateSelectionStatusForTree()
      this._createSelectedList()
    }
  }

  // selected list preview
  private _createSelectedList() {
    const selectedUsers = []
    const selectedRoleNames = []
    const all = this.items[0]
    all.childs.map(role => {
      if (role.isSelected) {
        selectedRoleNames.push(role)
      }
      role.childs.map(user => {
        if (user.isSelected) {
          selectedUsers.push(user)
        }
      })
    })
    this.selectedList = [].concat(selectedRoleNames, selectedUsers)
    this.selectedList = this.selectedList.filter((n, i) => this.selectedList.indexOf(n) === i)
    this._setNgSelectMultiLabelTmplAvailability()
  }

  private _setNgSelectMultiLabelTmplAvailability(): void {
    if (this.selectedList.length > 0) {
      if (this.ngSelect) {
        this.ngSelect.select([1])
      }
    } else {
      if (this.ngSelect) {
        this.ngSelect.clearModel()
      }
    }
  }

  // collapse - set status
  private _setCollapseStatus(item: IUserSelectorItem, newVal: boolean): void {
    item.isCollapsed = newVal
    if (item.childs) {
      item.childs.forEach(i => {
        i.isCollapsed = newVal
        if (i.childs) {
          i.childs.forEach(i => this._setCollapseStatus(i, newVal))
        }
      })
    }
  }

  // collapse - check is all collapsed
  private _checkAllCollapse(): void {
    const all = this.items[0]
    all.childs.forEach(pos => {
      pos.isCollapsed = this._isAllChildsCollapsed(pos)
    })
    all.isCollapsed = this._isAllChildsCollapsed(all)
  }

  // collapse toggle
  toggleCollapse(ev: any, item: IUserSelectorItem): void {
    ev.stopPropagation()
    const newVal = !item.isCollapsed
    this._setCollapseStatus(item, newVal)
    this._checkAllCollapse()
  }

  // collapse - checkk is all childs collapsed
  private _isAllChildsCollapsed(item: IUserSelectorItem): boolean {
    const childsAm = item.childs.length
    let collapsedAm = 0
    item.childs.forEach(i => (i.isCollapsed ? collapsedAm++ : null))
    return childsAm === collapsedAm
  }

  clearData() {
    this.items.forEach(i => (i.isSelected = false))
    this.ngSelect.select([])
    this.parentForm.controls[this.controlName].setValue(undefined)
    if (this.changeSelected) {
      this.changeSelected.emit(undefined)
    }
  }

  private _onValueChangeEvent(): void {
    const all = this.items[0]
    const res = {
      selectedRoleNames: [],
      selectedUserIds: []
    }
    all.childs.map(role => {
      if (role.isSelected) {
        res.selectedRoleNames.push(role.id)
      }
      role.childs.map(user => {
        if (user.isSelected) {
          res.selectedUserIds.push(user.id)
        }
      })
    })
    this.changeSelected.emit(res)
  }

  // search
  searchFromHeader(val): void {
    if (val.length > 1) {
      this.searchValue = val
    } else {
      this.searchValue = undefined
    }
  }

  // search
  searchFromNgSelect(val): void {
    if (val.term.length > 1) {
      this.searchValue = val.term
    } else {
      this.searchValue = undefined
    }
  }

  // search
  getSearchAutocomplValue(val): void {
    this.isOpen = true
    this.searchValue = val.term
  }

  // search
  customSearch(item: any): any {
    return item
  }

  // reason - for option tree used custom approacg with model tree, so amount of ng-option = 1 item, so we need scroll to top after open
  private _scrollOptionListToTop() {
    setTimeout(() => {
      const scrollWrap = document.querySelector(`#${this.domId} .ng-dropdown-panel-items`)
      scrollWrap.scrollTo(0, 0)
    })
  }
  // view status
  onOpen() {
    this._scrollOptionListToTop()
    if (this.open) {
      this.open.emit()
    }
  }

  // view status
  onClose() {
    this.searchValue = undefined
    if (this.close) {
      this.close.emit()
    }
  }

  deleteItem(ev, item: IUserSelectorItem): void {
    ev.stopPropagation()
    item.isSelected = false
    if (item.type === 'role') {
      item.childs.forEach(user => (user.isSelected = false))
    }
    this._updateSelectionStatusForTree()
    this._createSelectedList()
    this._onValueChangeEvent()
  }

  stopPropagation(e) {
    e.stopPropagation()
  }
}
