import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-wysiwig',
  templateUrl: './wysiwig.component.html',
  styleUrls: ['./wysiwig.component.scss'],
})
export class WysiwigComponent implements OnInit {
  @Input() classList: string // list of css classes
  @Output() valueChanged: EventEmitter<string[]> = new EventEmitter()

  isBold = false
  isItalic = false
  currTextDecorClass = ''
  textDecorList = ['', 'ef-underline', 'ef-line-through']

  constructor() {}

  ngOnInit() {
    if (this.classList) {
      this.isBold = this.classList.indexOf('ef-bold') !== -1
      this.isItalic = this.classList.indexOf('ef-italic') !== -1
      if (this.classList.indexOf('ef-underline') !== -1) {
        this.currTextDecorClass = 'ef-underline'
      } else if (this.classList.indexOf('ef-line-through') !== -1) {
        this.currTextDecorClass = 'ef-line-through'
      }
    }
  }

  setTextDecoration(currCssClass) {
    this.currTextDecorClass = currCssClass
    this.update()
  }

  changeFontWeight() {
    this.isBold = !this.isBold
    this.update()
  }

  changeFontStyle() {
    this.isItalic = !this.isItalic
    this.update()
  }

  update() {
    let classList = []
    if (this.isBold) {
      classList.push('ef-bold')
    }
    if (this.isItalic) {
      classList.push('ef-italic')
    }
    if (this.currTextDecorClass) {
      classList.push(this.currTextDecorClass)
    }
    this.valueChanged.emit(classList)
  }
}
