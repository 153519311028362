// ang
import { Component, OnInit } from '@angular/core'

// app
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TermsOfUseComponent } from '../../molecules/modals/terms-of-use/terms-of-use.component'

@Component({
  selector: 'ef-app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class AppTermsComponent implements OnInit {
  constructor(private _modalService: NgbModal) {}

  ngOnInit() {}

  openTerms() {
    const modalRef = this._modalService.open(TermsOfUseComponent, {
      windowClass: 'ef-modal-position-center',
    })
  }
}
