// ang
import { Component, OnInit } from '@angular/core'

// lib
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

// app
import { SearchGlobalResultsComponent } from '../results/results.component'

@Component({
  selector: 'ef-search-global-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class SearchGlobalInputComponent implements OnInit {
  throttle: any[] = []
  modalIsOpen: boolean = false
  hintListIsOpen: boolean = false
  searchValue: string
  valueForHintList: string

  constructor(private _modalService: NgbModal) {}

  ngOnInit() {
    // temp
    // setTimeout(() => {
    //   this.openResultModal();
    // })
  }

  _onDelaySearch() {
    this.throttle.forEach(func => clearTimeout(func))
    const delay = setTimeout(() => {
      this.valueForHintList = this.searchValue
      if (this.valueForHintList && this.valueForHintList !== '') {
        this.hintListIsOpen = true
      }
    }, 500)
    this.throttle.push(delay)
  }

  onPrint(event: any) {
    this.searchValue = event.target.value
    if (event.keyCode === 13) {
      this.modalIsOpen = true
      this.hintListIsOpen = false
      setTimeout(() => {
        this.openResultModal()
      })
    } else {
      this._onDelaySearch()
    }
  }

  onActionOutside() {
    this.hintListIsOpen = false
  }

  openResultModal() {
    const modalRef = this._modalService.open(SearchGlobalResultsComponent, {
      windowClass: 'ef-modal-search-global',
    })
    modalRef.componentInstance.searchValue = this.searchValue
    modalRef.result
      .then(res => {
        this.modalIsOpen = false
      })
      .catch(res => {
        this.modalIsOpen = false
      })
  }
}
