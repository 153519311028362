import { environment } from '../../../environments/environment'

const API_LIST = {
  // ------------------------------------ ACCOUNTS

  ACCOUNTS_GET_CONFIG_BY_WORKSPACE_URL: '/accounts/v1/tenant/config/workspace/',
  ACCOUNTS_GET_IS_WORKSPACE_AVAILABLE: '/accounts/v1/tenant/config/workspace-is-available/',

  ACCOUNTS_GET_ORGANISATION_BY_ID: '/accounts/v1/realm/getOrgById',
  ACCOUNTS_GET_ORGANISATION_BY_NAME: '/accounts/v1/realm',

  ACCOUNTS_GET_TENANT_ID_BY_WORKSPACE_URL: '/accounts/v1/tenant/workspace/',

  // ------------------------------------ USERS

  USERS_GET_COMPANY_USER_LIST: '/users/getCompanyUserList',

  // ----------------------------------- BPM

  // client views
  BPM_POST_CLIENT_VIEW_SAVE_URL: '/bpm/v1/client/save',
  BPM_CLIENT_VIEW_SUBMIT_URL: '/bpm/v1/client/submit',
  BPM_POST_CLIENT_VIEW_NEW_URL: '/bpm/v1/client',
  BPM_POST_CLIENT_VIEW_DELETE_URL: '/bpm/v1/client',
  BPM_GET_CLIENT_VIEW_RECORD_URL: '/bpm/v1/client',
  BPM_GET_WORK_ITEM_RECORD_URL: '/bpm/v1/client/:id/work-item',
  // set assigned user
  PROCESSES_SET_ASSIGN_USER_API_URL: '/bpm/v1/client/update-assigned-user',

  // actions
  PROCESSES_GET_PROCESS_API_URL: '/bpm/v1/process',

  // ----------------------------------- AUTH

  // MENU

  MENU_GET_ITEMS_API_URL: '/bpm/v1/navigation',

  // registration (by invite)
  // AUTH_REGISTRATION_API_URL: '/v1.0/auth/registration',

  // profile overview
  // AUTH_GET_USER_PROFILE_OVERVIEW_API_URL: '/v1.0/auth/get-user-profile-overview',

  // --------------------------------- DASHBOARD

  // widget list
  DASHBOARD_GET_WIDGET_LIST_API_URL: '/bpm/v1/widgets/widget-list',

  // widget todo-list
  DASHBOARD_GET_WIDGET_TODO_STAGES_API_URL: '/bpm/v1/widgets/my-todo',

  // widget my-processes
  // DASHBOARD_GET_WIDGET_MY_PROCESSES_API_URL: '/v1.0/dashboard/widget-my-processes',

  // widget recent-work
  //TODO
  DASHBOARD_GET_RECENT_WORKS_API_URL: '/v1.0/dashboard/widget-recent-works',

  // widget recent-work delete item
  //TODO
  DASHBOARD_DELETE_RECENT_WORK_API_URL: '/v1.0/dashboard/widget-recent-work-delete',

  // widget publish item
  //TODO
  DASHBOARD_PUBLISH_WIDGET_API_URL: '/bpm/v1/widgets/publish',
  // --------------------------------- PROCESSES
  //

  CATEGORIES_GET_CATEGORY_LIST_API_URL: '/bpm/v1/category/categories',
  CATEGORIES_GET_CATEGORY_API_URL: '/bpm/v1/category/category',
  CATEGORIES_SAVE_CATEGORY_API_URL: '/bpm/v1/category/save',
  CATEGORIES_DELETE_CATEGORY_API_URL: '/bpm/v1/category',

  // get action item
  //ACTIONS_GET_ACTION_API_URL: '/bpm/v1/process',

  // ACTIONS_GET_ACTION_API_URL_READY: false,

  // save action item
  PROCESSES_SAVE_PROCESS_API_URL: '/bpm/v1/process',

  PROCESSES_SAVE_DOCUMENT_PROCESS_API_URL: '/bpm/v1/process/document-process',
  PROCESSES_GET_DOCUMENT_PROCESS_API_URL: '/bpm/v1/process/document-process',

  PROCESSES_PUBLISH_PROCESS_API_URL: '/bpm/v1/process/publish',

  // get actions list
  WIDGETS_GET_MY_WORK_URL: '/bpm/v1/widgets/my-work',

  // get action versions
  PROCESSES_GET_PROCESS_VERSIONS_API: '/bpm/v1/process/versions',

  // -------------------------------  PROCESS (instance of actions)

  // get list
  // TODO Dom - Determine whether a different list is required
  // CATEGORIES_GET_PROCESS_LIST_API_URL: 'v1.0/processes/get-list',
  CATEGORIES_GET_PROCESS_LIST_API_URL: '/bpm/v1/category/categories',
  // delete process
  PROCESSES_DELETE_PROCESS_API_URL: '/bpm/v1/processes/delete-process',

  // update process
  PROCESSES_UPDATE_PROCESS_API_URL: '/bpm/v1/processes/update',

  // process overview
  // PROCESSES_GET_PROCESS_OVERVIEW_API_URL: '/bpm/v1/processes/process',

  // snapshot
  // PROCESSES_GET_PROCESS_SNAPSHOT_API_URL: '/bpm/v1/processes/snapshot',

  // get user list for reasssign
  // PROCESSES_GET_REASSIGN_TO_LIST_API_URL: '/bpm/v1/processes/get-reassign-to-list',

  // user related apis
  ACTIONS_GET_USERS_API_URL: '/users/v1/users', //getUsersAndPositions
  ACTIONS_GET_ROLES_API_URL: '/users/v1/roles',
  ACTIONS_GET_USER_BY_ID_API_URL: '/users/v1/users',

  USERS_INVITE_USER_API_URL: '/users/v1/users/invite',
  USERS_RESEND_INVITE_USER_API_URL: '/users/v1/users/invite/resend',
  USERS_INVITES_LIST_API_URL: '/users/v1/users/invites',
  USERS_PROFILE_API_URL: '/users/v1/users/profile',
  USERS_UPDATE_TEAM_MEMBER_API_URL: '/users/v1/users/:id',
  USERS_TOGGLE_ENABLED_USER_API_URL: '/users/v1/users/:id/enabled',

  USERS_CREATE_ROLE_API_URL: '/users/v1/roles',
  USERS_UPDATE_ROLE_API_URL: '/users/v1/roles',
  ROLES_PERMISSIONS_API_URL: '/users/v1/roles/permissions',

  USERS_CREATE_DEPARTMENT_API_URL: '/users/v1/departments',
  USERS_GET_DEPARTMENTS_API_URL: '/users/v1/departments',

  // ------------------------------------- TEAM

  // get team list
  // TEAM_GET_TEAM_LIST_API_URL: '/v1.0/team/list',

  // get user profile
  // TEAM_GET_USER_PROFILE_API_URL: '/v1.0/team/user-profile',

  // ------------------------------------- COMPANY SETTINGS

  // overview
  //TODO
  COMPANY_SETTINGS_OVERVIEW_API_URL: '/v1.0/settings/overview',

  // subscriptions
  //TODO
  COMPANY_SETTINGS_SUBSCRIPTIONS_API_URL: '/v1.0/settings/subscriptions',

  // billings
  //TODO
  COMPANY_SETTINGS_BILLINGS_API_URL: '/v1.0/settings/billings',

  // -------------------------------------- GLOBAL SEARCH

  // hints

  // get hints
  //TODO
  SEARCH_GLOBAL_GET_HINT_LIST_API_URL: '/v1.0/global-search/hint-list',

  // processes

  // get team list
  //TODO
  SEARCH_GLOBAL_GET_TEAM_LIST_API_URL: '/v1.0/global-search/team-list',
  // get folder list
  //TODO
  SEARCH_GLOBAL_GET_FOLDER_LIST_API_URL: '/v1.0/global-search/folder-list',

  // get process list
  //TODO
  SEARCH_GLOBAL_GET_RESULT_PROCESS_LIST_API_URL: '/v1.0/global-search/workflow-header-list',

  // users

  // get department list
  //NOT USED
  // SEARCH_GLOBAL_GET_DEPARTMENT_LIST_API_URL: '/v1.0/global-search/department-list',

  // get positions list
  //NOT USED
  // SEARCH_GLOBAL_GET_POSITION_LIST_API_URL: '/v1.0/global-search/position-list',

  // get users list
  //TODO
  SEARCH_GLOBAL_GET_RESULT_USERS_LIST_API_URL: '/v1.0/global-search/users-list',

  // ------------------------------------- NOTIFICATIONS

  // get list
  //TODO
  NOTIFICATIONS_GET_LIST_API_URL: '/bpm/v1/notifications/get-list',

  // set all read
  //TODO
  NOTIFICATIONS_SET_ALL_READ_API_URL: '/bpm/v1/notifications/set-all-read',

  // set item read
  //TODO
  NOTIFICATIONS_SET_READ_STATUS_API_URL: '/bpm/v1/notifications/set-read-status'
}

const getPath = (val: string) => {
  return environment.apiEndpointUrl + API_LIST[val]
}

const getPathAndReplace = (val: string, IdName: string, idValue: string) => {
  let path = environment.apiEndpointUrl + API_LIST[val]
  return path.replace(':' + IdName, idValue)
}

export { getPath, getPathAndReplace }
