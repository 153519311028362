// ang
import { CommonModule, DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarModule as MatSnackBarModule
} from '@angular/material/legacy-snack-bar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { RouterModule, Routes } from '@angular/router'
// libs
// TODO - delete ngb main module - replace it only modules that need
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap'
// import { DropdownModule } from 'primeng/dropdown'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgxDnDModule } from '@swimlane/ngx-dnd'
import { ClickOutsideModule } from 'ng-click-outside'
import { AutoSizeInputModule } from 'ngx-autosize-input'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ClipboardModule } from 'ngx-clipboard'
import { ColorPickerModule } from 'ngx-color-picker'
import { ImageCropperModule } from 'ngx-image-cropper'
import { NgxUploaderModule } from 'ngx-uploader'

// components
import * as comp from '../components/atoms'
import * as org from '../components/organisms'
// directives
import * as dir from './directives/'
// pipes
import * as pipes from './pipes/'
// services
import { StoreModule } from '@ngrx/store'
import * as fromTeam from '../../app/modules/team/states/reducers'
import { HttpSharedService, UtilsService } from './services/'

const routes: Routes = []
@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    NgxDnDModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    NgxUploaderModule,
    ColorPickerModule,
    ClipboardModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    NgbDatepickerModule,
    AutoSizeInputModule,
    MatSnackBarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatSidenavModule,
    MatSelectModule,
    NgxSliderModule,
    StoreModule.forFeature('teamState', fromTeam.reducers, { metaReducers: fromTeam.metaReducers })
  ],

  declarations: [
    comp.CheckboxComponent,
    comp.FileUploaderComponent,
    comp.ColorPickerComponent,
    comp.ConfirmOverlayComponent,
    comp.DeleteConfirmOverlayComponent,
    comp.DateTimePickerComponent,
    comp.ChangerComponent,
    comp.WysiwigComponent,
    comp.SwitchComponent,
    comp.InputTitleComponent,
    comp.BtnAddComponent,
    comp.InputErrorsComponent,
    comp.InputComponent,
    comp.SelectComponent,
    comp.SliderBtnComponent,
    comp.SpinnerComponent,
    comp.SelectIconComponent,
    comp.TimePickerComponent,
    comp.AvatarComponent,
    comp.AvatarListComponent,
    comp.RadioComponent,
    comp.IconSvgComponent,
    comp.AvatarComponent,
    comp.ImgCropperComponent,
    comp.FileSelectorComponent,
    comp.TimezoneSelectorComponent,
    comp.HightlightSearchTextComponent,
    comp.HighlightSearchPipe,
    comp.DeadlineComponent,
    comp.TreePathComponent,
    comp.PassLabelComponent,
    comp.PassedTimeComponent,
    comp.AppTermsComponent,
    comp.BtnGroupComponent,
    comp.SaveChangesComponent,
    comp.UserSelectorComponent,
    comp.UserSelectorComponent,
    comp.SelectorSelectedItemComponent,
    comp.EmailToComponent,
    comp.ProcessesEmptyListComponent,
    comp.BadgeComponent,
    comp.SortDropdownComponent,
    org.SearchInputComponent,
    org.SearchInputFilterComponent,
    // directive
    dir.DetectOutsideActionDirective,
    dir.AutoFocusDirective,
    dir.PropagationStopDirective,

    //pipes
    pipes.DateAgoPipe,
    pipes.DateLeftPipe
  ],

  providers: [
    UtilsService,
    HttpSharedService,
    DatePipe,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
  ],

  exports: [
    // modules
    NgbModule,
    NgbDatepickerModule,
    BsDropdownModule,
    NgxDnDModule,
    // DropdownModule,
    ClickOutsideModule,
    MatSelectModule,

    // components
    comp.CheckboxComponent,
    comp.FileUploaderComponent,
    comp.ColorPickerComponent,
    comp.ConfirmOverlayComponent,
    comp.DeleteConfirmOverlayComponent,
    comp.DateTimePickerComponent,
    comp.ChangerComponent,
    comp.WysiwigComponent,
    comp.SwitchComponent,
    comp.InputTitleComponent,
    comp.BtnAddComponent,
    comp.InputErrorsComponent,
    comp.SliderBtnComponent,
    comp.InputComponent,
    comp.AvatarListComponent,
    comp.SelectComponent,
    comp.SpinnerComponent,
    comp.SelectIconComponent,
    comp.TimePickerComponent,
    comp.RadioComponent,
    comp.IconSvgComponent,
    comp.AvatarComponent,
    comp.ImgCropperComponent,
    comp.FileSelectorComponent,
    comp.TimezoneSelectorComponent,
    comp.HightlightSearchTextComponent,
    comp.HighlightSearchPipe,
    comp.DeadlineComponent,
    comp.TreePathComponent,
    comp.PassLabelComponent,
    comp.PassedTimeComponent,
    comp.AppTermsComponent,
    comp.BtnGroupComponent,
    comp.UserSelectorComponent,
    comp.EmailToComponent,
    comp.SelectorSelectedItemComponent,
    comp.ProcessesEmptyListComponent,
    comp.BadgeComponent,
    comp.SortDropdownComponent,
    org.SearchInputComponent,
    org.SearchInputFilterComponent,
    // directives
    dir.DetectOutsideActionDirective,
    dir.AutoFocusDirective,
    dir.PropagationStopDirective,

    //pipes
    pipes.DateAgoPipe,
    pipes.DateLeftPipe
  ],

  entryComponents: [comp.SwitchComponent, comp.ImgCropperComponent, comp.SaveChangesComponent]
})
export class SharedModule {}
