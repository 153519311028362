import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CONFIRM_DIALOG_ACTIONS } from '../../../shared/enum/confirm-dialog-actions.enum'

@Component({
  selector: 'ef-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() message: string = 'Are you sure you want to exit?'

  btnCancel: string = CONFIRM_DIALOG_ACTIONS[CONFIRM_DIALOG_ACTIONS.Cancel]
  btnConfirm: string = CONFIRM_DIALOG_ACTIONS[CONFIRM_DIALOG_ACTIONS.Confirm]
  @Output() onAction: EventEmitter<CONFIRM_DIALOG_ACTIONS> = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}

  confirm() {
    this.onAction.emit(CONFIRM_DIALOG_ACTIONS.Confirm)
  }

  cancel() {
    this.onAction.emit(CONFIRM_DIALOG_ACTIONS.Cancel)
  }
}
