// lib
import { IRoleName, IUser } from '@/app/shared/models'
import { Action } from '@ngrx/store'
import { IOrganization } from '../../shared/models/organization.model'
// app
import * as models from '../models'
import { NavigationItem } from '../models'

export enum CoreActionTypes {
  SetMenu = '[ Menu ] Set Menu',

  // AUTH
  SetAuthUser = '[ User ] Set Auth User',
  // SetUser = '[ User ] Set User',

  SetOrganization = '[Organization] Set Organization',

  // USERS
  SetAllUsers = '[ Users ] Set All Users',
  SetAllRoles = '[ Users ] Set All Roles',
  // SetUsersAndPositionsByIds = '[ Users ] Set All Users and Positions By Ids',

  // NOTIFICATIONS

  // dropdown
  SetNotificDropdownList = '[ Notifications ] Set Notific Dropdown List',
  AddNotificDropdownList = '[ Notifications ] Add Notific Dropdown List',

  // full list
  SetNotificFullList = '[ Notifications ] Set Notific Full List',
  AddNotificFullList = '[ Notifications ] Add Notific Full List',

  // delete
  DeleteNotification = '[ Notifications ] Delete Notification',

  // mark read
  SetAllNotificIsRead = '[ Notifications ] Set All Notific Is Read',

  // add push item to lists (full list + dropdown)
  AddPushNotificToOpenLists = '[ Notifications ] Add Push Notific To Dropdown List + To Full List'
}

export class SetMenu implements Action {
  readonly type = CoreActionTypes.SetMenu
  constructor(public payload: NavigationItem[]) {}
}

// AUTH
export class SetAuthUser implements Action {
  readonly type = CoreActionTypes.SetAuthUser
  constructor(public payload: IUser) {}
}

export class SetOrganization implements Action {
  readonly type = CoreActionTypes.SetOrganization
  constructor(public payload: IOrganization) {}
}

export class SetAllUsers implements Action {
  readonly type = CoreActionTypes.SetAllUsers
  constructor(public payload: IUser[]) {}
}

export class SetAllRoles implements Action {
  readonly type = CoreActionTypes.SetAllRoles
  constructor(public payload: IRoleName[]) {}
}

// NOTIFICATIONS

// dropdown
// set
export class SetNotificDropdownList implements Action {
  readonly type = CoreActionTypes.SetNotificDropdownList
  constructor(public payload: models.IAppNotification[]) {}
}
// add
export class AddNotificDropdownList implements Action {
  readonly type = CoreActionTypes.AddNotificDropdownList
  constructor(public payload: models.IAppNotification[]) {}
}

// full list
// set
export class SetNotificFullList implements Action {
  readonly type = CoreActionTypes.SetNotificFullList
  constructor(public payload: models.IAppNotification[]) {}
}
// add
export class AddNotificFullList implements Action {
  readonly type = CoreActionTypes.AddNotificFullList
  constructor(public payload: models.IAppNotification[]) {}
}

// mark all read
export class SetAllNotificIsRead implements Action {
  readonly type = CoreActionTypes.SetAllNotificIsRead
  constructor(public payload: boolean) {}
}
// delete
export class DeleteNotification implements Action {
  readonly type = CoreActionTypes.DeleteNotification
  constructor(public payload: string) {}
}
// add push item to lists (dropdown + full list)
export class AddPushNotificToOpenLists implements Action {
  readonly type = CoreActionTypes.AddPushNotificToOpenLists
  constructor(
    public payload: {
      notific: models.IAppNotification
      isDropdownListOpen: boolean
      isFullListOpen: boolean
    }
  ) {}
}

export type CoreActions =
  // auth
  | SetAuthUser
  //| SetUser
  | SetOrganization

  // users
  | SetAllUsers
  | SetAllRoles

  // notifications

  // dropdown
  | SetNotificDropdownList
  | AddNotificDropdownList

  // full list
  | SetNotificFullList
  | AddNotificFullList

  // mark all read
  | SetAllNotificIsRead

  // delete
  | DeleteNotification

  // add push item to lists
  | AddPushNotificToOpenLists
