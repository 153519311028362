// ang
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { SelectControlItem } from '../../../shared/models'
import { AWESOME } from './awesome.config'

@Component({
  selector: 'ef-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss'],
})
export class SelectIconComponent implements OnInit {
  @Input() initValue: string

  awesomeIconList: SelectControlItem[] = AWESOME
  currValue: string
  formControl: FormControl

  @Output() changeSelected = new EventEmitter()

  constructor() {}

  ngOnInit() {
    let currValue
    if (this.initValue) {
      currValue = this.initValue.replace('fa-', '').replace(/-/gi, ' ')
    }
    this.formControl = new FormControl(currValue)
  }

  onChange(event: SelectControlItem) {
    this.changeSelected.emit('fa-' + event.value.replace(/ /gi, '-'))
  }
}
