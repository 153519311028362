import { ISearchInputFilter } from '@/app/shared/interfaces/search-input-filter.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ef-search-input',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() showFilter: boolean
  @Output() output = new EventEmitter<Event>();
  @Output() applyFilter = new EventEmitter<ISearchInputFilter>();

  constructor() { }

  ngOnInit(): void {

  }

  public filter(event){
    this.applyFilter.emit(event)
  }

  public onChange(event){
    this.output.emit(event)
  }
}
