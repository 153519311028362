import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-slider-btn',
  templateUrl: './slider-btn.component.html',
  styleUrls: ['./slider-btn.component.scss'],
})
export class SliderBtnComponent implements OnInit {
  @Input() direction: string // left || right
  @Input() title: string // left || right

  @Output() change = new EventEmitter()

  constructor() {}

  ngOnInit() {}
}
