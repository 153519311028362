// ang
import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbActiveModal, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
// store
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { ClickOutsideModule } from 'ng-click-outside'
// libs
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { DeviceDetectorModule } from 'ngx-device-detector'
import { EmbedVideoService } from 'ngx-embed-video'
import { AppAuthGuard } from './app.authguard'
// modules
import { AppComponent } from './app.component'
import { HeaderComponent } from './components/molecules/header/header.component'
import { CoreModule } from './core/core.module'
import { JwtInterceptor } from './core/interceptors'
import { AuthService, UserService } from './core/services'
import { initializeKeycloak } from './initializeKeycloak'
import { SharedModule } from './shared/shared.module'
import { metaReducers, reducers } from './states/reducer'
// import { MatExpansionModule } from '@angular/material/expansion'
import { MatSidenavModule } from '@angular/material/sidenav'
// import { MatIconModule } from '@angular/material/icon'
// import { MatToolbarModule } from '@angular/material/toolbar'
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { environment } from '../environments/environment'
import { ConfirmDialogComponent } from './components/atoms/confirm-dialog/confirm-dialog.component'

const keycloakService = new KeycloakService()

@NgModule({
  imports: [
    // MatExpansionModule,
    MatSidenavModule,
    // MatIconModule,
    // MatToolbarModule,
    MatListModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    //StoriesModule,

    // libs
    ClickOutsideModule,
    BsDatepickerModule.forRoot(),
    DeviceDetectorModule.forRoot(),

    // app
    CoreModule,
    SharedModule,

    // store
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    KeycloakAngularModule
    // BsDatepickerModule.forRoot()
  ],

  declarations: [AppComponent, HeaderComponent, ConfirmDialogComponent],
  providers: [
    AppAuthGuard,
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    {
      provide: APP_INITIALIZER,
      useClass: JwtInterceptor,
      multi: true,
      useFactory: initializeKeycloak,
      deps: [KeycloakService, UserService, AuthService]
    },
    NgbActiveModal,
    EmbedVideoService,
    NgbTooltipConfig
  ].filter(item => item),
  bootstrap: [AppComponent],
  entryComponents: [AppComponent]
})
export class AppModule {}
