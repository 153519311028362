import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular'
import { AuthService, UserService } from './core/services'

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    private _userService: UserService,
    protected authService: AuthService
  ) {
    super(router, keycloakAngular)
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.authService.setAuthenticated(this.authenticated)
      let permission
      if (!this.authenticated) {
        this.keycloakAngular
          .login()
          .then(() => {
            this._userService.getUserProfile().subscribe(profile => {
              this.authService.setProfile(profile)
            })
          })
          .catch(e => console.error(e))
        return reject(false)
      }

      const requiredRoles: string[] = route.data.roles
      if (!requiredRoles || requiredRoles.length === 0) {
        permission = true
      } else {
        if (!this.roles || this.roles.length === 0) {
          permission = false
        }
        if (requiredRoles.every(role => this.roles.indexOf(role) > -1)) {
          permission = true
        } else {
          permission = false
        }
      }

      if (!permission) {
        this.router.navigate(['/'])
      }
      resolve(permission)
    })
  }
}
