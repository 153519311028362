import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-changer',
  templateUrl: './changer.component.html',
  styleUrls: ['./changer.component.scss'],
})
export class ChangerComponent implements OnInit {
  @Input() unit = 'px'
  @Input() initValue: number

  currValue: number = 1

  @Output() changeValue: EventEmitter<number> = new EventEmitter()

  constructor() {}

  ngOnInit() {
    if (typeof this.initValue === 'number') {
      this.currValue = this.initValue
    }
  }

  increase() {
    this.currValue++
  }

  decrease() {
    this.currValue--
  }

  changed() {
    this.changeValue.emit(this.currValue)
  }
}
