import { KeycloakService } from 'keycloak-angular'
import { environment } from '../environments/environment'
import { AuthService, UserService } from './core/services'

export function initializeKeycloak(keycloak: KeycloakService, userService: UserService, authService: AuthService) {
  // const realm = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : ''
  const realm = authService.getOrgId()
  return () =>
    keycloak
      .init({
        config: {
          url: `${environment.serverUrl}` + '/auth',
          realm,
          clientId: 'app-client-' + realm
        },
        loadUserProfileAtStartUp: false,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: []
      })
      .then(() => {
        userService.getUserProfile().subscribe(profile => {
          authService.setProfile(profile)
        })

        userService.getUsersByIdsData({ userIds: [], roleNames: [] }).subscribe(users => {
          authService.setAllUsers(users)
        })
      })
      .catch(error => console.error('init Keycloak failed', error))
}
