import * as enums from '../enum'
import { PageBehaviour } from './page-behaviour.model'
import { Section } from './section.model'

export class Card {
  id: string
  title: string
  description: string
  // timer
  isTimerActive: boolean
  timerAmount: number
  timerType: string
  approverUserIds: string[]
  approverRoleNames: string[]
  allowAssignToAnyUser: boolean
  sections: Section[]
  routes: ICardRoute[]
  notifications: IStageCardNotification[]
  permissions: IStageCardPermission[]

  // client side
  isActive?: boolean
}

// NOTIFICATION

export class IStageCardNotification {
  id: string
  cardId: string
  notificationName: string
  displayName: string
  routeType: string
  routeIds: string[]
  sendAs: string
  notificationUserIds: string
  emailToUserIds: string[]
  emailToEmails: string[]
  emailToRoleNames: string[]
  emailFrom: string
  emailCc: string
  emailContentPlainText: string
  emailSubject: string
  emailBody: string
  //TODO determine whether this is needed to retrieve the values of the body and subject to replace the fields
  emailSubjectItems: string[]
  emailBodyItems: string[]
  // client side
  isNew?: boolean
  isActive: boolean
}

// ROUTE

export class ICardRoute {
  id: string
  routeName: string
  routeType: string
  routeCondition: PageBehaviour
  rollbackCardId?: string
  // client side
  isNew?: boolean
  isActive: boolean

  // not used
  saveAsDraft: boolean
  sendAs: string
}

// PERMISSION

export class IStageCardPermission {
  id: string
  cardId: string
  sectionId: string
  permission: enums.PERMISSION_STATUS
  sectionName: string
}
