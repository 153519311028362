import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store'
import { environment } from '../../../../../environments/environment'
import * as fromTeam from './team.reducer'

export interface TeamState {
  team: fromTeam.TeamState
}

export const reducers: ActionReducerMap<TeamState> = {
  team: fromTeam.reducer
}

export const selectTeamState = createFeatureSelector<TeamState>('teamState')
export const selectUserProfile = createSelector(selectTeamState, (state: TeamState) => state.team.currUserProfile)
export const selectDepartmentMemberList = createSelector(
  selectTeamState,
  (state: TeamState) => state.team.departmentMemberList
)
export const selectDepartmentList = createSelector(selectTeamState, (state: TeamState) => state.team.departmentList)
export const selectRoleMemberList = createSelector(selectTeamState, (state: TeamState) => state.team.roleMemberList)
export const selectRoleList = createSelector(selectTeamState, (state: TeamState) => state.team.roleList)
export const selectRolePermissionList = createSelector(
  selectTeamState,
  (state: TeamState) => state.team.rolePermissioList
)
export const selectMemberList = createSelector(selectTeamState, (state: TeamState) => state.team.memberList)

export const metaReducers: MetaReducer<TeamState>[] = !environment.production ? [] : []
