// ang
import { Component, OnInit } from '@angular/core'

// lib
import { Subscription } from 'rxjs'

// app
import { NavigationTogglingService } from '../../../../core/services'
import { NotificationService, StoreNotificationsService } from '../../../../core/services'
import { IAppNotification } from '../../../../core/models'

@Component({
  selector: 'ef-notific-full-list',
  templateUrl: './notific-full-list.component.html',
  styleUrls: ['./notific-full-list.component.scss'],
})
export class NotificFullListComponent implements OnInit {
  tabList: string[] = ['all', 'unread', 'shared']
  currType = 'all'

  // filters
  isAllFilter: boolean = true
  isUnreadFilter: boolean = false
  isSharedFilter: boolean = false

  // temp
  notificList: IAppNotification[] = []
  showInitSpinner: boolean = false
  showMoreSpinner: boolean = false
  timestamp: string
  subNotificList: Subscription

  constructor(
    public navigationService: NavigationTogglingService,
    private _notificService: NotificationService,
    private _storeService: StoreNotificationsService
  ) {}

  ngOnInit() {
    this._notificService.isFullListOpen = true
    this.subNotificList = this._storeService.getNotificFullList().subscribe(list => {
      this._sortListByDate(list)
      this.notificList = list
      this._setTimestamp(list)
    })
    if (this.notificList.length === 0) {
      this.getNotificListAsync(true)
    }
  }

  ngOnDestroy() {
    this._storeService.actionSetNotificFullList([])
    this._notificService.isFullListOpen = false
  }

  private _setTimestamp(list: IAppNotification[]) {
    list.forEach(item => {
      if (!this.timestamp || new Date(item.createdAt).getTime() < new Date(this.timestamp).getTime()) {
        this.timestamp = item.createdAt
      }
    })
  }

  private _sortListByDate(list: IAppNotification[]) {
    if (list) {
      list.sort(function(a, b) {
        const aMs = new Date(a.createdAt).getTime()
        const bMs = new Date(b.createdAt).getTime()
        if (aMs < bMs) {
          return 1
        }
        if (aMs > bMs) {
          return -1
        }
        return 0
      })
    }
  }

  getNotificListAsync(isInit: boolean) {
    if (isInit) {
      this.notificList = []
      this.showInitSpinner = true
    } else {
      this.showMoreSpinner = true
    }
    this._notificService
      .getNotificListAsync({
        amount: 20,
        timestamp: this.timestamp,
        type: this.currType,
      })
      .subscribe((res: IAppNotification[]) => {
        if (isInit) {
          this._storeService.actionSetNotificFullList(res)
          this.showInitSpinner = false
          this._notificService.newNotificAmount = 0
        } else {
          this._storeService.actionAddNotificFullList(res)
          this.showMoreSpinner = false
        }
      })
  }

  setTab(tab) {
    this.currType = tab
    this.getNotificListAsync(true)
  }

  loadMore() {
    this.getNotificListAsync(false)
  }
}
