// ang
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

// app
import { ISearchGlobalFilterItem } from '../../../../../core/models'

@Component({
  selector: 'ef-search-global-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
})
export class SearchGlobalFilterSelectorComponent implements OnInit {
  @Input() title: string
  @Input() selectedIdList: string[] = []
  @Input() list: ISearchGlobalFilterItem[] = []

  @Output() changeSelected: EventEmitter<string[]> = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onChange(val: boolean, id: string) {
    this.list.some(item => {
      if (item.id === id) {
        item.isSelected = val
        return true
      }
    })
    const selectedIdList = this.list.filter(item => item.isSelected).map(item => item.id)
    this.changeSelected.emit(selectedIdList)
  }
}
