// ang
import { IDepartment, IRoleName, IUser } from '@/app/shared/models'
import { Injectable } from '@angular/core'
import * as _ from 'lodash'
// app
import { ITeamDepartment, ITeamRole, ITeamRolePermission } from '../models'

@Injectable({
  providedIn: 'root'
})
export class TeamUtilsService {
  constructor() {}

  getMemberListFromUsers(users: IUser[], departments?: IDepartment[]): ITeamDepartment[] {
    let res: ITeamDepartment[] = _.chain(users)
      .groupBy(x => x.department?.id)
      .map((departMembers: IUser[], department) => ({
        id: departments.find(a => a.id === department)?.id,
        title: departments.find(a => a.id === department)?.name,
        members: departMembers,
        positions: _.chain(departMembers)
          .groupBy('position')
          .map((members, position) => ({ id: position, title: position, members: members }))
          .value()
      }))
      .value()

    //Add departments with no users
    if (departments) {
      departments.forEach(department => {
        const doesExist = res.some(function(ele) {
          return ele.title === department.name
        })

        if (!doesExist) {
          res = [
            ...res,
            {
              id: department.id,
              title: department.name,
              members: [],
              positions: []
            }
          ]
        }
      })
    }

    return res
  }

  getRoleListFromUsers(users: IUser[], roles: IRoleName[], allPermissions: ITeamRolePermission[]): ITeamRole[] {
    let res: ITeamRole[] = roles.map(role => {
      const rolePermissions: ITeamRolePermission[] = allPermissions.map(permission => {
        if (role.permissions.hasOwnProperty(permission.id)) {
          const validPermission = role.permissions[permission.id]
          return {
            ...permission,
            isActive: true,
            permissionAction: validPermission[0]
          }
        } else {
          return {
            ...permission,
            isActive: false,
            permissionAction: ''
          }
        }
      })

      return {
        id: role.id,
        title: role.name,
        description: role.description,
        members: users.filter(member => member.roles.includes(role.name)),
        permissions: rolePermissions
      }
    })

    res = [
      {
        id: '0',
        title: 'all roles',
        description: 'all roles',
        members: users,
        permissions: [...new Map(allPermissions.map(item => [item.id, item])).values()]
      },
      ...res
    ]

    return res
  }
}
