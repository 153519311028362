import { NavigationService } from './core/services/navigation/navigation.service'
import { animate, style, transition, trigger } from '@angular/animations'

import { Component, OnInit, ViewChild } from '@angular/core'
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import { NavigationItem } from './core/models'
import { NavigationTogglingService } from './core/services/navigation/navigation-toggling.service'
import { ReloadNavMenuService } from './shared/services/reload-nav-menu.service'
import { RolePermissionsService } from './shared/services/role-permissions.service'
import * as enumsCore from '../app/core/enum'
import { CORE_CONFIG } from './core/core.config'
import { MatSidenav } from '@angular/material/sidenav'
import { BreakpointObserver } from '@angular/cdk/layout'
import { TeamStoreService } from './modules/team/services'

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('animationMd', [
      transition(':enter', [
        style({ 'margin-left': '-264px' }),
        animate('200ms linear', style({ 'margin-left': '0' }))
      ]),
      transition(':leave', [style({ 'margin-left': '0' }), animate('200ms linear', style({ 'margin-left': '-264px' }))])
    ]),
    // !!!! margin-left must be equal to '$ef-app-menu-width-sm' var in scss
    trigger('animationXs', [
      transition(':enter', [style({ 'margin-left': '-64px' }), animate('200ms linear', style({ 'margin-left': '0' }))]),
      transition(':leave', [style({ 'margin-left': '0' }), animate('200ms linear', style({ 'margin-left': '-64px' }))])
    ]),
    trigger('animationBackground', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms linear', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('100ms linear', style({ opacity: 0 }))])
    ])
  ]
})
export class AppComponent implements OnInit {
  disableInitAnimation = true
  navItems: NavigationItem[]
  @ViewChild(MatSidenav) sidenav!: MatSidenav

  constructor(
    private _configTooltip: NgbTooltipConfig,
    public navigationService: NavigationTogglingService,
    private navService: NavigationService,
    private reloadNavMenuService: ReloadNavMenuService,
    private _rolePermissionsService: RolePermissionsService,
    private observer: BreakpointObserver,
    private _teamStoreService: TeamStoreService
  ) {
    this._configTooltip.placement = 'bottom-left'
    this._configTooltip.container = 'body'
    this._configTooltip.triggers = 'hover'
  }

  ngOnInit() {
    this.reloadNavMenuService.changes.subscribe(status => {
      this.navService.getMenuItemsAsync().subscribe(items => {
        this._teamStoreService.getRolePermissionList().subscribe(profilePermissions => {
          if (profilePermissions.length > 0) {
            this.navItems = items.filter(item => {
              if (item.route.includes(enumsCore.MAIN_APP_ROUTES.PROCESSES))
                return this._rolePermissionsService.hasAccessPermission(
                  [CORE_CONFIG.MAP_PERMISSIONS.Processes.Create_Edit_Process],
                  profilePermissions
                )
              else return true
            })
            this.navItems.map(item => this.calculateId(item, 0))
          }
        })
      })
    })
    setTimeout(() => {
      this.disableInitAnimation = false
    })
  }

  private calculateId(item: NavigationItem, depth): void {
    if (depth > 0 && item.children) {
      let enc = new TextEncoder()
      item.id = 0
      enc.encode(item.displayName).forEach(x => {
        item.id = item.id - x
      })
    }

    item.children?.map(item => this.calculateId(item, depth + 1))
  }

  // https://zoaibkhan.com/blog/create-a-responsive-sidebar-menu-with-angular-material/
  ngAfterViewInit() {
    this.observer.observe(['(max-width: 992px)']).subscribe(res => {
      if (res.matches) {
        this.navigationService.hide()
      } else {
        if (!this.navigationService.isOpen) {
          this.navigationService.show(false)
        }
      }
    })
  }
}
