import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CorsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (
    //   !environment.production &&
    //   window.location.href.indexOf('http://localhost') !== -1 &&
    //   request.method === 'OPTIONS'
    // ) {
    //   const modifiedRequest = request.clone({
    //     setHeaders: {
    //       'Content-Type': 'application/json',
    //       'Access-Control-Request-Headers': 'Content-Type, Authorization, Accept, Origin',
    //       'Access-Control-Request-Method': 'GET, POST, PUT, PATCH, DELETE, HEAD, OPTIONS',
    //       'Access-Control-Allow-Origin': '*',
    //       'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, HEAD, OPTIONS',
    //       'Access-Control-Allow-Headers':
    //         'Content-Type, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Authorization, X-Requested-With, Origin, Accept, X-Org'
    //     }
    //   })
    //   return next.handle(modifiedRequest)
    // }
    return next.handle(request)
  }
}
