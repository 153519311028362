// ang
import { Component, OnInit, Input, SimpleChanges } from '@angular/core'

// app
import { SearchGlobalHttpService } from '../../../../core/services'
import { ISearchGlobalHintItem } from '../../../../core/models'

@Component({
  selector: 'ef-search-global-hints',
  templateUrl: './hints.component.html',
  styleUrls: ['./hints.component.scss'],
})
export class SearchGlobalHintsComponent implements OnInit {
  @Input() searchValue: string

  hintList: ISearchGlobalHintItem[] = []

  constructor(private _searchGlobalHttpService: SearchGlobalHttpService) {}

  ngOnInit() {
    this._onSearch()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchValue && !changes.searchValue.firstChange) {
      this._onSearch()
    }
  }

  private _onSearch() {
    if (this.searchValue && this.searchValue !== '') {
      this._searchGlobalHttpService.getHintList({ searchValue: this.searchValue }).subscribe(res => {
        this.hintList = res
      })
    } else {
      this.hintList = []
    }
  }

  hide() {
    this.hintList = []
  }
}
