import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { NavigationItem } from '../../models'
import { catchError } from 'rxjs/operators'
import { HandleError, HttpErrorHandler } from '../errors/http-error-handler.service'
import { getPath } from '../../api-list/api-name-list'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private handleError: HandleError

  constructor(private _http: HttpClient, _httpErrorHandler: HttpErrorHandler) {
    this.handleError = _httpErrorHandler.createHandleError('NavigationService')
  }

  getMenuItemsAsync(): Observable<NavigationItem[]> {
    return this._http
      .get<NavigationItem[]>(getPath('MENU_GET_ITEMS_API_URL'))
      .pipe(catchError(this.handleError('getProcessData:getProcess', null)))
  }
}
