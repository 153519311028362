import { Card } from './card.model'

export enum Operations {
  OR = 'or',
  AND = 'and',
}

export class Stage {
  id: string
  splitRule: string
  cards: Card[]

  // client side
  isNew?: boolean
}
