export class IAppNotification {
  id: string
  isSimple?: boolean
  actionType: string // constants NOTIFIC_ACCEPT ... (reject, assign, complete, reassign, list of some changes)
  isRead: boolean
  isShared: boolean
  createdAt: string // stage, section

  // initiator
  initiatorId: string
  initiatorName: string
  initiatorAvatar: string
  comment: string

  // reassign to
  reassignUserId: string
  reassignUserName: string
  reassignUserAvatar: string

  // stage
  stageId: string //
  stageName: string //

  // process
  processId: string
  processName: string
}
