// app
import { Injectable } from '@angular/core'
// lib
import { select, Store } from '@ngrx/store'
// app
import * as models from '../../models/'
import * as fromCoreState from '../../states/'
import * as coreStateAction from '../../states/actions'

@Injectable({
  providedIn: 'root'
})
export class StoreNotificationsService {
  constructor(private _notificStore: Store<fromCoreState.CoreState>) {}

  // ACTIONS

  // dropdown

  // set
  actionSetNotificDropdownList(data: models.IAppNotification[]) {
    this._notificStore.dispatch(new coreStateAction.SetNotificDropdownList(data))
  }
  // add
  actionAddNotificDropdownList(data: models.IAppNotification[]) {
    this._notificStore.dispatch(new coreStateAction.AddNotificDropdownList(data))
  }

  // full list

  // set
  actionSetNotificFullList(data: models.IAppNotification[]) {
    this._notificStore.dispatch(new coreStateAction.SetNotificFullList(data))
  }
  // add
  actionAddNotificFullList(data: models.IAppNotification[]) {
    this._notificStore.dispatch(new coreStateAction.AddNotificFullList(data))
  }

  // mark all read

  actionSetAllNotificIsRead() {
    this._notificStore.dispatch(new coreStateAction.SetAllNotificIsRead(true))
  }

  // delete

  actionDeleteNotification(notificId: string) {
    this._notificStore.dispatch(new coreStateAction.DeleteNotification(notificId))
  }

  // add push notific to lists (dropdown header + full list)

  // dropdown
  actionAddPushNotificToOpenLists(data: {
    notific: models.IAppNotification
    isDropdownListOpen: boolean
    isFullListOpen: boolean
  }) {
    this._notificStore.dispatch(new coreStateAction.AddPushNotificToOpenLists(data))
  }

  // SELECTS

  getNotificDropdownList() {
    return this._notificStore.pipe(select(fromCoreState.selectNotificDropdownList))
  }

  getNotificFullList() {
    return this._notificStore.pipe(select(fromCoreState.selectNotificFullList))
  }
}
