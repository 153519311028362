// ang
import { IDepartment, IRoleName, IUser } from '@/app/shared/models'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UserService } from '../../../core/services/'
import { ITeamRole, ITeamRolePermission } from '../models'
import { TeamStoreService } from './team-store.service'
import { TeamUtilsService } from './team-utils.service'

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  constructor(
    private _userService: UserService,
    private _teamStoreService: TeamStoreService,
    private _teamUtilsService: TeamUtilsService
  ) {}

  async getMemberByIdListAsync(id: string) {
    return await this.getData(this._userService.getUserById(id))
  }

  async getMemberDetails(force: boolean) {
    const memberList = await this.getMemberListAsync()
    const departmentList = await this.getDepartmentMemberListAsync(memberList, force)
    const roleList = await this.getMembersByRoleListAsync(memberList, force)

    return { memberList, departmentList, roleList }
  }

  async getRoleDetails(force: boolean) {
    const memberList = await this.getMemberListAsync()
    const roleList = await this.getMembersByRoleListAsync(memberList, force)
    return roleList
  }

  async getRolePermissions(): Promise<ITeamRolePermission[]> {
    return await this.getData(this._userService.getRolePermissionsAsync())
  }

  async getMemberListAsync() {
    let memberList = await this.getData(
      this._userService.getUsersByIdsData({
        userIds: [],
        roleNames: []
      })
    )

    this._teamStoreService.actionSetMemberList({ list: memberList })

    return memberList
  }

  async getDepartmentMemberListAsync(users: IUser[], force: boolean) {
    let departmentMemberList = await this.getData(this._teamStoreService.getDepartmentMemberList())
    if (departmentMemberList?.length === 0 || force) {
      const departments = await this.getListOfDepartmentsAsync(force)
      departmentMemberList = this._teamUtilsService.getMemberListFromUsers(users, departments)
      this._teamStoreService.actionSetDepartmentMemberList({ list: departmentMemberList })
    }
    return departmentMemberList
  }

  async getListOfDepartmentsAsync(force: boolean): Promise<IDepartment[]> {
    let departmentList = await this.getData(this._teamStoreService.getDepartmentList())
    if (departmentList?.length === 0 || force) {
      departmentList = await this.getData(
        this._userService.getListOfDepartmentsData({
          departmentNames: []
        })
      )
      this._teamStoreService.actionSetDepartmentList({ list: departmentList })
    }
    return departmentList
  }

  async getListOfRolesAsync(force: boolean): Promise<IRoleName[]> {
    let rolesList = await this.getData(this._teamStoreService.getRoleList())
    if (rolesList?.length === 0 || force) {
      rolesList = await this.getData(
        this._userService.getRolesByIdsData({
          roleNames: []
        })
      )
      this._teamStoreService.actionSetRoleList({ list: rolesList })
    }
    return rolesList
  }

  async getMembersByRoleListAsync(users: IUser[], force: boolean): Promise<ITeamRole[]> {
    //TODO need to filter by users list, i think this is wrong
    //if you set it, it then has a filtered list of users
    let roleList = await this.getData(this._teamStoreService.getRoleMemberList())
    if (roleList?.length === 0 || force) {
      const roles = await this.getData(
        this._userService.getRolesByIdsData({
          roleNames: []
        })
      )

      const allPermissions = await this.getData(this._userService.getRolePermissionsAsync())
      roleList = await this._teamUtilsService.getRoleListFromUsers(users, roles, allPermissions)
      this._teamStoreService.actionSetRoleMemberList({ list: roleList })
    }
    return roleList
  }

  async getInviteeListAsync() {
    return await this.getData(this._userService.getListOfInviteesData())
  }

  async toggleUserEnabled(id: string, isEnabled: boolean) {
    return await this.getData(this._userService.setToggleUserEnabledAsync({ id, isEnabled }))
  }

  async resendInviteAsync(id: string) {
    return await this.getData(this._userService.setResendInviteUserAsync({ id }))
  }

  updateMemberProfile(user: IUser) {
    return this._userService.setUpdateTeamMemberAsync(user)
  }

  updateRole(teamRole: ITeamRole) {
    var permissionActions = teamRole.permissions.filter(a => a.isActive)
    const data: IRoleName = {
      id: teamRole.id,
      name: teamRole.title,
      description: teamRole.description,
      permissions: Object.assign({}, ...permissionActions.map(x => ({ [x.id]: [x.permissionAction] })))
    }

    return this._userService.setUpdateRoleAsync(data)
  }

  public getData(obs: Observable<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      obs.subscribe({
        next: data => resolve(data),
        error: err => reject(err)
      })
    }).catch(r => {
      return undefined
    })
  }
}
