// lib
// app
// form-builder
import { IRoleName, IUser } from '@/app/shared/models'
import { IOrganization } from '../../shared/models/organization.model'
import * as models from '../models'
import { CoreActions, CoreActionTypes } from './actions'

export interface CoreState {
  // auth
  // authUser: models.IAuthUser
  authUser: IUser
  // user: IUser
  organization: IOrganization

  // users
  allUsers: IUser[]
  allRoles: IRoleName[]

  // notific
  notificDropdownList: models.IAppNotification[]
  notificFullList: models.IAppNotification[]
}

export const initialState: CoreState = {
  // auth
  authUser: null,
  // user: null,
  organization: null,

  // users
  allUsers: null,
  allRoles: null,

  // notific
  notificDropdownList: [],
  notificFullList: []
}

export function reducer(state = initialState, action: CoreActions): CoreState {
  switch (action.type) {
    // AUTH
    case CoreActionTypes.SetAuthUser: {
      return {
        ...state,
        authUser: {
          id: action.payload.id,
          name: action.payload.firstName + ' ' + action.payload.lastName,
          avatar: action.payload.avatar,
          position: action.payload.position,
          department: action.payload.department,
          email: action.payload.email,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phoneNumber: action.payload.phoneNumber,
          roles: action.payload.roles,
          status: action.payload.status,
          timeZone: action.payload.timeZone
        }
      }
    }

    case CoreActionTypes.SetOrganization: {
      return {
        ...state,
        organization: action.payload
      }
    }

    // USER

    case CoreActionTypes.SetAllUsers: {
      return {
        ...state,
        allUsers: action.payload
      }
    }

    case CoreActionTypes.SetAllRoles: {
      return {
        ...state,
        allRoles: action.payload
      }
    }

    // NOTIFICATIONS

    // dropdown

    // set
    case CoreActionTypes.SetNotificDropdownList: {
      return {
        ...state,
        notificDropdownList: action.payload
      }
    }

    // add
    case CoreActionTypes.AddNotificDropdownList: {
      return {
        ...state,
        notificDropdownList: state.notificDropdownList.concat(action.payload)
      }
    }

    // full list

    // set
    case CoreActionTypes.SetNotificFullList: {
      return {
        ...state,
        notificFullList: action.payload
      }
    }

    // add
    case CoreActionTypes.AddNotificFullList: {
      return {
        ...state,
        notificFullList: state.notificFullList.concat(action.payload)
      }
    }

    // set all is read
    case CoreActionTypes.SetAllNotificIsRead: {
      return {
        ...state,
        notificDropdownList: state.notificDropdownList.map(item => {
          item.isRead = true
          return item
        }),
        notificFullList: state.notificFullList.map(item => {
          item.isRead = true
          return item
        })
      }
    }

    // delete
    case CoreActionTypes.DeleteNotification: {
      const deletedId = action.payload
      return {
        ...state,
        notificDropdownList: state.notificDropdownList.filter(item => item.id !== deletedId),
        notificFullList: state.notificFullList.filter(item => item.id !== deletedId)
      }
    }

    // add push item to lists (dropdown + full list)
    case CoreActionTypes.AddPushNotificToOpenLists: {
      let newDropdownList
      let newFullList
      if (action.payload.isDropdownListOpen) {
        newDropdownList = [...state.notificDropdownList]
        newDropdownList.unshift(action.payload.notific)
      }
      if (action.payload.isFullListOpen) {
        newFullList = [...state.notificFullList]
        newFullList.unshift(action.payload.notific)
      }
      return {
        ...state,
        notificDropdownList: action.payload.isDropdownListOpen ? newDropdownList : state.notificDropdownList,
        notificFullList: action.payload.isFullListOpen ? newFullList : state.notificFullList
      }
    }

    default:
      return state
  }
}
