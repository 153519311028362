import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { UtilsService } from '../../../shared/services'

@Component({
  selector: 'ef-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() stopClickPropagation = false

  // data
  @Input() items: any
  @Input() initValue: string
  @Input() bindLabel: string
  @Input() bindValue: string
  @Input() groupBy: string

  // view
  @Input() disabled = false
  @Input() multiple = false
  @Input() searchable = false
  @Input() clearable = false
  @Input() placeholder: string
  @Input() isNoBorderType = false

  // form
  @Input() parentForm: FormGroup
  @Input() submitted = false
  @Input() controlName: string
  @Input() lastValue: string
  @Input() required = false
  @Input() showErrorStyles = false

  dropdownPosition: string // by default bottom
  isInitChange = true // first change (init - disable first output)
  form: FormGroup
  formControl: FormControl
  formControlName: string
  abstractControl: AbstractControl
  isOutsideChange = false

  @Output() changeSelected = new EventEmitter()

  constructor(private _utilsService: UtilsService, private _elementRef: ElementRef) {}

  ngOnInit() {
    const validators = [this.required ? Validators.required : null].filter(item => item)
    this.formControl = new FormControl(this._initValue(this.initValue), validators)
    if (this.parentForm) {
      this.formControlName = this.controlName || `select_${this._utilsService.generateUniqueId()}`
      this.parentForm.addControl(this.formControlName, this.formControl)
      this.abstractControl = this.parentForm.controls[this.formControlName]
    }
    const formControl = this.parentForm ? this.abstractControl : this.formControl
    if (this.disabled) {
      formControl.disable()
    }
  }

  // simple - select is string val, multi - only array
  private _initValue(initValue: any) {
    let value = initValue
    if (this.lastValue) {
      value = this.lastValue
    }

    if (this.multiple) {
      return Array.isArray(value) ? value : []
    } else {
      return value
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && !changes.disabled.firstChange) {
      const formControl = this.parentForm ? this.abstractControl : this.formControl
      changes.disabled.currentValue ? formControl.disable() : formControl.enable()
    }
    if (changes.initValue) {
      const formControl = this.parentForm ? this.abstractControl : this.formControl
      if (formControl) {
        formControl.setValue(this._initValue(changes.initValue.currentValue))
      }
    }
  }

  ngAfertViewInit() {}

  change(value) {
    this.changeSelected.emit(this.formControl.value)
  }

  setDropdownPosition() {
    const bottomIndent =
      $(window).height() - $(this._elementRef.nativeElement).offset().top - $(this._elementRef.nativeElement).height()
    this.dropdownPosition = bottomIndent < 240 ? 'top' : undefined
  }

  ngOnDestroy() {
    if (this.parentForm) {
      this.parentForm.removeControl(this.formControlName)
    }
  }
}
