import { Injectable } from '@angular/core'
import {
  CanLoad,
  UrlTree,
  Route,
  UrlSegment,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router'
import { Observable } from 'rxjs'
import { RolePermissionsService } from './shared/services/role-permissions.service'

@Injectable({
  providedIn: 'root'
})
export class AppPermissionGuard implements CanLoad, CanActivate {
  constructor(private _permissions: RolePermissionsService, protected router: Router) {}
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | UrlTree {
    return new Promise(async (resolve, reject) => {
      let permission = await this._permissions.hasAccessPermissionPromise(route.data.permissions)
      if (!permission) this.router.navigate(['/'])
      resolve(permission)
    })
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree {
    return this.canLoad(route.routeConfig, route.url)
  }
}
