import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'ef-confirm-overlay',
  templateUrl: './confirm-overlay.component.html',
  styleUrls: ['./confirm-overlay.component.scss']
})
export class ConfirmOverlayComponent implements OnInit {
  @Input() isOpenConfirmOverlay = false
  @Input() title: string
  @Output() confirm = new EventEmitter<boolean>()

  constructor() {}

  ngOnInit() {}

  closeConfirmOverlay() {
    this.isOpenConfirmOverlay = false
    this.confirm.emit(false)
  }

  onConfirm() {
    this.confirm.emit(true)
    this.isOpenConfirmOverlay = false
  }
}
