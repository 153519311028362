// ang
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

// lib
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'ef-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  @Input() value: number // milliseconds

  // h
  hours: number = 0
  minHours: number = 0
  maxHours: number = 24
  hoursTyping$: Subject<any> = new Subject<any>()
  hoursTypingSub: Subscription = new Subscription()

  // m
  minutes: number = 0
  minMinutes: number = 0
  maxMinutes: number = 60
  minutesTyping$: Subject<any> = new Subject<any>()
  minutesTypingSub: Subscription = new Subscription()

  @Output() valueChanged: EventEmitter<number> = new EventEmitter() //output - milliseconds

  constructor() {}

  ngOnInit() {
    if (this.value && typeof this.value === 'number') {
      const date = new Date(this.value)
      this.hours = date.getHours()
      this.minutes = date.getMinutes()
    }

    this.minutesTypingSub = this.minutesTyping$.pipe(debounceTime(300)).subscribe(res => {
      if (res <= this.maxMinutes && res >= this.minMinutes) {
        this.minutes = res
        this.valueChanged.emit(this._getDate())
      }
    })

    this.hoursTypingSub = this.hoursTyping$.pipe(debounceTime(300)).subscribe(res => {
      if (res <= this.maxHours && res >= this.minHours) {
        this.hours = res
        this.valueChanged.emit(this._getDate())
      }
    })
  }

  ngOnDestroy() {
    this.minutesTypingSub.unsubscribe()
    this.hoursTypingSub.unsubscribe()
  }

  private _getDate() {
    return this.hours * 60 * 60 * 1000 + this.minutes * 60 * 1000
  }
}
