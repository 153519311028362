import { ITeamInvite, ITeamRolePermission } from '@/app/modules/team/models'
import * as sharedModels from '@/app/shared/models'
import { IRoleName, IUser } from '@/app/shared/models'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { UtilsService } from '../../../shared/services'
import { getPath, getPathAndReplace } from '../../api-list'
import { HandleError, HttpErrorHandler } from '../errors/http-error-handler.service'
import { AssignedUser } from './../../../modules/dashboard/models/my-work-item.model'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private handleError: HandleError

  constructor(private _utilsService: UtilsService, private httpClient: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('UserService')
  }

  getListOfDepartmentsData(data: { departmentNames: string[] }): Observable<sharedModels.IDepartment[]> {
    return this.httpClient
      .get<any>(`${getPath('USERS_GET_DEPARTMENTS_API_URL')}`, {
        params: this._utilsService.createQueryParams(data)
      })
      .pipe(catchError(this.handleError('getListOfDepartmentsData')))
  }

  getListOfInviteesData(): Observable<ITeamInvite[]> {
    return this.httpClient
      .get<any>(`${getPath('USERS_INVITES_LIST_API_URL')}`)
      .pipe(catchError(this.handleError('getListOfInviteesData')))
  }

  getUsersByIdsData(data: { userIds: string[]; roleNames: string[] }): Observable<sharedModels.IUser[]> {
    return this.httpClient
      .get<any>(`${getPath('ACTIONS_GET_USERS_API_URL')}`, {
        params: this._utilsService.createQueryParams(data)
      })
      .pipe(catchError(this.handleError('getUsersAndPositionsData')))
  }

  getRolesByIdsData(data: { roleNames: string[] }): Observable<sharedModels.IRoleName[]> {
    return this.httpClient
      .get<any>(`${getPath('ACTIONS_GET_ROLES_API_URL')}`, {
        params: this._utilsService.createQueryParams(data)
      })
      .pipe(catchError(this.handleError('getRolesByIdsData')))
  }

  getUserById(id: string): Observable<sharedModels.IUser> {
    return this.httpClient
      .get<any>(`${[getPath('ACTIONS_GET_USER_BY_ID_API_URL'), id].join('/')}`)
      .pipe(catchError(this.handleError('getUserById')))
  }

  getUserProfile(): Observable<sharedModels.IUser> {
    return this.httpClient
      .get<any>(`${getPath('USERS_PROFILE_API_URL')}`)
      .pipe(catchError(this.handleError('getUserById')))
  }

  setAssignUserAsync(data: { clientRecordId: string; assignedUser: AssignedUser }) {
    return this.httpClient
      .post<any>(`${getPath('PROCESSES_SET_ASSIGN_USER_API_URL')}`, data)
      .pipe(catchError(this.handleError('setAssignUserAsync')))
  }

  setInviteUserAsync(data) {
    return this.httpClient
      .post<any>(`${getPath('USERS_INVITE_USER_API_URL')}`, data)
      .pipe(catchError(this.handleError('setInviteUserAsync')))
  }

  setResendInviteUserAsync(data: { id: string }) {
    return this.httpClient
      .post<any>(`${getPath('USERS_RESEND_INVITE_USER_API_URL')}`, data)
      .pipe(catchError(this.handleError('setResendInviteUserAsync')))
  }

  setToggleUserEnabledAsync(data: { id: string; isEnabled: boolean }) {
    return this.httpClient
      .put<any>(`${getPathAndReplace('USERS_TOGGLE_ENABLED_USER_API_URL', 'id', data.id)}`, data)
      .pipe(catchError(this.handleError('setToggleUserEnabledAsync')))
  }

  setUpdateProfileAsync(data: IUser) {
    return this.httpClient
      .put<any>(`${getPath('USERS_PROFILE_API_URL')}`, data)
      .pipe(catchError(this.handleError('setUpdateUserAsync')))
  }

  setUpdateTeamMemberAsync(data: IUser) {
    return this.httpClient
      .put<any>(`${getPathAndReplace('USERS_UPDATE_TEAM_MEMBER_API_URL', 'id', data.id)}`, data)
      .pipe(catchError(this.handleError('setUpdateTeamMemberAsync')))
  }

  setCreateRoleAsync(data: IRoleName) {
    return this.httpClient
      .post<any>(`${getPath('USERS_CREATE_ROLE_API_URL')}`, data)
      .pipe(catchError(this.handleError('setUpdateUserAsync')))
  }

  setUpdateRoleAsync(data: IRoleName) {
    return this.httpClient
      .put<any>(`${getPath('USERS_UPDATE_ROLE_API_URL')}`, data)
      .pipe(catchError(this.handleError('setUpdateUserAsync')))
  }

  setNewDepartmentAsync(data: { name: string; displayName: string }) {
    return this.httpClient
      .post<any>(`${getPath('USERS_CREATE_DEPARTMENT_API_URL')}`, data)
      .pipe(catchError(this.handleError('setNewDepartmentAsync')))
  }

  getRolePermissionsAsync(): Observable<ITeamRolePermission[]> {
    return this.httpClient
      .get<any>(`${getPath('ROLES_PERMISSIONS_API_URL')}`)
      .pipe(catchError(this.handleError('getRolePermissionsAsync')))
  }
}
