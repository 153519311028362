// ang
import { Component, OnInit, Input } from '@angular/core'

// app
import { IAppNotification } from '../../../../core/models'
import { NotificationService, StoreNotificationsService } from '../../../../core/services'

@Component({
  selector: 'ef-notific-item',
  templateUrl: './notific-item.component.html',
  styleUrls: ['./notific-item.component.scss'],
})
export class NotificItemComponent implements OnInit {
  @Input() notification: IAppNotification

  showSettings: boolean = false

  constructor(private _httpService: NotificationService, private _storeService: StoreNotificationsService) {}

  ngOnInit() {}

  delete() {
    // todo - will be used delete opportunity?
    this._storeService.actionDeleteNotification(this.notification.id)
  }

  setReadStatus() {
    this._httpService.setReadStatusAsync().subscribe(res => {
      this.notification.isRead = true
    })
  }
}
