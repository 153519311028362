export const environment = {
  production: true,
  serverUrl: 'https://identity.easiflow.io',
  apiEndpointUrl: 'https://api.easiflow.io',
  landingUrl: 'https://www.easiflow.io',
  // appServerUrl: 'https://app.easiflow.io',
  httpInterceptor: {
    allowedList: ['https://identity.easiflow.io/*', 'https://api.easiflow.io/*']
  }
}
