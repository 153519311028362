// ang
import { Component, Input, OnInit } from '@angular/core'
import { IUser } from '../../../shared/models/'

@Component({
  selector: 'ef-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss']
})
export class AvatarListComponent implements OnInit {
  @Input() users: IUser[]

  constructor() {}

  ngOnInit() {}
}
