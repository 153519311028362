import { IProcessNavigationCategoryMenuItem } from './process-navigation-category-menu-item.model'

export class IProcessNavigationCategory {
  // data
  id: string
  categoryName: string
  displayName: string
  sortOrder: number
  isActive: boolean

  // items
  menuItems: IProcessNavigationCategoryMenuItem[]

  // created
  createdBy: string
  createdDate: string

  // deleted
  deleted: boolean
  deletedDate: string
  deletedByUserId: string
}

export class IProcessMenuItem {
  orgId: string
  categoryId: string
  name: string
  order: number

  // created
  createdBy: string
  createdDate: string

  // deleted
  deleted: boolean
  deletedDate: string
  deletedBy: string
}
