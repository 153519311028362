// angular
import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
// lib
import { ObjectID } from 'bson'
// app
import { IDeltaTime } from '../models'

const uniqueAppIdHashList = {}

export function getUniqueAppIdHashList() {
  return uniqueAppIdHashList
}

export function generateUniqueId() {
  const id = new ObjectID().toString()
  if (!uniqueAppIdHashList[id]) {
    uniqueAppIdHashList[id] = true
    return id
  } else {
    return generateUniqueId()
  }
}

// lorem ipsum
export function generateLoremIpsum(maxLength?: number) {
  let result = 'Lorem '
  const text =
    'ipsum dolor sit amet, consectetur adipisicing elit. Perferendis nisi quae dolore architecto neque voluptatum atque, cumque ratione aliquid nulla. ipsum dolor sit amet, consectetur adipisicing elit. Perferendis nisi quae dolore architecto neque voluptatum atque, cumque ratione aliquid nulla.'
  const arr = text.split(' ')
  let lengthNewText = Math.round(Math.random() * arr.length)
  if (typeof maxLength === 'number') {
    lengthNewText = maxLength <= lengthNewText ? maxLength : lengthNewText
  }
  for (let i = 0; i < lengthNewText; ++i) {
    const randIndex = Math.round(Math.random() * lengthNewText)
    result += arr[randIndex] + ' '
  }
  return result
}

// NGB DATE

export function converFromNgbFormatToDate(ngbDate: NgbDate) {
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
}
export function converFromStringDateToNgbFormat(d: string): NgbDateStruct {
  const date: Date = new Date(d)
  return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
}
export function converFromNgbFormatToDateMilisec(ngbDate: NgbDate): number {
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime()
}

function getPrettyDeltaTime(ms: number): IDeltaTime {
  let cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(ms / cd),
    h = Math.floor((ms - d * cd) / ch),
    m = Math.round((ms - d * cd - h * ch) / 60000),
    pad = function(n) {
      return n < 10 ? '0' + n : n
    }
  if (m === 60) {
    h++
    m = 0
  }
  if (h === 24) {
    d++
    h = 0
  }
  return {
    days: d,
    hours: h,
    minutes: m
  }
}

// left time (left to deadline)

export function getPrettyLeftTime(ms: number): IDeltaTime {
  return getPrettyDeltaTime(ms)
}

// passed time (passed after now)

export function getPrettyPassedTime(ms: number): IDeltaTime {
  return getPrettyDeltaTime(ms)
}

// CREATE QUERY PARAMS
export function createQueryParams(data: object): HttpParams {
  let httpParams = new HttpParams()
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item, index) => {
        httpParams = httpParams.append(key + '[' + index + ']', item)
      })
    } else {
      httpParams = httpParams.append(key, data[key])
    }
  })

  return httpParams
}

export function createSearchParams(obj: object): URLSearchParams {
  const params: URLSearchParams = new URLSearchParams()
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      params.set(property, obj[property])
    }
  }
  return params
}

export const toTitleCase = (word: string): string => {
  if (!word[0]) {
    return word[0]
  }
  const firstCharacter = word[0].toUpperCase()
  return `${firstCharacter}${word.substring(1, word.length)}`
}

export const toSentenceCase = (sentence?: string): string => {
  if (!sentence) {
    return sentence
  }
  const wordArray = sentence.split(' ')
  wordArray[0] = toTitleCase(wordArray[0])
  return wordArray.join(' ')
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  // todo define all functions return
  generateUniqueId: any
  converFromNgbFormatToDate: any
  converFromStringDateToNgbFormat: any
  converFromNgbFormatToDateMilisec: any
  getPrettyLeftTime: any
  getPrettyPassedTime: any
  createQueryParams: any
  createSearchParams: any

  constructor() {
    this.generateUniqueId = generateUniqueId
    this.getPrettyLeftTime = getPrettyLeftTime
    this.getPrettyPassedTime = getPrettyPassedTime
    this.converFromNgbFormatToDate = converFromNgbFormatToDate
    this.converFromStringDateToNgbFormat = converFromStringDateToNgbFormat
    this.converFromNgbFormatToDateMilisec = converFromNgbFormatToDateMilisec
    this.createQueryParams = createQueryParams
    this.createSearchParams = createSearchParams
  }
}
