// ang
import { Component, OnInit } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'

// app
import { NotificationService } from '../../../../core/services'
import * as models from '../../../../core/models'

@Component({
  selector: 'ef-notific-push-list',
  templateUrl: './notific-push-list.component.html',
  styleUrls: ['./notific-push-list.component.scss'],
  animations: [
    // !!! - default translateX have to equal to value in scss
    trigger('animation', [
      transition(':enter', [
        style({
          transform: 'translateX(500px)',
          opacity: '0',
        }),
        animate(
          '200ms linear',
          style({
            transform: 'translateX(10px)',
            opacity: '1',
          })
        ),
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(10px)',
          opacity: '1',
        }),
        animate(
          '200ms linear',
          style({
            transform: 'translateX(500px)',
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class NotificPushListComponent implements OnInit {
  list: Array<models.IAppNotification | models.IAppNotificationSimple>

  constructor(private _notificationService: NotificationService) {}

  ngOnInit() {
    this.list = this._notificationService.getNotificPushList()
  }
}
