// ang
import { IRoleName, IUser } from '@/app/shared/models'
import { IOrganization } from '@/app/shared/models/organization.model'
import { Injectable } from '@angular/core'
// lib
import { select, Store } from '@ngrx/store'
import * as coreState from '../../states/'
import * as coreAction from '../../states/actions'

@Injectable({
  providedIn: 'root'
})
export class StoreAuthService {
  constructor(private _coreStore: Store<coreState.CoreState>) {}

  // ACTIONS
  actionSetAuthUser(data: IUser) {
    this._coreStore.dispatch(new coreAction.SetAuthUser(data))
  }

  actionSetOrganization(data: IOrganization) {
    this._coreStore.dispatch(new coreAction.SetOrganization(data))
  }

  setAllUsers(users: IUser[]) {
    this._coreStore.dispatch(new coreAction.SetAllUsers(users))
  }

  setAllRoles(roles: IRoleName[]) {
    this._coreStore.dispatch(new coreAction.SetAllRoles(roles))
  }

  // SELECTS
  getAuthUser() {
    return this._coreStore.pipe(select(coreState.selectAuthUser))
  }

  // getUser() {
  //   return this._coreStore.pipe(select(coreState.selectUser))
  // }

  getOrganization() {
    return this._coreStore.pipe(select(coreState.selectOrganization))
  }

  getAllUsers() {
    return this._coreStore.pipe(select(coreState.getAllUsers))
  }

  getAllRoles() {
    return this._coreStore.pipe(select(coreState.getAllRoles))
  }

  getUsersByIds(data: { userIds: string[]; roleNames: string[] }) {
    return this._coreStore.pipe(select(coreState.getUsersByIds, data))
  }
}
