// ang
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

// app

@Component({
  selector: 'ef-selector-selected-item',
  templateUrl: './user-selector-selected-item.component.html',
  styleUrls: ['./user-selector-selected-item.component.scss']
})
export class SelectorSelectedItemComponent implements OnInit {
  @Input() type: 'user' | 'role'
  @Input() name: string
  @Input() avatar: string

  @Output() delete = new EventEmitter()

  constructor() {}

  ngOnInit() {}
}
