import { Component, OnInit, ViewChild, Input, ElementRef, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'ef-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent implements OnInit {
  @Input() title: string = 'upload'
  @Input() cssClass: string = 'ef-btn ef-btn-default'

  @Output() selected: EventEmitter<File> = new EventEmitter()

  @ViewChild('input') input: ElementRef

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.input.nativeElement.onchange = event => {
      this.selected.emit(this.input.nativeElement.files[0])
    }
  }
}
