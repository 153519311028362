import { Component, OnInit } from '@angular/core'
import { NavigationTogglingService } from '../../../core/services'

@Component({
  selector: 'ef-profile-auth',
  templateUrl: './profile-auth.component.html',
  styleUrls: ['./profile-auth.component.scss']
})
export class ProfileAuthComponent implements OnInit {
  tabList = ['overview']
  currTab = 'overview'
  constructor(public navigationService: NavigationTogglingService) {}

  ngOnInit() {}

  setTab(tab) {
    this.currTab = tab
  }
}
