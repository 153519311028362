// ang
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
// app
import { TIMEZONE_LIST } from './timezone-list'

@Component({
  selector: 'ef-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss']
})
export class TimezoneSelectorComponent implements OnInit {
  @Input() initValue: string
  @Input() required: boolean = false
  @Input() showErrorStyles: boolean = false
  @Input() form: FormGroup
  @Input() controlName: string

  list = TIMEZONE_LIST

  defaultValue = this.list.find(a => a.default).value

  @Output() changeSelected: EventEmitter<string> = new EventEmitter()

  constructor() {}

  ngOnInit() {
    if (!this.initValue) {
      this.initValue = this.defaultValue
    }
  }

  onChange(val: string) {
    this.changeSelected.emit(val)
  }
}
