import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'ef-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss'],
})
export class InputErrorsComponent implements OnInit {
  @Input() form: FormGroup
  @Input() controlName: string

  // required data
  @Input() minLength: number
  @Input() maxLength: number
  @Input() min: number
  @Input() max: number

  // custom messages
  @Input() customMinLengthMsg: number
  @Input() customMaxLengthMsg: number
  @Input() customMinMsg: number
  @Input() customMaxMsg: number
  @Input() customPatternMsg: string
  @Input() customrRequiredMsg: number

  constructor() {}

  ngOnInit() {}
}
