export class IStageTreeItem {
  id: string
  treeType: 'control' | 'stage' | 'section' | 'noDataFoundText'
  processType?: string
  isDataControl?: boolean // for items with 'control' type
  processSubType?: string // stage types - single | split, control types - input, title etc
  title?: string
  parentSplitStage?: any
  parentId?: string
  parentStageId?: string
  parentStageName?: string
  parentCardId?: string
  parentCardName?: string
  parentSectionId?: string
  parentSectionName?: string
  firstParentType?: string
  childAmount?: number
  childType?: string
  childTreeList?: any[]
  childProcessList?: any[]
  isCollapsed?: boolean
  isChecked?: boolean
  isShowBySearch?: boolean
  link?: any // Stage|Card|Section|Control
}
