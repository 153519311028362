import { Directive, HostListener, ElementRef, Output, EventEmitter } from '@angular/core'
import { UtilsService } from '../services/'
import * as $ from 'jquery'

@Directive({
  selector: '[efDetectOutsideClick]',
})
export class DetectOutsideActionDirective {
  id: string
  isInited: boolean = false

  @Output() efDetectOutsideClickOn = new EventEmitter()

  // !!! only click
  @HostListener('document:click', ['$event'])
  detect(event) {
    this.detectOutsideAction(event)
  }

  constructor(private _elemRef: ElementRef, private _utilsService: UtilsService) {
    this.id = this._utilsService.generateUniqueId()
    $(this._elemRef.nativeElement).attr('data-detect-outside-click-id', this.id)
  }

  detectOutsideAction(event) {
    const isInnerAction = $.contains(this._elemRef.nativeElement, event.srcElement)
    if (!isInnerAction) {
      this.efDetectOutsideClickOn.emit()
    }
  }
}
