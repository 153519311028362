import { IDepartment, IRoleName, IUser, IUserRolePermission } from '@/app/shared/models'
import { Injectable } from '@angular/core'
// lib
import { select, Store } from '@ngrx/store'
// app
import { ITeamDepartment, ITeamPosition, ITeamRole } from '../models'
import * as teamAction from '../states/actions/team.actions'
import * as teamState from '../states/reducers'

@Injectable({
  providedIn: 'root'
})
export class TeamStoreService {
  constructor(private _teamStore: Store<teamState.TeamState>) {}

  // ACTIONS

  // departments
  actionSetDepartmentMemberList(data: { list: ITeamDepartment[] }) {
    this._teamStore.dispatch(new teamAction.SetDepartmentMemberList(data))
  }

  actionSetDepartmentList(data: { list: IDepartment[] }) {
    this._teamStore.dispatch(new teamAction.SetDepartmentList(data))
  }

  actionSetRoleMemberList(data: { list: ITeamRole[] }) {
    this._teamStore.dispatch(new teamAction.SetRoleMemberList(data))
  }

  actionSetRoleList(data: { list: IRoleName[] }) {
    this._teamStore.dispatch(new teamAction.SetRoleList(data))
  }

  actionSetMemberList(data: { list: IUser[] }) {
    this._teamStore.dispatch(new teamAction.SetMemberList(data))
  }

  actionAddDepartment(data: ITeamDepartment) {
    this._teamStore.dispatch(new teamAction.AddDepartment(data))
  }

  // positions
  actionAddPosition(data: { departmentId: string; position: ITeamPosition }) {
    this._teamStore.dispatch(new teamAction.AddPosition(data))
  }

  //permissions
  actionSetRolePermissionList(data: { list: IUserRolePermission[] }) {
    this._teamStore.dispatch(new teamAction.SetRolePermissionList(data))
  }

  // members
  // actionSetCurrUserProfile(data: IUser) {
  //   this._teamStore.dispatch(new teamAction.SetCurrUserProfile(data))
  // }

  // SELECTS

  getDepartmentMemberList() {
    return this._teamStore.pipe(select(teamState.selectDepartmentMemberList))
  }

  getDepartmentList() {
    return this._teamStore.pipe(select(teamState.selectDepartmentList))
  }

  getRoleMemberList() {
    return this._teamStore.pipe(select(teamState.selectRoleMemberList))
  }

  getRoleList() {
    return this._teamStore.pipe(select(teamState.selectRoleList))
  }

  getMemberList() {
    return this._teamStore.pipe(select(teamState.selectMemberList))
  }

  getRolePermissionList() {
    return this._teamStore.pipe(select(teamState.selectRolePermissionList))
  }

  // getCurrUserProfile() {
  //   return this._teamStore.pipe(select(teamState.selectUserProfile))
  // }
}
