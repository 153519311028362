// ang
import { Component, OnInit, Input } from '@angular/core'

// lib
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

// app
import { ISearchGlobalResultProcess } from '../../../../../../core/models'

@Component({
  selector: 'ef-search-global-results-process-item',
  templateUrl: './process-item.component.html',
  styleUrls: ['./process-item.component.scss'],
})
export class SearchGlobalResultsProcessItemComponent implements OnInit {
  @Input() searchValue: string
  @Input() process: ISearchGlobalResultProcess

  constructor(private _activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closeModal() {
    this._activeModal.close()
  }
}
