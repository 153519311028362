// ang
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as lodash from 'lodash'
import { catchError } from 'rxjs/operators'
import { CORE_CONFIG } from '../../../core/core.config'
import { UtilsService } from '../../../shared/services/utils.service'
import * as models from '../../models'
import { HandleError, HttpErrorHandler } from '../errors/http-error-handler.service'
// app
import { getPath } from './../../api-list/'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public newNotificAmount: number = 0
  public onRingAnimation: boolean = false
  private handleError: HandleError
  deleteWithDelay = []
  _notificPushList: Array<models.IAppNotification | models.IAppNotificationSimple> = []
  public isDropdownListOpen: boolean = false
  public isFullListOpen: boolean = false

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private _utilsService: UtilsService
  ) {
    this.handleError = httpErrorHandler.createHandleError('NotificationsService')
  }

  getNotificListAsync(data: { amount: number; timestamp: string; type?: string }) {
    // Dom TODO - Notification Controller -get-list method needed
    return this.http
      .get<models.IAppNotification>(`${getPath('NOTIFICATIONS_GET_LIST_API_URL')}`, {
        params: this._utilsService.createQueryParams(data)
      })
      .pipe(catchError(this.handleError('getNotificListAsync')))
  }

  setAllNotificIsReadAsync() {
    // Dom TODO - Notification Controller -set-all-read method needed
    return this.http
      .post<string>(`${getPath('NOTIFICATIONS_SET_ALL_READ_API_URL')}`, {})
      .pipe(catchError(this.handleError('setAllNotificIsReadAsync')))
  }

  setReadStatusAsync() {
    // Dom TODO - Notification Controller -set-read-status method needed
    return this.http
      .post<string>(`${getPath('NOTIFICATIONS_SET_READ_STATUS_API_URL')}`, {})
      .pipe(catchError(this.handleError('setReadStatusAsync')))
  }

  getNotificPushList() {
    return this._notificPushList
  }

  setNotificPush(notific: models.IAppNotification | models.IAppNotificationSimple) {
    // show message in view
    this._notificPushList.unshift(notific)
    this.deleteWithDelay.push(
      setTimeout(() => {
        this.deleteNotificPush(notific.id)
      }, CORE_CONFIG.NOTIFICATION_PUSH_TIME_IN_VIEW)
    )
    // if (!this.isDropdownListOpen && !this.isFullListOpen) {
    this.newNotificAmount++
    this.onRingAnimation = true
    setTimeout(() => {
      this.onRingAnimation = false
    }, 2000)
    // }
  }

  deleteNotificPush(notificId: string) {
    lodash.remove(this._notificPushList, { id: notificId })
  }
}
