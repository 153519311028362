import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BtnGroupItem } from '../../../shared/models/'

@Component({
  selector: 'ef-btn-group',
  templateUrl: './btn-group.component.html',
  styleUrls: ['./btn-group.component.scss']
})
export class BtnGroupComponent implements OnInit {
  @Input() title: string
  @Input() buttonLabels: BtnGroupItem[]
  @Input() selectedLabel: string

  @Output() changeStatus: EventEmitter<any> = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  changeState(label: BtnGroupItem) {
    this.selectedLabel = label.value
    this.changeStatus.emit(label)
  }
}
