import { IDepartment, IRoleName, IUser, IUserRolePermission } from '@/app/shared/models'
import {} from '../../factory/'
import { ITeamDepartment, ITeamRole } from '../../models/'
import { TeamActions, TeamActionTypes } from '../actions/team.actions'

export interface TeamState {
  departmentMemberList: ITeamDepartment[]
  departmentList: IDepartment[]
  currUserProfile: IUser
  roleMemberList: ITeamRole[]
  roleList: IRoleName[]
  memberList: IUser[]
  rolePermissioList: IUserRolePermission[]
}

export const initialState: TeamState = {
  departmentMemberList: [],
  departmentList: [],
  currUserProfile: null,
  roleMemberList: [],
  roleList: [],
  memberList: [],
  rolePermissioList: []
}

export function reducer(state = initialState, action: TeamActions): TeamState {
  switch (action.type) {
    // department
    case TeamActionTypes.SetDepartmentMemberList: {
      return {
        ...state,
        departmentMemberList: action.payload.list
      }
    }

    // department
    case TeamActionTypes.SetDepartmentList: {
      return {
        ...state,
        departmentList: action.payload.list
      }
    }

    case TeamActionTypes.SetRoleMemberList: {
      return {
        ...state,
        roleMemberList: action.payload.list
      }
    }

    case TeamActionTypes.SetRoleList: {
      return {
        ...state,
        roleList: action.payload.list
      }
    }

    case TeamActionTypes.SetRolePermissionList: {
      return {
        ...state,
        rolePermissioList: action.payload.list
      }
    }

    case TeamActionTypes.SetMemberList: {
      return {
        ...state,
        memberList: action.payload.list
      }
    }

    case TeamActionTypes.AddDepartment: {
      return {
        ...state,
        departmentMemberList: [...state.departmentMemberList, action.payload]
      }
    }

    // position
    case TeamActionTypes.AddPosition: {
      return {
        ...state,
        departmentMemberList: state.departmentMemberList.map(dep => {
          if (dep.id === action.payload.departmentId) {
            dep.positions.push(action.payload.position)
          }
          return dep
        })
      }
    }

    // member
    case TeamActionTypes.SetCurrUserProfile: {
      return {
        ...state,
        currUserProfile: action.payload
      }
    }

    default:
      return state
  }
}
