import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input() initColor

  showPicker: boolean = false
  currColor: string = '#ccc'

  @Output() changeColor: EventEmitter<any> = new EventEmitter()

  constructor() {}

  ngOnInit() {
    if (this.initColor) {
      this.currColor = this.initColor
    }
  }

  onActionOutside(event) {
    this.showPicker = false
  }

  onChangeColor(color: string) {
    this.currColor = color
    this.changeColor.emit(color)
  }
}
