import { IDataCaptureRecord, IdRecord } from './data-capture.model'
import { Stage } from './stage.model'

export class IProcessRecord extends IDataCaptureRecord {
  processName: string
  versionCreationDate?: Date
  versionNo: number
  publishStartDate?: Date
  publishEndDate?: Date
  stages: Stage[]
  isArchived: boolean
  isDraft: boolean
  isPublished: boolean

  // client side
  isNew?: boolean // (don't save in mongo model)
  lastSaveTimestamp?: string // (don't save in mongo model) for highlight save button (last save in database)
  lastChangeTimestamp?: string // (don't save in mongo model) (last change in client)
  isMarked: boolean
  source?: string

  static _processName: string;
}

export class IProcessSlim extends IDataCaptureRecord {
  name: string
  version: number
  isPublished: boolean
  sortOrder: number
}

export class IProcessPublish extends IdRecord {
  versionNo: number
  publishStartDate?: Date
  publishEndDate?: Date
}

export class IProcessCategory extends IDataCaptureRecord {
  categories: IProcessCategory[]
  categoryName: string
  displayName: string
  parentId: string
  processes: IProcessSlim[]
  sortOrder: number
  icon?: string
  bgColor?: string
  lastModified?: Date
  lastCreated?: Date
  path?: string
}