import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
// app
import { getPath } from '../../../core/api-list/'
import * as INTERFACES from './../../../core/models'

@Injectable({
  providedIn: 'root'
})
export class SearchGlobalHttpService {
  constructor(private http: HttpClient) {}

  getHintList(data: { searchValue: string }) {
    return this.http.get<INTERFACES.ISearchGlobalHintItem[]>(`${getPath('SEARCH_GLOBAL_GET_HINT_LIST_API_URL')}`, {
      params: data
    })
  }

  getTeamList() {
    return this.http.get<INTERFACES.ISearchGlobalProcessAssignedTo[]>(
      `${getPath('SEARCH_GLOBAL_GET_TEAM_LIST_API_URL')}`
    )
  }

  getFolderList() {
    return this.http.get<INTERFACES.ISearchGlobalProcessFolder[]>(`${getPath('SEARCH_GLOBAL_GET_FOLDER_LIST_API_URL')}`)
  }

  getProcessList(data: { searchValue: string; folderIdList: string[]; userIdList: string[] }) {
    return this.http.get<INTERFACES.ISearchGlobalResultProcess[]>(
      `${getPath('SEARCH_GLOBAL_GET_RESULT_PROCESS_LIST_API_URL')}`,
      {
        params: data
      }
    )
  }

  getUserList(data: { searchValue: string; departmentIdList: string[]; roleNamesList: string[] }) {
    return this.http.get<INTERFACES.ISearchGlobalResultUser[]>(
      `${getPath('SEARCH_GLOBAL_GET_RESULT_USERS_LIST_API_URL')}`,
      {
        params: data
      }
    )
  }
}
