import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar'
import { SNACK_BAR_TYPE } from '../../../../shared/enum/save-changes-actions.enum'
import { SnackBarModel } from '../../../../shared/models'

@Component({
  selector: 'ef-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  @Output() completed: EventEmitter<boolean> = new EventEmitter()

  setValues: SnackBarModel = {
    message: '',
    type: SNACK_BAR_TYPE.DEFAULT.toString(),
    action: 'x',
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarModel, public snackBar: MatSnackBar) {
    this.setValues = Object.assign({}, this.setValues, data)
  }

  ngOnInit() {
    this.openSnackBar();
  }

  openSnackBar() {
    setTimeout(() => {
      let snackbarRef = this.snackBar.open(this.setValues.message, this.setValues.action, {
        duration: this.setValues.duration,
        horizontalPosition: this.setValues.horizontalPosition,
        verticalPosition: this.setValues.verticalPosition,
        panelClass: [this.setValues.type]
      })
      snackbarRef.onAction().subscribe(() => {
        this.completed.emit(true)
      })
      snackbarRef.afterDismissed().subscribe(() => {
        this.completed.emit(true)
      })
    }, 0)
  }
}
