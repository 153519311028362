import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'dateLeft'
})
export class DateLeftPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return 'No deadline'
    }
    // Parse given value to moment duration
    let totalRemainingSeconds = Math.floor((+new Date(value) - +new Date()) / 1000)

    if (totalRemainingSeconds < 3600)
      // less than 1 hour will show as 'due soon'
      return 'due soon'
    const intervals = {
      y: 31536000,
      m: 2592000,
      w: 604800,
      d: 86400,
      h: 3600
    }

    let output = ''
    let counter
    for (const i in intervals) {
      counter = Math.floor(totalRemainingSeconds / intervals[i])
      if (counter > 0) {
        totalRemainingSeconds -= Math.floor(counter * intervals[i])
        output += counter + i + ' '
      }
    }

    return output
  }
}
