import { IUser } from '@/app/shared/models'
import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { HandleError, HttpErrorHandler } from '../errors/http-error-handler.service'
import { StoreAuthService } from './store.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  uniqueWorkspace: string
  isAuthenticated = true
  private handleError: HandleError
  localUrl: string = window.location.host

  constructor(
    private _storeAuthService: StoreAuthService,
    private httpErrorHandler: HttpErrorHandler,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.handleError = this.httpErrorHandler.createHandleError('AuthService')
    this.uniqueWorkspace = environment.production ? window.location.host.split('.')[0] : this.localUrl
  }

  getUniqueWorkspaceUrl() {
    return this.uniqueWorkspace
  }

  getOrgId() {
    const realm = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : ''
    return realm
  }

  setAuthenticated(authenticated) {
    this.isAuthenticated = authenticated
  }

  isLoggedIn() {
    return this.isAuthenticated
  }

  setProfile(profile: IUser) {
    this._storeAuthService.getAuthUser().subscribe(user => {
      if (!user) {
        this._storeAuthService.actionSetAuthUser(profile)
      }
    })
  }

  setAllUsers(users: IUser[]) {
    this._storeAuthService.setAllUsers(users)
  }
}
