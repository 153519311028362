import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-btn-add',
  templateUrl: './btn-add.component.html',
  styleUrls: ['./btn-add.component.scss'],
})
export class BtnAddComponent implements OnInit {
  @Input() title: string

  @Output() onClick = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  click() {
    this.onClick.emit()
  }
}
