export class IVersion {
  id: string
  isArchived: boolean
  isDraft: boolean
  isPublished: boolean
  versionCreationDate?: Date
  versionNo: number
  publishStartDate?: Date
  publishEndDate?: Date
}
