// ang
import { Component, ElementRef, OnInit, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms'
import { DatePipe } from '@angular/common'

// lib
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDatepicker,
  NgbDate
} from '@ng-bootstrap/ng-bootstrap'
import { UtilsService } from '../../../shared/services/'

@Component({
  selector: 'ef-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
  @Input() initDate: any // string - single (current), string[] - range
  @Input() showIcon: boolean = false
  @Input() maxDateString: string
  @Input() minDateString: string
  @Input() format: string = 'EEEE, MMMM d, y'
  @Input() required: boolean
  @Input() disabled: boolean
  @Input() parentForm: FormGroup
  @Input() controlName: string
  @Input() lastValue: string
  @Input() placeholder: string = 'select date'
  @Input() isRangeType: boolean = false
  @Input() rangeType: string // from | to
  @Input() showErrorStyles: boolean = false

  // toogle dropdown
  isFirstOpen: boolean = true
  showCalendar: boolean = false
  showTime: boolean = false

  // single
  today: Date
  viewCurrDate: Date
  viewCurrDateWithFormat: string
  ngbCurrDate: NgbDateStruct
  minDate: NgbDateStruct
  maxDate: NgbDateStruct
  currTime: number = 0 // ms
  maxDateMs: number // miliseconds
  minDateMs: number // miliseconds

  // form data
  abstractControl: AbstractControl
  formControl: FormControl = new FormControl()
  formControlName: string

  dateValue: string

  @ViewChild('datePicker') datePicker: NgbDatepicker

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  constructor(
    private _datePipe: DatePipe,
    public elementRef: ElementRef,
    private _ngbCalendar: NgbCalendar,
    private _utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.today = new Date()
    this._initInputDate()
    this._initMinDate()
    this._initMaxDate()
    this._initFormController()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initDate && !changes.initDate.firstChange) {
      this._initInputDate()
    }
    if (changes.disabled && !changes.disabled.firstChange) {
      const formControl = this.parentForm ? this.abstractControl : this.formControl
      changes.disabled.currentValue ? formControl.disable() : formControl.enable()
    }
    if (changes.minDateString && !changes.minDateString.firstChange) {
      this._initMinDate()
    }
    if (changes.maxDateString && !changes.maxDateString.firstChange) {
      this._initMaxDate()
    }
  }

  ngOnDestroy() {
    if (this.parentForm) {
      this.parentForm.removeControl(this.formControlName)
    }
  }

  private _initMinDate() {
    if (this.minDateString) {
      this.minDate = this._utilsService.converFromStringDateToNgbFormat(this.minDateString)
      this.minDateMs = this._utilsService.converFromNgbFormatToDateMilisec(this.minDateString)
    } else {
      this.minDate = null
      this.minDateMs = null
    }
  }

  private _initMaxDate() {
    if (this.maxDateString) {
      this.maxDate = this._utilsService.converFromStringDateToNgbFormat(this.maxDateString)
      this.maxDateMs = this._utilsService.converFromNgbFormatToDateMilisec(this.maxDateString)
    } else {
      this.maxDate = null
      this.maxDateMs = null
    }
  }

  private _getTimeFromDate(date: Date): number {
    return date.getHours() * 60 * 60 * 1000 + date.getMinutes() * 60 * 1000
  }

  private _initInputDate() {
    if (this.initDate && typeof this.initDate === 'string') {
      this.dateValue = this.initDate
    }
    if (this.lastValue) {
      this.dateValue = this.lastValue
    }
    if (this.dateValue) {
      this.viewCurrDate = new Date(this.dateValue)
      this.ngbCurrDate = this._utilsService.converFromStringDateToNgbFormat(this.viewCurrDate)
      this.currTime = this._getTimeFromDate(this.viewCurrDate)
    } else {
      this.viewCurrDate = null
      this.currTime = null
    }
  }

  private _initFormController() {
    const validators = [this.required ? Validators.required : null].filter(validator => validator)
    this.formControlName = this.controlName || this._utilsService.generateUniqueId()
    this.formControl = new FormControl(this.viewCurrDate, validators)
    if (this.parentForm) {
      this.parentForm.addControl(this.formControlName, this.formControl)
      this.abstractControl = this.parentForm.controls[this.formControlName]
      if (this.disabled) {
        this.abstractControl.disable()
      }
    } else {
      if (this.disabled) {
        this.formControl.disable()
      }
    }
  }

  // select date

  dateSelected(ngbDate: NgbDate) {
    this.viewCurrDate = this._utilsService.converFromNgbFormatToDate(ngbDate)
    if (this.currTime > 0) {
      this.viewCurrDate = new Date(this.viewCurrDate.getTime() + this.currTime)
    }
    this.valueChanged.emit(this.viewCurrDate.toString())
    if (this.parentForm) {
      this.parentForm.controls[this.formControlName].setValue(this.viewCurrDate.toString())
    }
    this.showCalendar = false

    this.viewCurrDateWithFormat = this._datePipe.transform(this.viewCurrDate, this.format)
  }

  setCurrTime(ms: number) {
    this.currTime = ms
  }

  // outside action

  onActionOutside(ev) {
    this.showCalendar = false
  }
}
