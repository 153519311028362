// ang
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HandleError, HttpErrorHandler } from '../../core/services/errors/http-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class HttpSharedService {
  private handleError: HandleError

  constructor(private _http: HttpClient, private _httpErrorHandler: HttpErrorHandler) {
    this.handleError = this._httpErrorHandler.createHandleError('HttpSharedService')
  }
}
