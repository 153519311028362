
import { Stage } from '../../../modules/processes/models'

export class IClientSnapshot {
  id: string
  currStageName: string
  currUserId: string
  currUserName: string
  currUserAvatar: string
  deadline: string
  stage: Stage
}
