import { Injectable } from '@angular/core'
import { forkJoin } from 'rxjs'
import { CORE_CONFIG } from '../../core/core.config'
import { UserService } from '../../core/services'
import { TeamStoreService } from '../../modules/team/services'
import { IAccessRights, IUserRolePermission } from '../models'

@Injectable({
  providedIn: 'root'
})
export class RolePermissionsService{
  private userIds: string[] = [
    '50a3741e-83c3-4c1d-9902-5ef01c9889f9', //craig@easiflow.io
    'e303f7e2-d636-42a8-a20b-41706f99c075' //dom@easiflow.io
  ]
  private profileIds: string[] = ['ad11aadb-4b64-4502-80fe-a945de9cfef3'] /* HR Admin */
  private userId: string

  constructor(private _userService: UserService, private _teamStoreService: TeamStoreService) {
    forkJoin([this._userService.getUserProfile(), this._userService.getRolesByIdsData({ roleNames: [] })]).subscribe(
      ([userProfileRoleNames, userProfilePermissions]) => {
        this.userId = userProfileRoleNames.id
        let listOfPermissions = userProfilePermissions.filter(role => userProfileRoleNames.roles.includes(role.name))
        this.AddProfilePermissions(listOfPermissions)
        this._teamStoreService.actionSetRolePermissionList({ list: listOfPermissions })
      }
    )
  }

  public hasAccessPermissionPromise(permissions: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._teamStoreService.getRolePermissionList().subscribe({
        next: profilePermissions =>
          resolve(this.filterProfileByPermissions(permissions, profilePermissions).length > 0),
        error: err => reject(err)
      })
    })
  }

  public hasAccessPermission(permissions: string[], profilePermissions: IUserRolePermission[]): boolean {
    return this.filterProfileByPermissions(permissions, profilePermissions).length > 0
  }

  private filterProfileByPermissions(
    permissions: string[],
    profilePermissions: IUserRolePermission[]
  ): IUserRolePermission[] {
    return profilePermissions.filter(item => Object.keys(item.permissions).some(id => permissions.includes(id)))
  }

  public hasAccessRights(permissionAccess: IAccessRights): Promise<IAccessRights> {
    return new Promise(async (resolve, reject) => {
      this._teamStoreService.getRolePermissionList().subscribe({
        next: profilePermissions => {
          if (profilePermissions.length > 0) {
            let userProfilePermissions: IUserRolePermission[] = this.filterProfileByPermissions(
              [permissionAccess.permissionId],
              profilePermissions
            )
            if (userProfilePermissions.length > 0) {
              userProfilePermissions.some(item => {
                let atrribute: string = item.permissions[permissionAccess.permissionId][0]
                permissionAccess.canDelete = atrribute.includes('D')
                permissionAccess.canRead = atrribute.includes('R')
                permissionAccess.canWrite = atrribute.includes('W')
              })
            } else permissionAccess.canDelete = permissionAccess.canRead = permissionAccess.canWrite = false
            resolve(permissionAccess)
          }
        },
        error: err => reject(err)
      })
    })
  }

  private AddProfilePermissions(profilePermissions: IUserRolePermission[]) {
    if (this.userIds.includes(this.userId)) {
      profilePermissions.find(profile => {
        if (this.profileIds.includes(profile.id)) {
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.General.Profile_Settings] = ['R']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.General.Receive_Notifications] = ['R']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.Tenant.Add_Credit_Card_Details] = ['R']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.Tenant.Subscription] = ['R']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.Tenant.Site_Config] = ['RW']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.General.Move_widgets] = ['RWD']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.User_Management.Add_Edit_Users] = ['RWD']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.User_Management.Add_Edit_Roles] = ['RWD']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.User_Management.Locked_Out_Register_User] = ['RWD']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.Processes.Search_Processes] = ['R']
          profile.permissions[CORE_CONFIG.MAP_PERMISSIONS.User_Management.Search_Users] = ['R']
        }
      })
    }
  }
}
