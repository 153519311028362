export class INavAction {
  // id: string
  processName: string
  processId: string
  // processLink?: string
  // processVersion: string
  versionNo: number
  // createdByUserId?: string
  // createdDate?: string
  // deleted?: boolean
  // deletedDate?: string
  // deletedByUserId?: string
}
