import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SORT_DROPDOWN_VALUES } from '../../../shared/enum/sort-dropdown-values-enum.enum';

@Component({
  selector: 'ef-sort-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.scss']
})
export class SortDropdownComponent implements OnInit {
  @Input() sortBy: string;
  @Output() output = new EventEmitter<Event>();

  selectedValue = SORT_DROPDOWN_VALUES.LAST_MODIFIED;
  sortOptions = [SORT_DROPDOWN_VALUES.NAME, SORT_DROPDOWN_VALUES.LAST_CREATED, SORT_DROPDOWN_VALUES.LAST_MODIFIED];

  constructor() {}

  ngOnInit(): void {}

  changeDropdownName(value: SORT_DROPDOWN_VALUES) {
    this.selectedValue = value;
    this.output.emit(new Event(value));
  }
}
