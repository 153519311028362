// ang
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
// lib
// app
import * as sharedModels from '../../../shared/models'

@Component({
  selector: 'ef-email-to',
  templateUrl: './email-to.component.html',
  styleUrls: ['./email-to.component.scss']
})
export class EmailToComponent implements OnInit {
  @ViewChild('emailInput', { static: true }) emailInput: ElementRef

  @Input() users: sharedModels.IUser[]
  @Input() emails: string[]
  @Input() roles: sharedModels.IRoleName[]
  @Input() showErrorStyles: boolean

  // email: string;
  showEmailError: boolean
  submitted: boolean
  emailControl: FormControl = new FormControl()

  @Output() addEmail: EventEmitter<string> = new EventEmitter<string>()
  @Output() deleteEmail: EventEmitter<string> = new EventEmitter<string>()
  @Output() deleteUser: EventEmitter<string> = new EventEmitter<string>()
  @Output() deleteRole: EventEmitter<string> = new EventEmitter<string>()

  constructor() {}

  @HostListener('click', ['$event'])
  onClick(e) {
    this.emailInput.nativeElement.focus()
  }

  ngOnInit() {}

  checkEmail(ev): void {
    this.showEmailError = false
    if (ev.keyCode === 13) {
      this.submitted = true
      if (this.emailControl.valid) {
        this.submitEmail()
      } else {
        this.showEmailError = true
      }
    }
  }

  submitEmail(): void {
    this.addEmail.emit(this.emailControl.value)
    this.emailControl.markAsPristine()
    this.emailControl.setValue(undefined)
    this.submitted = false
  }

  removeEmail(email: string): void {
    this.deleteEmail.emit(email)
  }

  removeRole(role: sharedModels.IRoleName): void {
    this.deleteRole.emit(role.id)
  }

  removeUser(user: sharedModels.IUser): void {
    this.deleteUser.emit(user.id)
  }
}
