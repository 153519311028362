import { SelectControlItem } from '../../../shared/models/'
import { PageBehaviour } from './page-behaviour.model'
import { Section } from './section.model'

export class Control {
  id: string
  type: string // todo - delete this and use from settings. input || checkbox || dropdown ...
  // data
  lastValue?: any
  pageBehaviours: PageBehaviour[]
  settings: ControlSettings
  dataSettings?: DataSettings
  // client side
  isNew?: boolean
}

// SETTINGS

export class ControlSettings {
  type: string // input || checkbox || dropdown ...
  isUnique: boolean

  // data
  dataType: string // text | number | password | date(string) | boolean
  title: string
  tooltip: string
  isDataControl: boolean // data control have some information in preview mode
  data: string | number // any data (link for embed video etc...)
  prefix: string
  suffix: string
  placeholder: string
  fileName: string
  altText: string
  description: string

  // date (date)
  includeCalendar: boolean
  dateFormat: string
  minDate: string // Date type in string format
  maxDate: string // Date type in string format
  dateRange?: string[] // date - 'from' 'to' in string

  // view
  isShow: boolean
  isDisabled: boolean

  //
  showTitle: boolean
  labelPosition: string
  icon: string
  height: string
  color: string
  align: string
  rows: number

  // css classes
  cssClassList: string[] // text decor classes from app custom wysiwig comp
  fontSize: string
  margin: string
  padding: string
  buttonType: string
  widthTemplate: string
  size: string

  // wysiwig
  wysiwig: boolean
  wysiwigData: string

  // validation
  isDisabledValidation: boolean
  regex: string
  isRequired: boolean
  minLength: number
  maxLength: number
  min: number
  max: number
  errorMsg: string
}

// DATA SETTINGS

export class DataSettings {
  items: DataSettingItem[]
  allowMultiSelect: boolean
  itemType: string
  defaultItem: string
  defaultItems?: string[]
  approverRoleNames?: string[] // new from 16.11.19 - for approver control
  approverUserIds?: string[] // new from 16.11.19 - for approver control
  allowAssignToAnyUser?: boolean
}

export class DataSettingItem {
  id: string
  name: string
  value: string
  type?: string // it can be some type of item (for example user || position in approvers control)
  title?: string
  booleanValue?: boolean
  parentListItemId?: string
  // additionalData?: any; // it can be any additional data, simple value, object, array that need in specific context
  section?: Section // for tabs control
}

// AVAILABLE CONTROL EVENTS

export class IControlEventItem extends SelectControlItem {
  controlList: string[]
}

export class IConditionItem extends SelectControlItem {
  dataTypes: string[]
  controlTypes: string[]
}

export class SelectControlActionItem extends SelectControlItem {
  inverseAction: string
  group?: string
}
