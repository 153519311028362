import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar'

export class SnackBarModel {
    message: string
    type: string
    action?: string
    duration?: number
    horizontalPosition?: MatSnackBarHorizontalPosition
    verticalPosition?: MatSnackBarVerticalPosition
}
