// ang
// app
import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { UtilsService } from '../../../../app/shared/services'
import { IDeltaTime } from '../../../shared/models'

@Component({
  selector: 'ef-deadline',
  templateUrl: './deadline.component.html',
  styleUrls: ['./deadline.component.scss']
})
export class DeadlineComponent implements OnInit {
  @Input() showMin: boolean = false
  @Input() remainingTime: boolean = false
  @Input() time: string // date string

  isWarning: boolean = false
  leftTimeMs: number
  leftTime: IDeltaTime

  constructor(private _commonUtilsService: UtilsService) {}

  ngOnInit() {
    this._initDeltaTime()
  }

  private _initDeltaTime() {
    this.leftTimeMs = new Date(this.time).getTime() - new Date().getTime()
    this.leftTime = this._commonUtilsService.getPrettyLeftTime(this.leftTimeMs)
    this.isWarning = this.leftTimeMs < 0
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.time && !changes.time.firstChange) {
      this._initDeltaTime()
    }
  }
}
