export class IDataCaptureWithoutIdRecord {
  createdBy?: string
  createdDate?: Date
  updatedDate?: Date = null
  updatedByUserId?: string = null
  deleted?: boolean = null
  deletedDate?: Date = null
  deletedByUserId?: string = null
}

export class IDataCaptureRecord extends IDataCaptureWithoutIdRecord {
  id: string
}

export class IdRecord {
  id: string
}
