export class IProcessNavigationCategoryMenuItem {
  // data
  id: string
  menuName: string
  displayName: string
  isActive: boolean

  // created
  createdBy: string
  createdDate: string

  // deleted
  deleted: boolean
  deletedDate: string
  deletedByUserId: string
}
