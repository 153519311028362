import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-delete-confirm-overlay',
  templateUrl: './delete-confirm-overlay.component.html',
  styleUrls: ['./delete-confirm-overlay.component.scss'],
})
export class DeleteConfirmOverlayComponent implements OnInit {
  @Input() isOpenConfirmOverlay = false
  @Input() title: string
  @Output() delete = new EventEmitter<boolean>()

  constructor() {}

  ngOnInit() {}

  closeConfirmOverlay() {
    this.isOpenConfirmOverlay = false
    this.delete.emit(false)
  }

  onDelete() {
    this.delete.emit(true)
    this.isOpenConfirmOverlay = false
  }
}
