import { NavigationTogglingService } from '../../../core/services/navigation/navigation-toggling.service'
import { IUser } from '@/app/shared/models'
import { DOCUMENT } from '@angular/common'
import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core'
import { KeycloakService } from 'keycloak-angular'
// lib
import { Subscription } from 'rxjs'
// app
import { environment } from '../../../../environments/environment'
import { CORE_CONFIG } from '../../../core/core.config'
import { NotificationService, StoreAuthService, StoreNotificationsService } from '../../../core/services'
import { TeamStoreService } from '../../../modules/team/services'
import { RolePermissionsService } from '../../../shared/services/role-permissions.service'
import { NotificationPushIntervalMock } from '../../../_helpers/tests/mock-factory/core/notification-push-interval.mock'

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class HeaderComponent implements OnInit, OnDestroy {
  subCompanySettings: Subscription
  subAuthUser: Subscription
  authUser: IUser
  companyName: string
  userName: string
  userposition: string
  isOpenNotific = false
  isOpenUserMenu = false
  dropdownSetting: boolean
  dropdownNotification: boolean
  subscriptions: boolean
  billings: boolean
  profile: boolean
  notific: boolean
  siteconfig: boolean
  constructor(
    public navigationService: NavigationTogglingService,
    public notificService: NotificationService,
    private authService: KeycloakService,
    private _storeAuthService: StoreAuthService,
    private _storeNotificService: StoreNotificationsService,
    public navService: NavigationTogglingService,
    private _rolePermissionsService: RolePermissionsService,
    @Inject(DOCUMENT) private doc: Document,
    private _teamStoreService: TeamStoreService
  ) {
    this.dropdownSetting = false
    this.dropdownNotification = false
  }

  ngOnInit() {
    // todo - temp for tests
    if (!environment.production) {
      NotificationPushIntervalMock(this.notificService, this._storeNotificService)
    }

    this.subAuthUser = this._storeAuthService.getAuthUser().subscribe(authUser => {
      this.authUser = authUser
    })

    this._storeAuthService.getOrganization().subscribe(org => {
      if (org) {
        this.companyName = org.displayName
      }
    })

    this.hasPermissions()
  }

  ngOnDestroy() {
    this.subAuthUser.unsubscribe()
  }

  toggleNotific(isOpen: boolean) {
    this.isOpenNotific = isOpen
  }

  onCloseNotificDropdown() {
    this.isOpenNotific = false
  }

  toggleUserMenu(isOpen: boolean) {
    this.isOpenUserMenu = isOpen
  }

  logout() {
    this.authService.logout(this.doc.location.origin)
  }

  toggleAppMenu() {
    this.navigationService.isOpen ? this.navigationService.hide() : this.navigationService.show(false)
  }

  hasPermissions() {
    this._teamStoreService.getRolePermissionList().subscribe(profilePermissions => {
      if (profilePermissions.length > 0) {
        this.subscriptions = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.Tenant.Subscription],
          profilePermissions
        )
        this.billings = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.Tenant.Billing],
          profilePermissions
        )
        this.profile = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.General.Profile_Settings],
          profilePermissions
        )
        this.notific = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.General.Receive_Notifications],
          profilePermissions
        )
        this.siteconfig = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.Tenant.Site_Config],
          profilePermissions
        )
      }
    })
  }
}
