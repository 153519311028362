import { INavAction } from './action-nav.model'

export class INavCategory {
  id: string
  title: string
  createdByUserId: string
  createdDate: string
  deleted: boolean
  deletedDate: string
  deletedByUserId: string
  editRoleList: string[]
  viewRoleList: string[]
  filesAm: number
  files: INavAction[]
}

export interface IFolder {
  id: string;
  parentId?: string;
  type?: string;
  title?: string;
  status?: number;
  spent?: number;
  isMarked?: boolean;
  created: string;
  modified: string;
  categoryName?: string;
  displayName?: string;
  itemCount?: number;
  icon?: string;
  bgColor?: string;
  subFolder?: IFolder[];
  draft?: boolean;
}