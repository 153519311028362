// ang
import { Component, OnInit, Input } from '@angular/core'

// app

@Component({
  selector: 'ef-tree-path',
  templateUrl: './tree-path.component.html',
  styleUrls: ['./tree-path.component.scss'],
})
export class TreePathComponent implements OnInit {
  @Input() path: string[]

  constructor() {}

  ngOnInit() {}
}
