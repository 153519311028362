import { IAccessRights, IUser } from '@/app/shared/models'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CORE_CONFIG } from '../../../../core/core.config'
import { StoreAuthService, UserService } from '../../../../core/services'
import { toTitleCase } from '../../../../shared/services'
import { RolePermissionsService } from '../../../../shared/services/role-permissions.service'
import { ImgCropperComponent } from '../../../atoms'

@Component({
  selector: 'ef-profile-auth-overview',
  templateUrl: './profile-auth-overview.component.html',
  styleUrls: ['./profile-auth-overview.component.scss']
})
export class ProfileAuthOverviewComponent implements OnInit {
  onUpdateSpinner = false
  user: IUser = {} as IUser
  profileForm: FormGroup
  isSubmitting = false

  // avatar change
  file: File
  base64: string
  toggleFileSelector = false //need for reinit input with type = 'file'
  isRoundAvatar = false
  public accessRights$: Promise<IAccessRights>

  constructor(
    private _modalService: NgbModal,
    private _userService: UserService,
    private _storeService: StoreAuthService,
    private fb: FormBuilder,
    private _rolePermissionsService: RolePermissionsService
  ) {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      position: ['', Validators.required],
      phoneNumber: ['', Validators.required]
    })
  }

  ngOnInit() {
    this._storeService.getAuthUser().subscribe(user => {
      if (user) {
        this.user = user
        this.user.position = toTitleCase(this.user.position)
        this.user.roles = [...this.user.roles.map(role => toTitleCase(role))]
        this.profileForm.patchValue(user)
      }
    })

    this.hasAccessRights()
  }

  submitForm() {
    this.isSubmitting = true
    this.onUpdateSpinner = true

    if (this.profileForm.valid) {
      const profile: IUser = {
        id: this.user.id,
        avatar: this.user.avatar,
        firstName: this.profileForm.controls.firstName.value,
        lastName: this.profileForm.controls.lastName.value,
        position: this.profileForm.controls.position.value,
        roles: this.user.roles,
        phoneNumber: this.profileForm.controls.phoneNumber.value,
        timeZone: this.profileForm.controls.timeZone.value
      }
      this._userService.setUpdateProfileAsync(profile).subscribe(
        () => {
          this.updateUser(this.profileForm.value)
          this.profileForm.markAsPristine()
          this.onUpdateSpinner = false
          this.isSubmitting = false
        },
        () => {
          this.isSubmitting = false
          this.onUpdateSpinner = false
        }
      )
    }
  }

  updateUser(values: Object) {
    Object.assign(this.user, values)
  }

  cancel() {
    this.profileForm.patchValue(this.user)
    this.profileForm.markAsPristine()
  }

  onUploadFile(file: File) {
    const modalRef = this._modalService.open(ImgCropperComponent, {
      windowClass: 'ef-modal-position-center'
    })
    modalRef.componentInstance.file = file
    modalRef.componentInstance.croppedResult.subscribe((res: { file: File; base64: string }) => {
      if (res) {
        this.base64 = res.base64
        this.file = res.file
        this.isRoundAvatar = false
        this.user.avatar = undefined
      }
      this.toggleFileSelector = !this.toggleFileSelector
    })
  }

  hasAccessRights() {
    this.accessRights$ = this._rolePermissionsService.hasAccessRights({
      permissionId: CORE_CONFIG.MAP_PERMISSIONS.User_Management.Add_Edit_Roles
    })
  }
}
