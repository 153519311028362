

class SharedConfig {
  readonly VALIDATION_YOUTUBE_LINK: RegExp
  readonly EMAIL_PATTERN: RegExp
  readonly DATE_FORMAT_ONLY_DATE: string
  readonly DATE_FORMAT_ONLY_TIME_FULL: string
  readonly DATE_FORMAT_ONLY_TIME_SHORT: string
  readonly DATE_FORMAT_DATE_WITH_FULL_TIME: string
  readonly DATE_FORMAT_DATE_WITH_SHORT_TIME: string
  readonly AVATAR_BG_COLORS: Array<{ charachters: string; color: string; textType: string }>
  readonly AVATAR_BG_COLORS_OTHER: string

  constructor() {
    this.VALIDATION_YOUTUBE_LINK = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
    this.EMAIL_PATTERN = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    this.DATE_FORMAT_ONLY_DATE = 'MMM d, y'
    this.DATE_FORMAT_ONLY_TIME_FULL = 'h:mm:ss a'
    this.DATE_FORMAT_ONLY_TIME_SHORT = 'h:mm a'
    this.DATE_FORMAT_DATE_WITH_FULL_TIME = 'MMM d, y, h:mm:ss a'
    this.DATE_FORMAT_DATE_WITH_SHORT_TIME = 'MMM d, y, h:mm a'

    ;(this.AVATAR_BG_COLORS = [
      {
        charachters: 'abcd',
        color: '#2AA2FA',
        textType: 'dark',
      },
      {
        charachters: 'efgh',
        color: '#38C2FF',
        textType: 'dark',
      },
      {
        charachters: 'ijkl',
        color: '#3E5473',
        textType: 'dark',
      },
      {
        charachters: 'mnop',
        color: '#536F96',
        textType: 'dark',
      },
      {
        charachters: 'qrstu',
        color: '#6483A7',
        textType: 'dark',
      },
      {
        charachters: 'vwxyz',
        color: '#7899C8',
        textType: 'dark',
      },
      {
        charachters: '0123456789',
        color: '#8b99a2',
        textType: 'dark',
      },
    ]),
      (this.AVATAR_BG_COLORS_OTHER = '#EDF1F9')
  }
}

const config = new SharedConfig()

export const SHARED_CONFIG = config
