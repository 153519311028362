import { AssignedUser } from '@/app/modules/dashboard/models'
import { WorkItemStatus } from '../../../../core/enum/work-item-status.enum'
import { IDataCaptureRecord } from '../data-capture.model'

export class IClientView {
  id: string
  isMarked: boolean
  actionDate?: Date
  assignedUser: AssignedUser
  clientRecord: IClientRecord
  clientHistoryRecords: IClientHistoryRecord[]
}

export class IClientRecord extends IDataCaptureRecord {
  processId: string
  processName: string
  versionNo: number
  activeCardId: string
  activeCardName: string
  isSubmitted: boolean
  status: WorkItemStatus
  data: string
}

export class IClientHistoryRecord extends IClientRecord {
  dateArchived: Date
}
