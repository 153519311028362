// ang
import { Component, OnInit, Input } from '@angular/core'
import { WorkItemStatus } from '../../../core/enum/work-item-status.enum'
// lib

// app

@Component({
  selector: 'ef-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss']
})
export class PassLabelComponent implements OnInit {
  @Input() isOverdue: boolean = false
  @Input() status: WorkItemStatus
  workItemStatus: typeof WorkItemStatus = WorkItemStatus

  toString() {
    return this.status.toString().replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  }

  constructor() {}

  ngOnInit() {}
}
