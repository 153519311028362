import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store'
import { environment } from '../../../environments/environment'
import * as fromCore from './reducer'

export interface CoreState {
  core: fromCore.CoreState
}

export const reducers: ActionReducerMap<CoreState> = {
  core: fromCore.reducer
}

export const selectCoreState = createFeatureSelector<CoreState>('coreState')

// auth
export const selectAuthUser = createSelector(selectCoreState, (state: CoreState) => state.core.authUser)
// export const selectUser = createSelector(selectCoreState, (state: CoreState) => state.core.user)
export const selectOrganization = createSelector(selectCoreState, (state: CoreState) => state.core.organization)

// users
export const getAllUsers = createSelector(selectCoreState, (state: CoreState) => state.core.allUsers)
export const getAllRoles = createSelector(selectCoreState, (state: CoreState) => state.core.allRoles)

export const getUsersByIds = createSelector(
  selectCoreState,
  (state: CoreState, props: { userIds: string[]; roleNames: string[] }) => {
    return state.core.allUsers?.filter(li => {
      return (
        props.userIds.includes(li.id) ||
        (li.roles.filter(f => props.roleNames.includes(f)) && props.roleNames.length !== 0) ||
        props.roleNames.length === 0
      )
    })
  }
)

// notific
export const selectNotificDropdownList = createSelector(selectCoreState, state => state.core.notificDropdownList)
export const selectNotificFullList = createSelector(selectCoreState, state => state.core.notificFullList)

export const metaReducers: MetaReducer<CoreState>[] = !environment.production ? [] : []
