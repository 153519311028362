// ang

// app
import { NotificationService, StoreNotificationsService } from '../../../../core/services'

export function NotificationPushIntervalMock(
  notificService: NotificationService,
  storeService: StoreNotificationsService
) {
  // const addItem = () => {
  //   const newNotific = NotificationItemMock()
  //   newNotific.createdAt = new Date().toString()
  //   newNotific.isRead = false
  //   notificService.setNotificPush(newNotific)
  //   // add to "dropdown header list" + "full list (page)"
  //   storeService.actionAddPushNotificToOpenLists({
  //     notific: newNotific,
  //     isDropdownListOpen: notificService.isDropdownListOpen,
  //     isFullListOpen: notificService.isFullListOpen,
  //   })
  // }
  // setTimeout(() => {
  //   addItem();
  // }, 50000)
  // setInterval(() => {
  //   addItem()
  // }, 5000)
}
