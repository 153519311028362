// ang
import { Component, OnInit } from '@angular/core'

// lib
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

// app

@Component({
  selector: 'ef-app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
