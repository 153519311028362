import { ISearchGlobalHintType, NavigationItem } from './models/'

class CoreConfig {
  readonly OCP_APIM_SUBSCRIPTION_KEY: string
  readonly NOTIFICATION_PUSH_TIME_IN_VIEW: number
  readonly SEARCH_GLOBAL_HINT_TYPE_LIST: Array<ISearchGlobalHintType>
  readonly PASSWORD_MIN_LENGTH: number
  readonly PASSWORD_MAX_LENGTH: number
  readonly PASSWORD_PATTERN: string
  readonly MAP_PERMISSIONS: {
    User_Management: {Add_Edit_Roles: string, Add_Edit_Users: string, Add_Edit_Widgets_Role: string, Impersonate_User: string, Locked_Out_Register_User: string, Search_Users: string},
    Tenant: {Add_Credit_Card_Details: string, Billing: string, Site_Config: string, Subscription: string, Tenant_Management: string},
    Processes: {Create_Edit_Process: string, Delete_Process: string, Search_Processes: string, Start_Individual_Process: string, View_All_Processes: string, View_Form: string, View_Linked_Process: string, View_Process_States: string},
    General: {Move_widgets: string, Profile_Settings: string, Receive_Notifications: string},
    Reporting: {Report_Generator: string, View_Reports: string}
  }
  constructor() {
    this.OCP_APIM_SUBSCRIPTION_KEY = '66343b59f4f74da2bf414d2962f0a3ef'

    // password requirements
    this.PASSWORD_MIN_LENGTH = 8
    this.PASSWORD_MAX_LENGTH = 16
    this.PASSWORD_PATTERN = `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\$@#%\^\&*?!.()+=-]).{${this.PASSWORD_MIN_LENGTH},${this.PASSWORD_MAX_LENGTH}}$`

    // notifications
    this.NOTIFICATION_PUSH_TIME_IN_VIEW = 7000
    
    this. MAP_PERMISSIONS= {
      User_Management: {
        Add_Edit_Roles: '624349a08ae61acb90c56db1', 
        Add_Edit_Users: '624349988ae61acb90c56db0', 
        Add_Edit_Widgets_Role: '624349ce8ae61acb90c56db5', 
        Impersonate_User: '624349b98ae61acb90c56db3', 
        Locked_Out_Register_User: '624349ab8ae61acb90c56db2', 
        Search_Users: '624349c48ae61acb90c56db4'
      },
      Tenant: {
        Add_Credit_Card_Details: '624349e48ae61acb90c56db7', 
        Billing: '624349ef8ae61acb90c56db8', 
        Site_Config: '62434a078ae61acb90c56dba', 
        Subscription: '624349f98ae61acb90c56db9', 
        Tenant_Management: '624349d88ae61acb90c56db6'
      },
      Processes: {
        Create_Edit_Process: '624349428ae61acb90c56da9', 
        Delete_Process: '6243498e8ae61acb90c56daf', 
        Search_Processes: '6243495a8ae61acb90c56dab', 
        Start_Individual_Process: '624349838ae61acb90c56dae', 
        View_All_Processes: '624349788ae61acb90c56dad', 
        View_Form: '6240e41dcc074f801524f028', 
        View_Linked_Process: '6243494b8ae61acb90c56daa', 
        View_Process_States: '6243496d8ae61acb90c56dac'
      },
      General: {
        Move_widgets: '62434a238ae61acb90c56dbd', 
        Profile_Settings: '62434a1a8ae61acb90c56dbc', 
        Receive_Notifications: '62434a118ae61acb90c56dbb'
      },
      Reporting: {
        Report_Generator: '62434a2d8ae61acb90c56dbe', 
        View_Reports: '62434a388ae61acb90c56dbf'
      }
    }
  }
}

const config = new CoreConfig()

export const CORE_CONFIG = config
