import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { CORE_CONFIG } from '../../../../core/core.config'
import { TeamStoreService } from '../../../../modules/team/services'
import { RolePermissionsService } from '../../../../shared/services/role-permissions.service'

// app

@Component({
  selector: 'ef-search-global-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class SearchGlobalResultsComponent implements OnInit {
  searchValue: string
  tabList = ['processes', 'actions', 'users']
  currTab = 'processes'
  public process: boolean
  public users: boolean

  constructor(
    public activeModal: NgbActiveModal,
    private _rolePermissionsService: RolePermissionsService,
    private _teamStoreService: TeamStoreService
  ) {
    this.hasPermissions()
  }

  ngOnInit() {}

  clear() {
    this.searchValue = undefined
  }

  setTab(tab) {
    this.currTab = tab
  }

  hasPermissions() {
    this._teamStoreService.getRolePermissionList().subscribe(profilePermissions => {
      if (profilePermissions.length > 0) {
        this.process = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.Processes.Search_Processes],
          profilePermissions
        )
        this.users = this._rolePermissionsService.hasAccessPermission(
          [CORE_CONFIG.MAP_PERMISSIONS.User_Management.Search_Users],
          profilePermissions
        )
      }
    })
  }
}
