import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'ef-profile-auth-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class ProfileAuthStatisticsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
