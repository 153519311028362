import { SelectControlItem } from '../../../shared/models/selectControl.model'

const getJsonFormat = () => {
  const dirtyList = [
    'fa-500px',
    'fa-address-book',
    'fa-address-book-o',
    'fa-address-card',
    'fa-address-card-o',
    'fa-adjust',
    'fa-adn',
    'fa-align-center',
    'fa-align-justify',
    'fa-align-left',
    'fa-align-right',
    'fa-amazon',
    'fa-ambulance',
    'fa-american-sign-language-interpreting',
    'fa-anchor',
    'fa-android',
    'fa-angellist',
    'fa-angle-double-down',
    'fa-angle-double-left',
    'fa-angle-double-right',
    'fa-angle-double-up',
    'fa-angle-down',
    'fa-angle-left',
    'fa-angle-right',
    'fa-angle-up',
    'fa-apple',
    'fa-archive',
    'fa-area-chart',
    'fa-arrow-circle-down',
    'fa-arrow-circle-left',
    'fa-arrow-circle-o-down',
    'fa-arrow-circle-o-left',
    'fa-arrow-circle-o-right',
    'fa-arrow-circle-o-up',
    'fa-arrow-circle-right',
    'fa-arrow-circle-up',
    'fa-arrow-down',
    'fa-arrow-left',
    'fa-arrow-right',
    'fa-arrow-up',
    'fa-arrows',
    'fa-arrows-alt',
    'fa-arrows-h',
    'fa-arrows-v',
    'fa-asl-interpreting',
    'fa-assistive-listening-systems',
    'fa-asterisk',
    'fa-at',
    'fa-audio-description',
    'fa-automobile',
    'fa-backward',
    'fa-balance-scale',
    'fa-ban',
    'fa-bandcamp',
    'fa-bank',
    'fa-bar-chart',
    'fa-bar-chart-o',
    'fa-barcode',
    'fa-bars',
    'fa-bath',
    'fa-bathtub',
    'fa-battery',
    'fa-battery-0',
    'fa-battery-1',
    'fa-battery-2',
    'fa-battery-3',
    'fa-battery-4',
    'fa-battery-empty',
    'fa-battery-full',
    'fa-battery-half',
    'fa-battery-quarter',
    'fa-battery-three-quarters',
    'fa-bed',
    'fa-beer',
    'fa-behance',
    'fa-behance-square',
    'fa-bell',
    'fa-bell-o',
    'fa-bell-slash',
    'fa-bell-slash-o',
    'fa-bicycle',
    'fa-binoculars',
    'fa-birthday-cake',
    'fa-bitbucket',
    'fa-bitbucket-square',
    'fa-bitcoin',
    'fa-black-tie',
    'fa-blind',
    'fa-bluetooth',
    'fa-bluetooth-b',
    'fa-bold',
    'fa-bolt',
    'fa-bomb',
    'fa-book',
    'fa-bookmark',
    'fa-bookmark-o',
    'fa-braille',
    'fa-briefcase',
    'fa-btc',
    'fa-bug',
    'fa-building',
    'fa-building-o',
    'fa-bullhorn',
    'fa-bullseye',
    'fa-bus',
    'fa-buysellads',
    'fa-cab',
    'fa-calculator',
    'fa-calendar',
    'fa-calendar-check-o',
    'fa-calendar-minus-o',
    'fa-calendar-o',
    'fa-calendar-plus-o',
    'fa-calendar-times-o',
    'fa-camera',
    'fa-camera-retro',
    'fa-car',
    'fa-caret-down',
    'fa-caret-left',
    'fa-caret-right',
    'fa-caret-square-o-down',
    'fa-caret-square-o-left',
    'fa-caret-square-o-right',
    'fa-caret-square-o-up',
    'fa-caret-up',
    'fa-cart-arrow-down',
    'fa-cart-plus',
    'fa-cc',
    'fa-cc-amex',
    'fa-cc-diners-club',
    'fa-cc-discover',
    'fa-cc-jcb',
    'fa-cc-mastercard',
    'fa-cc-paypal',
    'fa-cc-stripe',
    'fa-cc-visa',
    'fa-certificate',
    'fa-chain',
    'fa-chain-broken',
    'fa-check',
    'fa-check-circle',
    'fa-check-circle-o',
    'fa-check-square',
    'fa-check-square-o',
    'fa-chevron-circle-down',
    'fa-chevron-circle-left',
    'fa-chevron-circle-right',
    'fa-chevron-circle-up',
    'fa-chevron-down',
    'fa-chevron-left',
    'fa-chevron-right',
    'fa-chevron-up',
    'fa-child',
    'fa-chrome',
    'fa-circle',
    'fa-circle-o',
    'fa-circle-o-notch',
    'fa-circle-thin',
    'fa-clipboard',
    'fa-clock-o',
    'fa-clone',
    'fa-close',
    'fa-cloud',
    'fa-cloud-download',
    'fa-cloud-upload',
    'fa-cny',
    'fa-code',
    'fa-code-fork',
    'fa-codepen',
    'fa-codiepie',
    'fa-coffee',
    'fa-cog',
    'fa-cogs',
    'fa-columns',
    'fa-comment',
    'fa-comment-o',
    'fa-commenting',
    'fa-commenting-o',
    'fa-comments',
    'fa-comments-o',
    'fa-compass',
    'fa-compress',
    'fa-connectdevelop',
    'fa-contao',
    'fa-copy',
    'fa-copyright',
    'fa-creative-commons',
    'fa-credit-card',
    'fa-credit-card-alt',
    'fa-crop',
    'fa-crosshairs',
    'fa-css3',
    'fa-cube',
    'fa-cubes',
    'fa-cut',
    'fa-cutlery',
    'fa-dashboard',
    'fa-dashcube',
    'fa-database',
    'fa-deaf',
    'fa-deafness',
    'fa-dedent',
    'fa-delicious',
    'fa-desktop',
    'fa-deviantart',
    'fa-diamond',
    'fa-digg',
    'fa-dollar',
    'fa-dot-circle-o',
    'fa-download',
    'fa-dribbble',
    'fa-drivers-license',
    'fa-drivers-license-o',
    'fa-dropbox',
    'fa-drupal',
    'fa-edge',
    'fa-edit',
    'fa-eercast',
    'fa-eject',
    'fa-ellipsis-h',
    'fa-ellipsis-v',
    'fa-empire',
    'fa-envelope',
    'fa-envelope-o',
    'fa-envelope-open',
    'fa-envelope-open-o',
    'fa-envelope-square',
    'fa-envira',
    'fa-eraser',
    'fa-etsy',
    'fa-eur',
    'fa-euro',
    'fa-exchange',
    'fa-exclamation',
    'fa-exclamation-circle',
    'fa-exclamation-triangle',
    'fa-expand',
    'fa-expeditedssl',
    'fa-external-link',
    'fa-external-link-square',
    'fa-eye',
    'fa-eye-slash',
    'fa-eyedropper',
    'fa-fa',
    'fa-facebook',
    'fa-facebook-f',
    'fa-facebook-official',
    'fa-facebook-square',
    'fa-fast-backward',
    'fa-fast-forward',
    'fa-fax',
    'fa-feed',
    'fa-female',
    'fa-fighter-jet',
    'fa-file',
    'fa-file-archive-o',
    'fa-file-audio-o',
    'fa-file-code-o',
    'fa-file-excel-o',
    'fa-file-image-o',
    'fa-file-movie-o',
    'fa-file-o',
    'fa-file-pdf-o',
    'fa-file-photo-o',
    'fa-file-picture-o',
    'fa-file-powerpoint-o',
    'fa-file-sound-o',
    'fa-file-text',
    'fa-file-text-o',
    'fa-file-video-o',
    'fa-file-word-o',
    'fa-file-zip-o',
    'fa-files-o',
    'fa-film',
    'fa-filter',
    'fa-fire',
    'fa-fire-extinguisher',
    'fa-firefox',
    'fa-first-order',
    'fa-flag',
    'fa-flag-checkered',
    'fa-flag-o',
    'fa-flash',
    'fa-flask',
    'fa-flickr',
    'fa-floppy-o',
    'fa-folder',
    'fa-folder-o',
    'fa-folder-open',
    'fa-folder-open-o',
    'fa-font',
    'fa-font-awesome',
    'fa-fonticons',
    'fa-fort-awesome',
    'fa-forumbee',
    'fa-forward',
    'fa-foursquare',
    'fa-free-code-camp',
    'fa-frown-o',
    'fa-futbol-o',
    'fa-gamepad',
    'fa-gavel',
    'fa-gbp',
    'fa-ge',
    'fa-gear',
    'fa-gears',
    'fa-genderless',
    'fa-get-pocket',
    'fa-gg',
    'fa-gg-circle',
    'fa-gift',
    'fa-git',
    'fa-git-square',
    'fa-github',
    'fa-github-alt',
    'fa-github-square',
    'fa-gitlab',
    'fa-gittip',
    'fa-glass',
    'fa-glide',
    'fa-glide-g',
    'fa-globe',
    'fa-google',
    'fa-google-plus',
    'fa-google-plus-circle',
    'fa-google-plus-official',
    'fa-google-plus-square',
    'fa-google-wallet',
    'fa-graduation-cap',
    'fa-gratipay',
    'fa-grav',
    'fa-group',
    'fa-h-square',
    'fa-hacker-news',
    'fa-hand-grab-o',
    'fa-hand-lizard-o',
    'fa-hand-o-down',
    'fa-hand-o-left',
    'fa-hand-o-right',
    'fa-hand-o-up',
    'fa-hand-paper-o',
    'fa-hand-peace-o',
    'fa-hand-pointer-o',
    'fa-hand-rock-o',
    'fa-hand-scissors-o',
    'fa-hand-spock-o',
    'fa-hand-stop-o',
    'fa-handshake-o',
    'fa-hard-of-hearing',
    'fa-hashtag',
    'fa-hdd-o',
    'fa-header',
    'fa-headphones',
    'fa-heart',
    'fa-heart-o',
    'fa-heartbeat',
    'fa-history',
    'fa-home',
    'fa-hospital-o',
    'fa-hotel',
    'fa-hourglass',
    'fa-hourglass-1',
    'fa-hourglass-2',
    'fa-hourglass-3',
    'fa-hourglass-end',
    'fa-hourglass-half',
    'fa-hourglass-o',
    'fa-hourglass-start',
    'fa-houzz',
    'fa-html5',
    'fa-i-cursor',
    'fa-id-badge',
    'fa-id-card',
    'fa-id-card-o',
    'fa-ils',
    'fa-image',
    'fa-imdb',
    'fa-inbox',
    'fa-indent',
    'fa-industry',
    'fa-info',
    'fa-info-circle',
    'fa-inr',
    'fa-instagram',
    'fa-institution',
    'fa-internet-explorer',
    'fa-intersex',
    'fa-ioxhost',
    'fa-italic',
    'fa-joomla',
    'fa-jpy',
    'fa-jsfiddle',
    'fa-key',
    'fa-keyboard-o',
    'fa-krw',
    'fa-language',
    'fa-laptop',
    'fa-lastfm',
    'fa-lastfm-square',
    'fa-leaf',
    'fa-leanpub',
    'fa-legal',
    'fa-lemon-o',
    'fa-level-down',
    'fa-level-up',
    'fa-life-bouy',
    'fa-life-buoy',
    'fa-life-ring',
    'fa-life-saver',
    'fa-lightbulb-o',
    'fa-line-chart',
    'fa-link',
    'fa-linkedin',
    'fa-linkedin-square',
    'fa-linode',
    'fa-linux',
    'fa-list',
    'fa-list-alt',
    'fa-list-ol',
    'fa-list-ul',
    'fa-location-arrow',
    'fa-lock',
    'fa-long-arrow-down',
    'fa-long-arrow-left',
    'fa-long-arrow-right',
    'fa-long-arrow-up',
    'fa-low-vision',
    'fa-magic',
    'fa-magnet',
    'fa-mail-forward',
    'fa-mail-reply',
    'fa-mail-reply-all',
    'fa-male',
    'fa-map',
    'fa-map-marker',
    'fa-map-o',
    'fa-map-pin',
    'fa-map-signs',
    'fa-mars',
    'fa-mars-double',
    'fa-mars-stroke',
    'fa-mars-stroke-h',
    'fa-mars-stroke-v',
    'fa-maxcdn',
    'fa-meanpath',
    'fa-medium',
    'fa-medkit',
    'fa-meetup',
    'fa-meh-o',
    'fa-mercury',
    'fa-microchip',
    'fa-microphone',
    'fa-microphone-slash',
    'fa-minus',
    'fa-minus-circle',
    'fa-minus-square',
    'fa-minus-square-o',
    'fa-mixcloud',
    'fa-mobile',
    'fa-mobile-phone',
    'fa-modx',
    'fa-money',
    'fa-moon-o',
    'fa-mortar-board',
    'fa-motorcycle',
    'fa-mouse-pointer',
    'fa-music',
    'fa-navicon',
    'fa-neuter',
    'fa-newspaper-o',
    'fa-object-group',
    'fa-object-ungroup',
    'fa-odnoklassniki',
    'fa-odnoklassniki-square',
    'fa-opencart',
    'fa-openid',
    'fa-opera',
    'fa-optin-monster',
    'fa-outdent',
    'fa-pagelines',
    'fa-paint-brush',
    'fa-paper-plane',
    'fa-paper-plane-o',
    'fa-paperclip',
    'fa-paragraph',
    'fa-paste',
    'fa-pause',
    'fa-pause-circle',
    'fa-pause-circle-o',
    'fa-paw',
    'fa-paypal',
    'fa-pencil',
    'fa-pencil-square',
    'fa-pencil-square-o',
    'fa-percent',
    'fa-phone',
    'fa-phone-square',
    'fa-photo',
    'fa-picture-o',
    'fa-pie-chart',
    'fa-pied-piper',
    'fa-pied-piper-alt',
    'fa-pied-piper-pp',
    'fa-pinterest',
    'fa-pinterest-p',
    'fa-pinterest-square',
    'fa-plane',
    'fa-play',
    'fa-play-circle',
    'fa-play-circle-o',
    'fa-plug',
    'fa-plus',
    'fa-plus-circle',
    'fa-plus-square',
    'fa-plus-square-o',
    'fa-podcast',
    'fa-power-off',
    'fa-print',
    'fa-product-hunt',
    'fa-puzzle-piece',
    'fa-qq',
    'fa-qrcode',
    'fa-question',
    'fa-question-circle',
    'fa-question-circle-o',
    'fa-quora',
    'fa-quote-left',
    'fa-quote-right',
    'fa-ra',
    'fa-random',
    'fa-ravelry',
    'fa-rebel',
    'fa-recycle',
    'fa-reddit',
    'fa-reddit-alien',
    'fa-reddit-square',
    'fa-refresh',
    'fa-registered',
    'fa-remove',
    'fa-renren',
    'fa-reorder',
    'fa-repeat',
    'fa-reply',
    'fa-reply-all',
    'fa-resistance',
    'fa-retweet',
    'fa-rmb',
    'fa-road',
    'fa-rocket',
    'fa-rotate-left',
    'fa-rotate-right',
    'fa-rouble',
    'fa-rss',
    'fa-rss-square',
    'fa-rub',
    'fa-ruble',
    'fa-rupee',
    'fa-s15',
    'fa-safari',
    'fa-save',
    'fa-scissors',
    'fa-scribd',
    'fa-search',
    'fa-search-minus',
    'fa-search-plus',
    'fa-sellsy',
    'fa-send',
    'fa-send-o',
    'fa-server',
    'fa-share',
    'fa-share-alt',
    'fa-share-alt-square',
    'fa-share-square',
    'fa-share-square-o',
    'fa-shekel',
    'fa-sheqel',
    'fa-shield',
    'fa-ship',
    'fa-shirtsinbulk',
    'fa-shopping-bag',
    'fa-shopping-basket',
    'fa-shopping-cart',
    'fa-shower',
    'fa-sign-in',
    'fa-sign-language',
    'fa-sign-out',
    'fa-signal',
    'fa-signing',
    'fa-simplybuilt',
    'fa-sitemap',
    'fa-skyatlas',
    'fa-skype',
    'fa-slack',
    'fa-sliders',
    'fa-slideshare',
    'fa-smile-o',
    'fa-snapchat',
    'fa-snapchat-ghost',
    'fa-snapchat-square',
    'fa-snowflake-o',
    'fa-soccer-ball-o',
    'fa-sort',
    'fa-sort-alpha-asc',
    'fa-sort-alpha-desc',
    'fa-sort-amount-asc',
    'fa-sort-amount-desc',
    'fa-sort-asc',
    'fa-sort-desc',
    'fa-sort-down',
    'fa-sort-numeric-asc',
    'fa-sort-numeric-desc',
    'fa-sort-up',
    'fa-soundcloud',
    'fa-space-shuttle',
    'fa-spinner',
    'fa-spoon',
    'fa-spotify',
    'fa-square',
    'fa-square-o',
    'fa-stack-exchange',
    'fa-stack-overflow',
    'fa-star',
    'fa-star-half',
    'fa-star-half-empty',
    'fa-star-half-full',
    'fa-star-half-o',
    'fa-star-o',
    'fa-steam',
    'fa-steam-square',
    'fa-step-backward',
    'fa-step-forward',
    'fa-stethoscope',
    'fa-sticky-note',
    'fa-sticky-note-o',
    'fa-stop',
    'fa-stop-circle',
    'fa-stop-circle-o',
    'fa-street-view',
    'fa-strikethrough',
    'fa-stumbleupon',
    'fa-stumbleupon-circle',
    'fa-subscript',
    'fa-subway',
    'fa-suitcase',
    'fa-sun-o',
    'fa-superpowers',
    'fa-superscript',
    'fa-support',
    'fa-table',
    'fa-tablet',
    'fa-tachometer',
    'fa-tag',
    'fa-tags',
    'fa-tasks',
    'fa-taxi',
    'fa-telegram',
    'fa-television',
    'fa-tencent-weibo',
    'fa-terminal',
    'fa-text-height',
    'fa-text-width',
    'fa-th',
    'fa-th-large',
    'fa-th-list',
    'fa-themeisle',
    'fa-thermometer',
    'fa-thermometer-0',
    'fa-thermometer-1',
    'fa-thermometer-2',
    'fa-thermometer-3',
    'fa-thermometer-4',
    'fa-thermometer-empty',
    'fa-thermometer-full',
    'fa-thermometer-half',
    'fa-thermometer-quarter',
    'fa-thermometer-three-quarters',
    'fa-thumb-tack',
    'fa-thumbs-down',
    'fa-thumbs-o-down',
    'fa-thumbs-o-up',
    'fa-thumbs-up',
    'fa-ticket',
    'fa-times',
    'fa-times-circle',
    'fa-times-circle-o',
    'fa-times-rectangle',
    'fa-times-rectangle-o',
    'fa-tint',
    'fa-toggle-down',
    'fa-toggle-left',
    'fa-toggle-off',
    'fa-toggle-on',
    'fa-toggle-right',
    'fa-toggle-up',
    'fa-trademark',
    'fa-train',
    'fa-transgender',
    'fa-transgender-alt',
    'fa-trash',
    'fa-trash-o',
    'fa-tree',
    'fa-trello',
    'fa-tripadvisor',
    'fa-trophy',
    'fa-truck',
    'fa-try',
    'fa-tty',
    'fa-tumblr',
    'fa-tumblr-square',
    'fa-turkish-lira',
    'fa-tv',
    'fa-twitch',
    'fa-twitter',
    'fa-twitter-square',
    'fa-umbrella',
    'fa-underline',
    'fa-undo',
    'fa-universal-access',
    'fa-university',
    'fa-unlink',
    'fa-unlock',
    'fa-unlock-alt',
    'fa-unsorted',
    'fa-upload',
    'fa-usb',
    'fa-usd',
    'fa-user',
    'fa-user-circle',
    'fa-user-circle-o',
    'fa-user-md',
    'fa-user-o',
    'fa-user-plus',
    'fa-user-secret',
    'fa-user-times',
    'fa-users',
    'fa-vcard',
    'fa-vcard-o',
    'fa-venus',
    'fa-venus-double',
    'fa-venus-mars',
    'fa-viacoin',
    'fa-viadeo',
    'fa-viadeo-square',
    'fa-video-camera',
    'fa-vimeo',
    'fa-vimeo-square',
    'fa-vine',
    'fa-vk',
    'fa-volume-control-phone',
    'fa-volume-down',
    'fa-volume-off',
    'fa-volume-up',
    'fa-warning',
    'fa-wechat',
    'fa-weibo',
    'fa-weixin',
    'fa-whatsapp',
    'fa-wheelchair',
    'fa-wheelchair-alt',
    'fa-wifi',
    'fa-wikipedia-w',
    'fa-window-close',
    'fa-window-close-o',
    'fa-window-maximize',
    'fa-window-minimize',
    'fa-window-restore',
    'fa-windows',
    'fa-won',
    'fa-wordpress',
    'fa-wpbeginner',
    'fa-wpexplorer',
    'fa-wpforms',
    'fa-wrench',
    'fa-xing',
    'fa-xing-square',
    'fa-y-combinator',
    'fa-y-combinator-square',
    'fa-yahoo',
    'fa-yc',
    'fa-yc-square',
    'fa-yelp',
    'fa-yen',
    'fa-yoast',
    'fa-youtube',
    'fa-youtube-play',
    'fa-youtube-square',
  ] // [https://stackoverflow.com/questions/27992992/i-need-list-of-all-class-name-of-font-awesome]
  let result: SelectControlItem[] = []
  for (var i = 0; i < dirtyList.length; ++i) {
    let name = dirtyList[i]
    result.push({
      id: i,
      name: name,
      value: name.replace('fa-', '').replace(/-/gi, ' '),
    })
  }
  // JSON.stringify(result) //> result convert to json and show in console > copy from console> set to json parser
}

// getJsonFormat()
// todo - remove from production dirty list
export const AWESOME = [
  {
    id: 0,
    name: 'fa-500px',
    value: '500px',
  },
  {
    id: 1,
    name: 'fa-address-book',
    value: 'address book',
  },
  {
    id: 2,
    name: 'fa-address-book-o',
    value: 'address book o',
  },
  {
    id: 3,
    name: 'fa-address-card',
    value: 'address card',
  },
  {
    id: 4,
    name: 'fa-address-card-o',
    value: 'address card o',
  },
  {
    id: 5,
    name: 'fa-adjust',
    value: 'adjust',
  },
  {
    id: 6,
    name: 'fa-adn',
    value: 'adn',
  },
  {
    id: 7,
    name: 'fa-align-center',
    value: 'align center',
  },
  {
    id: 8,
    name: 'fa-align-justify',
    value: 'align justify',
  },
  {
    id: 9,
    name: 'fa-align-left',
    value: 'align left',
  },
  {
    id: 10,
    name: 'fa-align-right',
    value: 'align right',
  },
  {
    id: 11,
    name: 'fa-amazon',
    value: 'amazon',
  },
  {
    id: 12,
    name: 'fa-ambulance',
    value: 'ambulance',
  },
  {
    id: 13,
    name: 'fa-american-sign-language-interpreting',
    value: 'american sign language interpreting',
  },
  {
    id: 14,
    name: 'fa-anchor',
    value: 'anchor',
  },
  {
    id: 15,
    name: 'fa-android',
    value: 'android',
  },
  {
    id: 16,
    name: 'fa-angellist',
    value: 'angellist',
  },
  {
    id: 17,
    name: 'fa-angle-double-down',
    value: 'angle double down',
  },
  {
    id: 18,
    name: 'fa-angle-double-left',
    value: 'angle double left',
  },
  {
    id: 19,
    name: 'fa-angle-double-right',
    value: 'angle double right',
  },
  {
    id: 20,
    name: 'fa-angle-double-up',
    value: 'angle double up',
  },
  {
    id: 21,
    name: 'fa-angle-down',
    value: 'angle down',
  },
  {
    id: 22,
    name: 'fa-angle-left',
    value: 'angle left',
  },
  {
    id: 23,
    name: 'fa-angle-right',
    value: 'angle right',
  },
  {
    id: 24,
    name: 'fa-angle-up',
    value: 'angle up',
  },
  {
    id: 25,
    name: 'fa-apple',
    value: 'apple',
  },
  {
    id: 26,
    name: 'fa-archive',
    value: 'archive',
  },
  {
    id: 27,
    name: 'fa-area-chart',
    value: 'area chart',
  },
  {
    id: 28,
    name: 'fa-arrow-circle-down',
    value: 'arrow circle down',
  },
  {
    id: 29,
    name: 'fa-arrow-circle-left',
    value: 'arrow circle left',
  },
  {
    id: 30,
    name: 'fa-arrow-circle-o-down',
    value: 'arrow circle o down',
  },
  {
    id: 31,
    name: 'fa-arrow-circle-o-left',
    value: 'arrow circle o left',
  },
  {
    id: 32,
    name: 'fa-arrow-circle-o-right',
    value: 'arrow circle o right',
  },
  {
    id: 33,
    name: 'fa-arrow-circle-o-up',
    value: 'arrow circle o up',
  },
  {
    id: 34,
    name: 'fa-arrow-circle-right',
    value: 'arrow circle right',
  },
  {
    id: 35,
    name: 'fa-arrow-circle-up',
    value: 'arrow circle up',
  },
  {
    id: 36,
    name: 'fa-arrow-down',
    value: 'arrow down',
  },
  {
    id: 37,
    name: 'fa-arrow-left',
    value: 'arrow left',
  },
  {
    id: 38,
    name: 'fa-arrow-right',
    value: 'arrow right',
  },
  {
    id: 39,
    name: 'fa-arrow-up',
    value: 'arrow up',
  },
  {
    id: 40,
    name: 'fa-arrows',
    value: 'arrows',
  },
  {
    id: 41,
    name: 'fa-arrows-alt',
    value: 'arrows alt',
  },
  {
    id: 42,
    name: 'fa-arrows-h',
    value: 'arrows h',
  },
  {
    id: 43,
    name: 'fa-arrows-v',
    value: 'arrows v',
  },
  {
    id: 44,
    name: 'fa-asl-interpreting',
    value: 'asl interpreting',
  },
  {
    id: 45,
    name: 'fa-assistive-listening-systems',
    value: 'assistive listening systems',
  },
  {
    id: 46,
    name: 'fa-asterisk',
    value: 'asterisk',
  },
  {
    id: 47,
    name: 'fa-at',
    value: 'at',
  },
  {
    id: 48,
    name: 'fa-audio-description',
    value: 'audio description',
  },
  {
    id: 49,
    name: 'fa-automobile',
    value: 'automobile',
  },
  {
    id: 50,
    name: 'fa-backward',
    value: 'backward',
  },
  {
    id: 51,
    name: 'fa-balance-scale',
    value: 'balance scale',
  },
  {
    id: 52,
    name: 'fa-ban',
    value: 'ban',
  },
  {
    id: 53,
    name: 'fa-bandcamp',
    value: 'bandcamp',
  },
  {
    id: 54,
    name: 'fa-bank',
    value: 'bank',
  },
  {
    id: 55,
    name: 'fa-bar-chart',
    value: 'bar chart',
  },
  {
    id: 56,
    name: 'fa-bar-chart-o',
    value: 'bar chart o',
  },
  {
    id: 57,
    name: 'fa-barcode',
    value: 'barcode',
  },
  {
    id: 58,
    name: 'fa-bars',
    value: 'bars',
  },
  {
    id: 59,
    name: 'fa-bath',
    value: 'bath',
  },
  {
    id: 60,
    name: 'fa-bathtub',
    value: 'bathtub',
  },
  {
    id: 61,
    name: 'fa-battery',
    value: 'battery',
  },
  {
    id: 62,
    name: 'fa-battery-0',
    value: 'battery 0',
  },
  {
    id: 63,
    name: 'fa-battery-1',
    value: 'battery 1',
  },
  {
    id: 64,
    name: 'fa-battery-2',
    value: 'battery 2',
  },
  {
    id: 65,
    name: 'fa-battery-3',
    value: 'battery 3',
  },
  {
    id: 66,
    name: 'fa-battery-4',
    value: 'battery 4',
  },
  {
    id: 67,
    name: 'fa-battery-empty',
    value: 'battery empty',
  },
  {
    id: 68,
    name: 'fa-battery-full',
    value: 'battery full',
  },
  {
    id: 69,
    name: 'fa-battery-half',
    value: 'battery half',
  },
  {
    id: 70,
    name: 'fa-battery-quarter',
    value: 'battery quarter',
  },
  {
    id: 71,
    name: 'fa-battery-three-quarters',
    value: 'battery three quarters',
  },
  {
    id: 72,
    name: 'fa-bed',
    value: 'bed',
  },
  {
    id: 73,
    name: 'fa-beer',
    value: 'beer',
  },
  {
    id: 74,
    name: 'fa-behance',
    value: 'behance',
  },
  {
    id: 75,
    name: 'fa-behance-square',
    value: 'behance square',
  },
  {
    id: 76,
    name: 'fa-bell',
    value: 'bell',
  },
  {
    id: 77,
    name: 'fa-bell-o',
    value: 'bell o',
  },
  {
    id: 78,
    name: 'fa-bell-slash',
    value: 'bell slash',
  },
  {
    id: 79,
    name: 'fa-bell-slash-o',
    value: 'bell slash o',
  },
  {
    id: 80,
    name: 'fa-bicycle',
    value: 'bicycle',
  },
  {
    id: 81,
    name: 'fa-binoculars',
    value: 'binoculars',
  },
  {
    id: 82,
    name: 'fa-birthday-cake',
    value: 'birthday cake',
  },
  {
    id: 83,
    name: 'fa-bitbucket',
    value: 'bitbucket',
  },
  {
    id: 84,
    name: 'fa-bitbucket-square',
    value: 'bitbucket square',
  },
  {
    id: 85,
    name: 'fa-bitcoin',
    value: 'bitcoin',
  },
  {
    id: 86,
    name: 'fa-black-tie',
    value: 'black tie',
  },
  {
    id: 87,
    name: 'fa-blind',
    value: 'blind',
  },
  {
    id: 88,
    name: 'fa-bluetooth',
    value: 'bluetooth',
  },
  {
    id: 89,
    name: 'fa-bluetooth-b',
    value: 'bluetooth b',
  },
  {
    id: 90,
    name: 'fa-bold',
    value: 'bold',
  },
  {
    id: 91,
    name: 'fa-bolt',
    value: 'bolt',
  },
  {
    id: 92,
    name: 'fa-bomb',
    value: 'bomb',
  },
  {
    id: 93,
    name: 'fa-book',
    value: 'book',
  },
  {
    id: 94,
    name: 'fa-bookmark',
    value: 'bookmark',
  },
  {
    id: 95,
    name: 'fa-bookmark-o',
    value: 'bookmark o',
  },
  {
    id: 96,
    name: 'fa-braille',
    value: 'braille',
  },
  {
    id: 97,
    name: 'fa-briefcase',
    value: 'briefcase',
  },
  {
    id: 98,
    name: 'fa-btc',
    value: 'btc',
  },
  {
    id: 99,
    name: 'fa-bug',
    value: 'bug',
  },
  {
    id: 100,
    name: 'fa-building',
    value: 'building',
  },
  {
    id: 101,
    name: 'fa-building-o',
    value: 'building o',
  },
  {
    id: 102,
    name: 'fa-bullhorn',
    value: 'bullhorn',
  },
  {
    id: 103,
    name: 'fa-bullseye',
    value: 'bullseye',
  },
  {
    id: 104,
    name: 'fa-bus',
    value: 'bus',
  },
  {
    id: 105,
    name: 'fa-buysellads',
    value: 'buysellads',
  },
  {
    id: 106,
    name: 'fa-cab',
    value: 'cab',
  },
  {
    id: 107,
    name: 'fa-calculator',
    value: 'calculator',
  },
  {
    id: 108,
    name: 'fa-calendar',
    value: 'calendar',
  },
  {
    id: 109,
    name: 'fa-calendar-check-o',
    value: 'calendar check o',
  },
  {
    id: 110,
    name: 'fa-calendar-minus-o',
    value: 'calendar minus o',
  },
  {
    id: 111,
    name: 'fa-calendar-o',
    value: 'calendar o',
  },
  {
    id: 112,
    name: 'fa-calendar-plus-o',
    value: 'calendar plus o',
  },
  {
    id: 113,
    name: 'fa-calendar-times-o',
    value: 'calendar times o',
  },
  {
    id: 114,
    name: 'fa-camera',
    value: 'camera',
  },
  {
    id: 115,
    name: 'fa-camera-retro',
    value: 'camera retro',
  },
  {
    id: 116,
    name: 'fa-car',
    value: 'car',
  },
  {
    id: 117,
    name: 'fa-caret-down',
    value: 'caret down',
  },
  {
    id: 118,
    name: 'fa-caret-left',
    value: 'caret left',
  },
  {
    id: 119,
    name: 'fa-caret-right',
    value: 'caret right',
  },
  {
    id: 120,
    name: 'fa-caret-square-o-down',
    value: 'caret square o down',
  },
  {
    id: 121,
    name: 'fa-caret-square-o-left',
    value: 'caret square o left',
  },
  {
    id: 122,
    name: 'fa-caret-square-o-right',
    value: 'caret square o right',
  },
  {
    id: 123,
    name: 'fa-caret-square-o-up',
    value: 'caret square o up',
  },
  {
    id: 124,
    name: 'fa-caret-up',
    value: 'caret up',
  },
  {
    id: 125,
    name: 'fa-cart-arrow-down',
    value: 'cart arrow down',
  },
  {
    id: 126,
    name: 'fa-cart-plus',
    value: 'cart plus',
  },
  {
    id: 127,
    name: 'fa-cc',
    value: 'cc',
  },
  {
    id: 128,
    name: 'fa-cc-amex',
    value: 'cc amex',
  },
  {
    id: 129,
    name: 'fa-cc-diners-club',
    value: 'cc diners club',
  },
  {
    id: 130,
    name: 'fa-cc-discover',
    value: 'cc discover',
  },
  {
    id: 131,
    name: 'fa-cc-jcb',
    value: 'cc jcb',
  },
  {
    id: 132,
    name: 'fa-cc-mastercard',
    value: 'cc mastercard',
  },
  {
    id: 133,
    name: 'fa-cc-paypal',
    value: 'cc paypal',
  },
  {
    id: 134,
    name: 'fa-cc-stripe',
    value: 'cc stripe',
  },
  {
    id: 135,
    name: 'fa-cc-visa',
    value: 'cc visa',
  },
  {
    id: 136,
    name: 'fa-certificate',
    value: 'certificate',
  },
  {
    id: 137,
    name: 'fa-chain',
    value: 'chain',
  },
  {
    id: 138,
    name: 'fa-chain-broken',
    value: 'chain broken',
  },
  {
    id: 139,
    name: 'fa-check',
    value: 'check',
  },
  {
    id: 140,
    name: 'fa-check-circle',
    value: 'check circle',
  },
  {
    id: 141,
    name: 'fa-check-circle-o',
    value: 'check circle o',
  },
  {
    id: 142,
    name: 'fa-check-square',
    value: 'check square',
  },
  {
    id: 143,
    name: 'fa-check-square-o',
    value: 'check square o',
  },
  {
    id: 144,
    name: 'fa-chevron-circle-down',
    value: 'chevron circle down',
  },
  {
    id: 145,
    name: 'fa-chevron-circle-left',
    value: 'chevron circle left',
  },
  {
    id: 146,
    name: 'fa-chevron-circle-right',
    value: 'chevron circle right',
  },
  {
    id: 147,
    name: 'fa-chevron-circle-up',
    value: 'chevron circle up',
  },
  {
    id: 148,
    name: 'fa-chevron-down',
    value: 'chevron down',
  },
  {
    id: 149,
    name: 'fa-chevron-left',
    value: 'chevron left',
  },
  {
    id: 150,
    name: 'fa-chevron-right',
    value: 'chevron right',
  },
  {
    id: 151,
    name: 'fa-chevron-up',
    value: 'chevron up',
  },
  {
    id: 152,
    name: 'fa-child',
    value: 'child',
  },
  {
    id: 153,
    name: 'fa-chrome',
    value: 'chrome',
  },
  {
    id: 154,
    name: 'fa-circle',
    value: 'circle',
  },
  {
    id: 155,
    name: 'fa-circle-o',
    value: 'circle o',
  },
  {
    id: 156,
    name: 'fa-circle-o-notch',
    value: 'circle o notch',
  },
  {
    id: 157,
    name: 'fa-circle-thin',
    value: 'circle thin',
  },
  {
    id: 158,
    name: 'fa-clipboard',
    value: 'clipboard',
  },
  {
    id: 159,
    name: 'fa-clock-o',
    value: 'clock o',
  },
  {
    id: 160,
    name: 'fa-clone',
    value: 'clone',
  },
  {
    id: 161,
    name: 'fa-close',
    value: 'close',
  },
  {
    id: 162,
    name: 'fa-cloud',
    value: 'cloud',
  },
  {
    id: 163,
    name: 'fa-cloud-download',
    value: 'cloud download',
  },
  {
    id: 164,
    name: 'fa-cloud-upload',
    value: 'cloud upload',
  },
  {
    id: 165,
    name: 'fa-cny',
    value: 'cny',
  },
  {
    id: 166,
    name: 'fa-code',
    value: 'code',
  },
  {
    id: 167,
    name: 'fa-code-fork',
    value: 'code fork',
  },
  {
    id: 168,
    name: 'fa-codepen',
    value: 'codepen',
  },
  {
    id: 169,
    name: 'fa-codiepie',
    value: 'codiepie',
  },
  {
    id: 170,
    name: 'fa-coffee',
    value: 'coffee',
  },
  {
    id: 171,
    name: 'fa-cog',
    value: 'cog',
  },
  {
    id: 172,
    name: 'fa-cogs',
    value: 'cogs',
  },
  {
    id: 173,
    name: 'fa-columns',
    value: 'columns',
  },
  {
    id: 174,
    name: 'fa-comment',
    value: 'comment',
  },
  {
    id: 175,
    name: 'fa-comment-o',
    value: 'comment o',
  },
  {
    id: 176,
    name: 'fa-commenting',
    value: 'commenting',
  },
  {
    id: 177,
    name: 'fa-commenting-o',
    value: 'commenting o',
  },
  {
    id: 178,
    name: 'fa-comments',
    value: 'comments',
  },
  {
    id: 179,
    name: 'fa-comments-o',
    value: 'comments o',
  },
  {
    id: 180,
    name: 'fa-compass',
    value: 'compass',
  },
  {
    id: 181,
    name: 'fa-compress',
    value: 'compress',
  },
  {
    id: 182,
    name: 'fa-connectdevelop',
    value: 'connectdevelop',
  },
  {
    id: 183,
    name: 'fa-contao',
    value: 'contao',
  },
  {
    id: 184,
    name: 'fa-copy',
    value: 'copy',
  },
  {
    id: 185,
    name: 'fa-copyright',
    value: 'copyright',
  },
  {
    id: 186,
    name: 'fa-creative-commons',
    value: 'creative commons',
  },
  {
    id: 187,
    name: 'fa-credit-card',
    value: 'credit card',
  },
  {
    id: 188,
    name: 'fa-credit-card-alt',
    value: 'credit card alt',
  },
  {
    id: 189,
    name: 'fa-crop',
    value: 'crop',
  },
  {
    id: 190,
    name: 'fa-crosshairs',
    value: 'crosshairs',
  },
  {
    id: 191,
    name: 'fa-css3',
    value: 'css3',
  },
  {
    id: 192,
    name: 'fa-cube',
    value: 'cube',
  },
  {
    id: 193,
    name: 'fa-cubes',
    value: 'cubes',
  },
  {
    id: 194,
    name: 'fa-cut',
    value: 'cut',
  },
  {
    id: 195,
    name: 'fa-cutlery',
    value: 'cutlery',
  },
  {
    id: 196,
    name: 'fa-dashboard',
    value: 'dashboard',
  },
  {
    id: 197,
    name: 'fa-dashcube',
    value: 'dashcube',
  },
  {
    id: 198,
    name: 'fa-database',
    value: 'database',
  },
  {
    id: 199,
    name: 'fa-deaf',
    value: 'deaf',
  },
  {
    id: 200,
    name: 'fa-deafness',
    value: 'deafness',
  },
  {
    id: 201,
    name: 'fa-dedent',
    value: 'dedent',
  },
  {
    id: 202,
    name: 'fa-delicious',
    value: 'delicious',
  },
  {
    id: 203,
    name: 'fa-desktop',
    value: 'desktop',
  },
  {
    id: 204,
    name: 'fa-deviantart',
    value: 'deviantart',
  },
  {
    id: 205,
    name: 'fa-diamond',
    value: 'diamond',
  },
  {
    id: 206,
    name: 'fa-digg',
    value: 'digg',
  },
  {
    id: 207,
    name: 'fa-dollar',
    value: 'dollar',
  },
  {
    id: 208,
    name: 'fa-dot-circle-o',
    value: 'dot circle o',
  },
  {
    id: 209,
    name: 'fa-download',
    value: 'download',
  },
  {
    id: 210,
    name: 'fa-dribbble',
    value: 'dribbble',
  },
  {
    id: 211,
    name: 'fa-drivers-license',
    value: 'drivers license',
  },
  {
    id: 212,
    name: 'fa-drivers-license-o',
    value: 'drivers license o',
  },
  {
    id: 213,
    name: 'fa-dropbox',
    value: 'dropbox',
  },
  {
    id: 214,
    name: 'fa-drupal',
    value: 'drupal',
  },
  {
    id: 215,
    name: 'fa-edge',
    value: 'edge',
  },
  {
    id: 216,
    name: 'fa-edit',
    value: 'edit',
  },
  {
    id: 217,
    name: 'fa-eercast',
    value: 'eercast',
  },
  {
    id: 218,
    name: 'fa-eject',
    value: 'eject',
  },
  {
    id: 219,
    name: 'fa-ellipsis-h',
    value: 'ellipsis h',
  },
  {
    id: 220,
    name: 'fa-ellipsis-v',
    value: 'ellipsis v',
  },
  {
    id: 221,
    name: 'fa-empire',
    value: 'empire',
  },
  {
    id: 222,
    name: 'fa-envelope',
    value: 'envelope',
  },
  {
    id: 223,
    name: 'fa-envelope-o',
    value: 'envelope o',
  },
  {
    id: 224,
    name: 'fa-envelope-open',
    value: 'envelope open',
  },
  {
    id: 225,
    name: 'fa-envelope-open-o',
    value: 'envelope open o',
  },
  {
    id: 226,
    name: 'fa-envelope-square',
    value: 'envelope square',
  },
  {
    id: 227,
    name: 'fa-envira',
    value: 'envira',
  },
  {
    id: 228,
    name: 'fa-eraser',
    value: 'eraser',
  },
  {
    id: 229,
    name: 'fa-etsy',
    value: 'etsy',
  },
  {
    id: 230,
    name: 'fa-eur',
    value: 'eur',
  },
  {
    id: 231,
    name: 'fa-euro',
    value: 'euro',
  },
  {
    id: 232,
    name: 'fa-exchange',
    value: 'exchange',
  },
  {
    id: 233,
    name: 'fa-exclamation',
    value: 'exclamation',
  },
  {
    id: 234,
    name: 'fa-exclamation-circle',
    value: 'exclamation circle',
  },
  {
    id: 235,
    name: 'fa-exclamation-triangle',
    value: 'exclamation triangle',
  },
  {
    id: 236,
    name: 'fa-expand',
    value: 'expand',
  },
  {
    id: 237,
    name: 'fa-expeditedssl',
    value: 'expeditedssl',
  },
  {
    id: 238,
    name: 'fa-external-link',
    value: 'external link',
  },
  {
    id: 239,
    name: 'fa-external-link-square',
    value: 'external link square',
  },
  {
    id: 240,
    name: 'fa-eye',
    value: 'eye',
  },
  {
    id: 241,
    name: 'fa-eye-slash',
    value: 'eye slash',
  },
  {
    id: 242,
    name: 'fa-eyedropper',
    value: 'eyedropper',
  },
  {
    id: 243,
    name: 'fa-fa',
    value: 'fa',
  },
  {
    id: 244,
    name: 'fa-facebook',
    value: 'facebook',
  },
  {
    id: 245,
    name: 'fa-facebook-f',
    value: 'facebook f',
  },
  {
    id: 246,
    name: 'fa-facebook-official',
    value: 'facebook official',
  },
  {
    id: 247,
    name: 'fa-facebook-square',
    value: 'facebook square',
  },
  {
    id: 248,
    name: 'fa-fast-backward',
    value: 'fast backward',
  },
  {
    id: 249,
    name: 'fa-fast-forward',
    value: 'fast forward',
  },
  {
    id: 250,
    name: 'fa-fax',
    value: 'fax',
  },
  {
    id: 251,
    name: 'fa-feed',
    value: 'feed',
  },
  {
    id: 252,
    name: 'fa-female',
    value: 'female',
  },
  {
    id: 253,
    name: 'fa-fighter-jet',
    value: 'fighter jet',
  },
  {
    id: 254,
    name: 'fa-file',
    value: 'file',
  },
  {
    id: 255,
    name: 'fa-file-archive-o',
    value: 'file archive o',
  },
  {
    id: 256,
    name: 'fa-file-audio-o',
    value: 'file audio o',
  },
  {
    id: 257,
    name: 'fa-file-code-o',
    value: 'file code o',
  },
  {
    id: 258,
    name: 'fa-file-excel-o',
    value: 'file excel o',
  },
  {
    id: 259,
    name: 'fa-file-image-o',
    value: 'file image o',
  },
  {
    id: 260,
    name: 'fa-file-movie-o',
    value: 'file movie o',
  },
  {
    id: 261,
    name: 'fa-file-o',
    value: 'file o',
  },
  {
    id: 262,
    name: 'fa-file-pdf-o',
    value: 'file pdf o',
  },
  {
    id: 263,
    name: 'fa-file-photo-o',
    value: 'file photo o',
  },
  {
    id: 264,
    name: 'fa-file-picture-o',
    value: 'file picture o',
  },
  {
    id: 265,
    name: 'fa-file-powerpoint-o',
    value: 'file powerpoint o',
  },
  {
    id: 266,
    name: 'fa-file-sound-o',
    value: 'file sound o',
  },
  {
    id: 267,
    name: 'fa-file-text',
    value: 'file text',
  },
  {
    id: 268,
    name: 'fa-file-text-o',
    value: 'file text o',
  },
  {
    id: 269,
    name: 'fa-file-video-o',
    value: 'file video o',
  },
  {
    id: 270,
    name: 'fa-file-word-o',
    value: 'file word o',
  },
  {
    id: 271,
    name: 'fa-file-zip-o',
    value: 'file zip o',
  },
  {
    id: 272,
    name: 'fa-files-o',
    value: 'files o',
  },
  {
    id: 273,
    name: 'fa-film',
    value: 'film',
  },
  {
    id: 274,
    name: 'fa-filter',
    value: 'filter',
  },
  {
    id: 275,
    name: 'fa-fire',
    value: 'fire',
  },
  {
    id: 276,
    name: 'fa-fire-extinguisher',
    value: 'fire extinguisher',
  },
  {
    id: 277,
    name: 'fa-firefox',
    value: 'firefox',
  },
  {
    id: 278,
    name: 'fa-first-order',
    value: 'first order',
  },
  {
    id: 279,
    name: 'fa-flag',
    value: 'flag',
  },
  {
    id: 280,
    name: 'fa-flag-checkered',
    value: 'flag checkered',
  },
  {
    id: 281,
    name: 'fa-flag-o',
    value: 'flag o',
  },
  {
    id: 282,
    name: 'fa-flash',
    value: 'flash',
  },
  {
    id: 283,
    name: 'fa-flask',
    value: 'flask',
  },
  {
    id: 284,
    name: 'fa-flickr',
    value: 'flickr',
  },
  {
    id: 285,
    name: 'fa-floppy-o',
    value: 'floppy o',
  },
  {
    id: 286,
    name: 'fa-folder',
    value: 'folder',
  },
  {
    id: 287,
    name: 'fa-folder-o',
    value: 'folder o',
  },
  {
    id: 288,
    name: 'fa-folder-open',
    value: 'folder open',
  },
  {
    id: 289,
    name: 'fa-folder-open-o',
    value: 'folder open o',
  },
  {
    id: 290,
    name: 'fa-font',
    value: 'font',
  },
  {
    id: 291,
    name: 'fa-font-awesome',
    value: 'font awesome',
  },
  {
    id: 292,
    name: 'fa-fonticons',
    value: 'fonticons',
  },
  {
    id: 293,
    name: 'fa-fort-awesome',
    value: 'fort awesome',
  },
  {
    id: 294,
    name: 'fa-forumbee',
    value: 'forumbee',
  },
  {
    id: 295,
    name: 'fa-forward',
    value: 'forward',
  },
  {
    id: 296,
    name: 'fa-foursquare',
    value: 'foursquare',
  },
  {
    id: 297,
    name: 'fa-free-code-camp',
    value: 'free code camp',
  },
  {
    id: 298,
    name: 'fa-frown-o',
    value: 'frown o',
  },
  {
    id: 299,
    name: 'fa-futbol-o',
    value: 'futbol o',
  },
  {
    id: 300,
    name: 'fa-gamepad',
    value: 'gamepad',
  },
  {
    id: 301,
    name: 'fa-gavel',
    value: 'gavel',
  },
  {
    id: 302,
    name: 'fa-gbp',
    value: 'gbp',
  },
  {
    id: 303,
    name: 'fa-ge',
    value: 'ge',
  },
  {
    id: 304,
    name: 'fa-gear',
    value: 'gear',
  },
  {
    id: 305,
    name: 'fa-gears',
    value: 'gears',
  },
  {
    id: 306,
    name: 'fa-genderless',
    value: 'genderless',
  },
  {
    id: 307,
    name: 'fa-get-pocket',
    value: 'get pocket',
  },
  {
    id: 308,
    name: 'fa-gg',
    value: 'gg',
  },
  {
    id: 309,
    name: 'fa-gg-circle',
    value: 'gg circle',
  },
  {
    id: 310,
    name: 'fa-gift',
    value: 'gift',
  },
  {
    id: 311,
    name: 'fa-git',
    value: 'git',
  },
  {
    id: 312,
    name: 'fa-git-square',
    value: 'git square',
  },
  {
    id: 313,
    name: 'fa-github',
    value: 'github',
  },
  {
    id: 314,
    name: 'fa-github-alt',
    value: 'github alt',
  },
  {
    id: 315,
    name: 'fa-github-square',
    value: 'github square',
  },
  {
    id: 316,
    name: 'fa-gitlab',
    value: 'gitlab',
  },
  {
    id: 317,
    name: 'fa-gittip',
    value: 'gittip',
  },
  {
    id: 318,
    name: 'fa-glass',
    value: 'glass',
  },
  {
    id: 319,
    name: 'fa-glide',
    value: 'glide',
  },
  {
    id: 320,
    name: 'fa-glide-g',
    value: 'glide g',
  },
  {
    id: 321,
    name: 'fa-globe',
    value: 'globe',
  },
  {
    id: 322,
    name: 'fa-google',
    value: 'google',
  },
  {
    id: 323,
    name: 'fa-google-plus',
    value: 'google plus',
  },
  {
    id: 324,
    name: 'fa-google-plus-circle',
    value: 'google plus circle',
  },
  {
    id: 325,
    name: 'fa-google-plus-official',
    value: 'google plus official',
  },
  {
    id: 326,
    name: 'fa-google-plus-square',
    value: 'google plus square',
  },
  {
    id: 327,
    name: 'fa-google-wallet',
    value: 'google wallet',
  },
  {
    id: 328,
    name: 'fa-graduation-cap',
    value: 'graduation cap',
  },
  {
    id: 329,
    name: 'fa-gratipay',
    value: 'gratipay',
  },
  {
    id: 330,
    name: 'fa-grav',
    value: 'grav',
  },
  {
    id: 331,
    name: 'fa-group',
    value: 'group',
  },
  {
    id: 332,
    name: 'fa-h-square',
    value: 'h square',
  },
  {
    id: 333,
    name: 'fa-hacker-news',
    value: 'hacker news',
  },
  {
    id: 334,
    name: 'fa-hand-grab-o',
    value: 'hand grab o',
  },
  {
    id: 335,
    name: 'fa-hand-lizard-o',
    value: 'hand lizard o',
  },
  {
    id: 336,
    name: 'fa-hand-o-down',
    value: 'hand o down',
  },
  {
    id: 337,
    name: 'fa-hand-o-left',
    value: 'hand o left',
  },
  {
    id: 338,
    name: 'fa-hand-o-right',
    value: 'hand o right',
  },
  {
    id: 339,
    name: 'fa-hand-o-up',
    value: 'hand o up',
  },
  {
    id: 340,
    name: 'fa-hand-paper-o',
    value: 'hand paper o',
  },
  {
    id: 341,
    name: 'fa-hand-peace-o',
    value: 'hand peace o',
  },
  {
    id: 342,
    name: 'fa-hand-pointer-o',
    value: 'hand pointer o',
  },
  {
    id: 343,
    name: 'fa-hand-rock-o',
    value: 'hand rock o',
  },
  {
    id: 344,
    name: 'fa-hand-scissors-o',
    value: 'hand scissors o',
  },
  {
    id: 345,
    name: 'fa-hand-spock-o',
    value: 'hand spock o',
  },
  {
    id: 346,
    name: 'fa-hand-stop-o',
    value: 'hand stop o',
  },
  {
    id: 347,
    name: 'fa-handshake-o',
    value: 'handshake o',
  },
  {
    id: 348,
    name: 'fa-hard-of-hearing',
    value: 'hard of hearing',
  },
  {
    id: 349,
    name: 'fa-hashtag',
    value: 'hashtag',
  },
  {
    id: 350,
    name: 'fa-hdd-o',
    value: 'hdd o',
  },
  {
    id: 351,
    name: 'fa-header',
    value: 'header',
  },
  {
    id: 352,
    name: 'fa-headphones',
    value: 'headphones',
  },
  {
    id: 353,
    name: 'fa-heart',
    value: 'heart',
  },
  {
    id: 354,
    name: 'fa-heart-o',
    value: 'heart o',
  },
  {
    id: 355,
    name: 'fa-heartbeat',
    value: 'heartbeat',
  },
  {
    id: 356,
    name: 'fa-history',
    value: 'history',
  },
  {
    id: 357,
    name: 'fa-home',
    value: 'home',
  },
  {
    id: 358,
    name: 'fa-hospital-o',
    value: 'hospital o',
  },
  {
    id: 359,
    name: 'fa-hotel',
    value: 'hotel',
  },
  {
    id: 360,
    name: 'fa-hourglass',
    value: 'hourglass',
  },
  {
    id: 361,
    name: 'fa-hourglass-1',
    value: 'hourglass 1',
  },
  {
    id: 362,
    name: 'fa-hourglass-2',
    value: 'hourglass 2',
  },
  {
    id: 363,
    name: 'fa-hourglass-3',
    value: 'hourglass 3',
  },
  {
    id: 364,
    name: 'fa-hourglass-end',
    value: 'hourglass end',
  },
  {
    id: 365,
    name: 'fa-hourglass-half',
    value: 'hourglass half',
  },
  {
    id: 366,
    name: 'fa-hourglass-o',
    value: 'hourglass o',
  },
  {
    id: 367,
    name: 'fa-hourglass-start',
    value: 'hourglass start',
  },
  {
    id: 368,
    name: 'fa-houzz',
    value: 'houzz',
  },
  {
    id: 369,
    name: 'fa-html5',
    value: 'html5',
  },
  {
    id: 370,
    name: 'fa-i-cursor',
    value: 'i cursor',
  },
  {
    id: 371,
    name: 'fa-id-badge',
    value: 'id badge',
  },
  {
    id: 372,
    name: 'fa-id-card',
    value: 'id card',
  },
  {
    id: 373,
    name: 'fa-id-card-o',
    value: 'id card o',
  },
  {
    id: 374,
    name: 'fa-ils',
    value: 'ils',
  },
  {
    id: 375,
    name: 'fa-image',
    value: 'image',
  },
  {
    id: 376,
    name: 'fa-imdb',
    value: 'imdb',
  },
  {
    id: 377,
    name: 'fa-inbox',
    value: 'inbox',
  },
  {
    id: 378,
    name: 'fa-indent',
    value: 'indent',
  },
  {
    id: 379,
    name: 'fa-industry',
    value: 'industry',
  },
  {
    id: 380,
    name: 'fa-info',
    value: 'info',
  },
  {
    id: 381,
    name: 'fa-info-circle',
    value: 'info circle',
  },
  {
    id: 382,
    name: 'fa-inr',
    value: 'inr',
  },
  {
    id: 383,
    name: 'fa-instagram',
    value: 'instagram',
  },
  {
    id: 384,
    name: 'fa-institution',
    value: 'institution',
  },
  {
    id: 385,
    name: 'fa-internet-explorer',
    value: 'internet explorer',
  },
  {
    id: 386,
    name: 'fa-intersex',
    value: 'intersex',
  },
  {
    id: 387,
    name: 'fa-ioxhost',
    value: 'ioxhost',
  },
  {
    id: 388,
    name: 'fa-italic',
    value: 'italic',
  },
  {
    id: 389,
    name: 'fa-joomla',
    value: 'joomla',
  },
  {
    id: 390,
    name: 'fa-jpy',
    value: 'jpy',
  },
  {
    id: 391,
    name: 'fa-jsfiddle',
    value: 'jsfiddle',
  },
  {
    id: 392,
    name: 'fa-key',
    value: 'key',
  },
  {
    id: 393,
    name: 'fa-keyboard-o',
    value: 'keyboard o',
  },
  {
    id: 394,
    name: 'fa-krw',
    value: 'krw',
  },
  {
    id: 395,
    name: 'fa-language',
    value: 'language',
  },
  {
    id: 396,
    name: 'fa-laptop',
    value: 'laptop',
  },
  {
    id: 397,
    name: 'fa-lastfm',
    value: 'lastfm',
  },
  {
    id: 398,
    name: 'fa-lastfm-square',
    value: 'lastfm square',
  },
  {
    id: 399,
    name: 'fa-leaf',
    value: 'leaf',
  },
  {
    id: 400,
    name: 'fa-leanpub',
    value: 'leanpub',
  },
  {
    id: 401,
    name: 'fa-legal',
    value: 'legal',
  },
  {
    id: 402,
    name: 'fa-lemon-o',
    value: 'lemon o',
  },
  {
    id: 403,
    name: 'fa-level-down',
    value: 'level down',
  },
  {
    id: 404,
    name: 'fa-level-up',
    value: 'level up',
  },
  {
    id: 405,
    name: 'fa-life-bouy',
    value: 'life bouy',
  },
  {
    id: 406,
    name: 'fa-life-buoy',
    value: 'life buoy',
  },
  {
    id: 407,
    name: 'fa-life-ring',
    value: 'life ring',
  },
  {
    id: 408,
    name: 'fa-life-saver',
    value: 'life saver',
  },
  {
    id: 409,
    name: 'fa-lightbulb-o',
    value: 'lightbulb o',
  },
  {
    id: 410,
    name: 'fa-line-chart',
    value: 'line chart',
  },
  {
    id: 411,
    name: 'fa-link',
    value: 'link',
  },
  {
    id: 412,
    name: 'fa-linkedin',
    value: 'linkedin',
  },
  {
    id: 413,
    name: 'fa-linkedin-square',
    value: 'linkedin square',
  },
  {
    id: 414,
    name: 'fa-linode',
    value: 'linode',
  },
  {
    id: 415,
    name: 'fa-linux',
    value: 'linux',
  },
  {
    id: 416,
    name: 'fa-list',
    value: 'list',
  },
  {
    id: 417,
    name: 'fa-list-alt',
    value: 'list alt',
  },
  {
    id: 418,
    name: 'fa-list-ol',
    value: 'list ol',
  },
  {
    id: 419,
    name: 'fa-list-ul',
    value: 'list ul',
  },
  {
    id: 420,
    name: 'fa-location-arrow',
    value: 'location arrow',
  },
  {
    id: 421,
    name: 'fa-lock',
    value: 'lock',
  },
  {
    id: 422,
    name: 'fa-long-arrow-down',
    value: 'long arrow down',
  },
  {
    id: 423,
    name: 'fa-long-arrow-left',
    value: 'long arrow left',
  },
  {
    id: 424,
    name: 'fa-long-arrow-right',
    value: 'long arrow right',
  },
  {
    id: 425,
    name: 'fa-long-arrow-up',
    value: 'long arrow up',
  },
  {
    id: 426,
    name: 'fa-low-vision',
    value: 'low vision',
  },
  {
    id: 427,
    name: 'fa-magic',
    value: 'magic',
  },
  {
    id: 428,
    name: 'fa-magnet',
    value: 'magnet',
  },
  {
    id: 429,
    name: 'fa-mail-forward',
    value: 'mail forward',
  },
  {
    id: 430,
    name: 'fa-mail-reply',
    value: 'mail reply',
  },
  {
    id: 431,
    name: 'fa-mail-reply-all',
    value: 'mail reply all',
  },
  {
    id: 432,
    name: 'fa-male',
    value: 'male',
  },
  {
    id: 433,
    name: 'fa-map',
    value: 'map',
  },
  {
    id: 434,
    name: 'fa-map-marker',
    value: 'map marker',
  },
  {
    id: 435,
    name: 'fa-map-o',
    value: 'map o',
  },
  {
    id: 436,
    name: 'fa-map-pin',
    value: 'map pin',
  },
  {
    id: 437,
    name: 'fa-map-signs',
    value: 'map signs',
  },
  {
    id: 438,
    name: 'fa-mars',
    value: 'mars',
  },
  {
    id: 439,
    name: 'fa-mars-double',
    value: 'mars double',
  },
  {
    id: 440,
    name: 'fa-mars-stroke',
    value: 'mars stroke',
  },
  {
    id: 441,
    name: 'fa-mars-stroke-h',
    value: 'mars stroke h',
  },
  {
    id: 442,
    name: 'fa-mars-stroke-v',
    value: 'mars stroke v',
  },
  {
    id: 443,
    name: 'fa-maxcdn',
    value: 'maxcdn',
  },
  {
    id: 444,
    name: 'fa-meanpath',
    value: 'meanpath',
  },
  {
    id: 445,
    name: 'fa-medium',
    value: 'medium',
  },
  {
    id: 446,
    name: 'fa-medkit',
    value: 'medkit',
  },
  {
    id: 447,
    name: 'fa-meetup',
    value: 'meetup',
  },
  {
    id: 448,
    name: 'fa-meh-o',
    value: 'meh o',
  },
  {
    id: 449,
    name: 'fa-mercury',
    value: 'mercury',
  },
  {
    id: 450,
    name: 'fa-microchip',
    value: 'microchip',
  },
  {
    id: 451,
    name: 'fa-microphone',
    value: 'microphone',
  },
  {
    id: 452,
    name: 'fa-microphone-slash',
    value: 'microphone slash',
  },
  {
    id: 453,
    name: 'fa-minus',
    value: 'minus',
  },
  {
    id: 454,
    name: 'fa-minus-circle',
    value: 'minus circle',
  },
  {
    id: 455,
    name: 'fa-minus-square',
    value: 'minus square',
  },
  {
    id: 456,
    name: 'fa-minus-square-o',
    value: 'minus square o',
  },
  {
    id: 457,
    name: 'fa-mixcloud',
    value: 'mixcloud',
  },
  {
    id: 458,
    name: 'fa-mobile',
    value: 'mobile',
  },
  {
    id: 459,
    name: 'fa-mobile-phone',
    value: 'mobile phone',
  },
  {
    id: 460,
    name: 'fa-modx',
    value: 'modx',
  },
  {
    id: 461,
    name: 'fa-money',
    value: 'money',
  },
  {
    id: 462,
    name: 'fa-moon-o',
    value: 'moon o',
  },
  {
    id: 463,
    name: 'fa-mortar-board',
    value: 'mortar board',
  },
  {
    id: 464,
    name: 'fa-motorcycle',
    value: 'motorcycle',
  },
  {
    id: 465,
    name: 'fa-mouse-pointer',
    value: 'mouse pointer',
  },
  {
    id: 466,
    name: 'fa-music',
    value: 'music',
  },
  {
    id: 467,
    name: 'fa-navicon',
    value: 'navicon',
  },
  {
    id: 468,
    name: 'fa-neuter',
    value: 'neuter',
  },
  {
    id: 469,
    name: 'fa-newspaper-o',
    value: 'newspaper o',
  },
  {
    id: 470,
    name: 'fa-object-group',
    value: 'object group',
  },
  {
    id: 471,
    name: 'fa-object-ungroup',
    value: 'object ungroup',
  },
  {
    id: 472,
    name: 'fa-odnoklassniki',
    value: 'odnoklassniki',
  },
  {
    id: 473,
    name: 'fa-odnoklassniki-square',
    value: 'odnoklassniki square',
  },
  {
    id: 474,
    name: 'fa-opencart',
    value: 'opencart',
  },
  {
    id: 475,
    name: 'fa-openid',
    value: 'openid',
  },
  {
    id: 476,
    name: 'fa-opera',
    value: 'opera',
  },
  {
    id: 477,
    name: 'fa-optin-monster',
    value: 'optin monster',
  },
  {
    id: 478,
    name: 'fa-outdent',
    value: 'outdent',
  },
  {
    id: 479,
    name: 'fa-pagelines',
    value: 'pagelines',
  },
  {
    id: 480,
    name: 'fa-paint-brush',
    value: 'paint brush',
  },
  {
    id: 481,
    name: 'fa-paper-plane',
    value: 'paper plane',
  },
  {
    id: 482,
    name: 'fa-paper-plane-o',
    value: 'paper plane o',
  },
  {
    id: 483,
    name: 'fa-paperclip',
    value: 'paperclip',
  },
  {
    id: 484,
    name: 'fa-paragraph',
    value: 'paragraph',
  },
  {
    id: 485,
    name: 'fa-paste',
    value: 'paste',
  },
  {
    id: 486,
    name: 'fa-pause',
    value: 'pause',
  },
  {
    id: 487,
    name: 'fa-pause-circle',
    value: 'pause circle',
  },
  {
    id: 488,
    name: 'fa-pause-circle-o',
    value: 'pause circle o',
  },
  {
    id: 489,
    name: 'fa-paw',
    value: 'paw',
  },
  {
    id: 490,
    name: 'fa-paypal',
    value: 'paypal',
  },
  {
    id: 491,
    name: 'fa-pencil',
    value: 'pencil',
  },
  {
    id: 492,
    name: 'fa-pencil-square',
    value: 'pencil square',
  },
  {
    id: 493,
    name: 'fa-pencil-square-o',
    value: 'pencil square o',
  },
  {
    id: 494,
    name: 'fa-percent',
    value: 'percent',
  },
  {
    id: 495,
    name: 'fa-phone',
    value: 'phone',
  },
  {
    id: 496,
    name: 'fa-phone-square',
    value: 'phone square',
  },
  {
    id: 497,
    name: 'fa-photo',
    value: 'photo',
  },
  {
    id: 498,
    name: 'fa-picture-o',
    value: 'picture o',
  },
  {
    id: 499,
    name: 'fa-pie-chart',
    value: 'pie chart',
  },
  {
    id: 500,
    name: 'fa-pied-piper',
    value: 'pied piper',
  },
  {
    id: 501,
    name: 'fa-pied-piper-alt',
    value: 'pied piper alt',
  },
  {
    id: 502,
    name: 'fa-pied-piper-pp',
    value: 'pied piper pp',
  },
  {
    id: 503,
    name: 'fa-pinterest',
    value: 'pinterest',
  },
  {
    id: 504,
    name: 'fa-pinterest-p',
    value: 'pinterest p',
  },
  {
    id: 505,
    name: 'fa-pinterest-square',
    value: 'pinterest square',
  },
  {
    id: 506,
    name: 'fa-plane',
    value: 'plane',
  },
  {
    id: 507,
    name: 'fa-play',
    value: 'play',
  },
  {
    id: 508,
    name: 'fa-play-circle',
    value: 'play circle',
  },
  {
    id: 509,
    name: 'fa-play-circle-o',
    value: 'play circle o',
  },
  {
    id: 510,
    name: 'fa-plug',
    value: 'plug',
  },
  {
    id: 511,
    name: 'fa-plus',
    value: 'plus',
  },
  {
    id: 512,
    name: 'fa-plus-circle',
    value: 'plus circle',
  },
  {
    id: 513,
    name: 'fa-plus-square',
    value: 'plus square',
  },
  {
    id: 514,
    name: 'fa-plus-square-o',
    value: 'plus square o',
  },
  {
    id: 515,
    name: 'fa-podcast',
    value: 'podcast',
  },
  {
    id: 516,
    name: 'fa-power-off',
    value: 'power off',
  },
  {
    id: 517,
    name: 'fa-print',
    value: 'print',
  },
  {
    id: 518,
    name: 'fa-product-hunt',
    value: 'product hunt',
  },
  {
    id: 519,
    name: 'fa-puzzle-piece',
    value: 'puzzle piece',
  },
  {
    id: 520,
    name: 'fa-qq',
    value: 'qq',
  },
  {
    id: 521,
    name: 'fa-qrcode',
    value: 'qrcode',
  },
  {
    id: 522,
    name: 'fa-question',
    value: 'question',
  },
  {
    id: 523,
    name: 'fa-question-circle',
    value: 'question circle',
  },
  {
    id: 524,
    name: 'fa-question-circle-o',
    value: 'question circle o',
  },
  {
    id: 525,
    name: 'fa-quora',
    value: 'quora',
  },
  {
    id: 526,
    name: 'fa-quote-left',
    value: 'quote left',
  },
  {
    id: 527,
    name: 'fa-quote-right',
    value: 'quote right',
  },
  {
    id: 528,
    name: 'fa-ra',
    value: 'ra',
  },
  {
    id: 529,
    name: 'fa-random',
    value: 'random',
  },
  {
    id: 530,
    name: 'fa-ravelry',
    value: 'ravelry',
  },
  {
    id: 531,
    name: 'fa-rebel',
    value: 'rebel',
  },
  {
    id: 532,
    name: 'fa-recycle',
    value: 'recycle',
  },
  {
    id: 533,
    name: 'fa-reddit',
    value: 'reddit',
  },
  {
    id: 534,
    name: 'fa-reddit-alien',
    value: 'reddit alien',
  },
  {
    id: 535,
    name: 'fa-reddit-square',
    value: 'reddit square',
  },
  {
    id: 536,
    name: 'fa-refresh',
    value: 'refresh',
  },
  {
    id: 537,
    name: 'fa-registered',
    value: 'registered',
  },
  {
    id: 538,
    name: 'fa-remove',
    value: 'remove',
  },
  {
    id: 539,
    name: 'fa-renren',
    value: 'renren',
  },
  {
    id: 540,
    name: 'fa-reorder',
    value: 'reorder',
  },
  {
    id: 541,
    name: 'fa-repeat',
    value: 'repeat',
  },
  {
    id: 542,
    name: 'fa-reply',
    value: 'reply',
  },
  {
    id: 543,
    name: 'fa-reply-all',
    value: 'reply all',
  },
  {
    id: 544,
    name: 'fa-resistance',
    value: 'resistance',
  },
  {
    id: 545,
    name: 'fa-retweet',
    value: 'retweet',
  },
  {
    id: 546,
    name: 'fa-rmb',
    value: 'rmb',
  },
  {
    id: 547,
    name: 'fa-road',
    value: 'road',
  },
  {
    id: 548,
    name: 'fa-rocket',
    value: 'rocket',
  },
  {
    id: 549,
    name: 'fa-rotate-left',
    value: 'rotate left',
  },
  {
    id: 550,
    name: 'fa-rotate-right',
    value: 'rotate right',
  },
  {
    id: 551,
    name: 'fa-rouble',
    value: 'rouble',
  },
  {
    id: 552,
    name: 'fa-rss',
    value: 'rss',
  },
  {
    id: 553,
    name: 'fa-rss-square',
    value: 'rss square',
  },
  {
    id: 554,
    name: 'fa-rub',
    value: 'rub',
  },
  {
    id: 555,
    name: 'fa-ruble',
    value: 'ruble',
  },
  {
    id: 556,
    name: 'fa-rupee',
    value: 'rupee',
  },
  {
    id: 557,
    name: 'fa-s15',
    value: 's15',
  },
  {
    id: 558,
    name: 'fa-safari',
    value: 'safari',
  },
  {
    id: 559,
    name: 'fa-save',
    value: 'save',
  },
  {
    id: 560,
    name: 'fa-scissors',
    value: 'scissors',
  },
  {
    id: 561,
    name: 'fa-scribd',
    value: 'scribd',
  },
  {
    id: 562,
    name: 'fa-search',
    value: 'search',
  },
  {
    id: 563,
    name: 'fa-search-minus',
    value: 'search minus',
  },
  {
    id: 564,
    name: 'fa-search-plus',
    value: 'search plus',
  },
  {
    id: 565,
    name: 'fa-sellsy',
    value: 'sellsy',
  },
  {
    id: 566,
    name: 'fa-send',
    value: 'send',
  },
  {
    id: 567,
    name: 'fa-send-o',
    value: 'send o',
  },
  {
    id: 568,
    name: 'fa-server',
    value: 'server',
  },
  {
    id: 569,
    name: 'fa-share',
    value: 'share',
  },
  {
    id: 570,
    name: 'fa-share-alt',
    value: 'share alt',
  },
  {
    id: 571,
    name: 'fa-share-alt-square',
    value: 'share alt square',
  },
  {
    id: 572,
    name: 'fa-share-square',
    value: 'share square',
  },
  {
    id: 573,
    name: 'fa-share-square-o',
    value: 'share square o',
  },
  {
    id: 574,
    name: 'fa-shekel',
    value: 'shekel',
  },
  {
    id: 575,
    name: 'fa-sheqel',
    value: 'sheqel',
  },
  {
    id: 576,
    name: 'fa-shield',
    value: 'shield',
  },
  {
    id: 577,
    name: 'fa-ship',
    value: 'ship',
  },
  {
    id: 578,
    name: 'fa-shirtsinbulk',
    value: 'shirtsinbulk',
  },
  {
    id: 579,
    name: 'fa-shopping-bag',
    value: 'shopping bag',
  },
  {
    id: 580,
    name: 'fa-shopping-basket',
    value: 'shopping basket',
  },
  {
    id: 581,
    name: 'fa-shopping-cart',
    value: 'shopping cart',
  },
  {
    id: 582,
    name: 'fa-shower',
    value: 'shower',
  },
  {
    id: 583,
    name: 'fa-sign-in',
    value: 'sign in',
  },
  {
    id: 584,
    name: 'fa-sign-language',
    value: 'sign language',
  },
  {
    id: 585,
    name: 'fa-sign-out',
    value: 'sign out',
  },
  {
    id: 586,
    name: 'fa-signal',
    value: 'signal',
  },
  {
    id: 587,
    name: 'fa-signing',
    value: 'signing',
  },
  {
    id: 588,
    name: 'fa-simplybuilt',
    value: 'simplybuilt',
  },
  {
    id: 589,
    name: 'fa-sitemap',
    value: 'sitemap',
  },
  {
    id: 590,
    name: 'fa-skyatlas',
    value: 'skyatlas',
  },
  {
    id: 591,
    name: 'fa-skype',
    value: 'skype',
  },
  {
    id: 592,
    name: 'fa-slack',
    value: 'slack',
  },
  {
    id: 593,
    name: 'fa-sliders',
    value: 'sliders',
  },
  {
    id: 594,
    name: 'fa-slideshare',
    value: 'slideshare',
  },
  {
    id: 595,
    name: 'fa-smile-o',
    value: 'smile o',
  },
  {
    id: 596,
    name: 'fa-snapchat',
    value: 'snapchat',
  },
  {
    id: 597,
    name: 'fa-snapchat-ghost',
    value: 'snapchat ghost',
  },
  {
    id: 598,
    name: 'fa-snapchat-square',
    value: 'snapchat square',
  },
  {
    id: 599,
    name: 'fa-snowflake-o',
    value: 'snowflake o',
  },
  {
    id: 600,
    name: 'fa-soccer-ball-o',
    value: 'soccer ball o',
  },
  {
    id: 601,
    name: 'fa-sort',
    value: 'sort',
  },
  {
    id: 602,
    name: 'fa-sort-alpha-asc',
    value: 'sort alpha asc',
  },
  {
    id: 603,
    name: 'fa-sort-alpha-desc',
    value: 'sort alpha desc',
  },
  {
    id: 604,
    name: 'fa-sort-amount-asc',
    value: 'sort amount asc',
  },
  {
    id: 605,
    name: 'fa-sort-amount-desc',
    value: 'sort amount desc',
  },
  {
    id: 606,
    name: 'fa-sort-asc',
    value: 'sort asc',
  },
  {
    id: 607,
    name: 'fa-sort-desc',
    value: 'sort desc',
  },
  {
    id: 608,
    name: 'fa-sort-down',
    value: 'sort down',
  },
  {
    id: 609,
    name: 'fa-sort-numeric-asc',
    value: 'sort numeric asc',
  },
  {
    id: 610,
    name: 'fa-sort-numeric-desc',
    value: 'sort numeric desc',
  },
  {
    id: 611,
    name: 'fa-sort-up',
    value: 'sort up',
  },
  {
    id: 612,
    name: 'fa-soundcloud',
    value: 'soundcloud',
  },
  {
    id: 613,
    name: 'fa-space-shuttle',
    value: 'space shuttle',
  },
  {
    id: 614,
    name: 'fa-spinner',
    value: 'spinner',
  },
  {
    id: 615,
    name: 'fa-spoon',
    value: 'spoon',
  },
  {
    id: 616,
    name: 'fa-spotify',
    value: 'spotify',
  },
  {
    id: 617,
    name: 'fa-square',
    value: 'square',
  },
  {
    id: 618,
    name: 'fa-square-o',
    value: 'square o',
  },
  {
    id: 619,
    name: 'fa-stack-exchange',
    value: 'stack exchange',
  },
  {
    id: 620,
    name: 'fa-stack-overflow',
    value: 'stack overflow',
  },
  {
    id: 621,
    name: 'fa-star',
    value: 'star',
  },
  {
    id: 622,
    name: 'fa-star-half',
    value: 'star half',
  },
  {
    id: 623,
    name: 'fa-star-half-empty',
    value: 'star half empty',
  },
  {
    id: 624,
    name: 'fa-star-half-full',
    value: 'star half full',
  },
  {
    id: 625,
    name: 'fa-star-half-o',
    value: 'star half o',
  },
  {
    id: 626,
    name: 'fa-star-o',
    value: 'star o',
  },
  {
    id: 627,
    name: 'fa-steam',
    value: 'steam',
  },
  {
    id: 628,
    name: 'fa-steam-square',
    value: 'steam square',
  },
  {
    id: 629,
    name: 'fa-step-backward',
    value: 'step backward',
  },
  {
    id: 630,
    name: 'fa-step-forward',
    value: 'step forward',
  },
  {
    id: 631,
    name: 'fa-stethoscope',
    value: 'stethoscope',
  },
  {
    id: 632,
    name: 'fa-sticky-note',
    value: 'sticky note',
  },
  {
    id: 633,
    name: 'fa-sticky-note-o',
    value: 'sticky note o',
  },
  {
    id: 634,
    name: 'fa-stop',
    value: 'stop',
  },
  {
    id: 635,
    name: 'fa-stop-circle',
    value: 'stop circle',
  },
  {
    id: 636,
    name: 'fa-stop-circle-o',
    value: 'stop circle o',
  },
  {
    id: 637,
    name: 'fa-street-view',
    value: 'street view',
  },
  {
    id: 638,
    name: 'fa-strikethrough',
    value: 'strikethrough',
  },
  {
    id: 639,
    name: 'fa-stumbleupon',
    value: 'stumbleupon',
  },
  {
    id: 640,
    name: 'fa-stumbleupon-circle',
    value: 'stumbleupon circle',
  },
  {
    id: 641,
    name: 'fa-subscript',
    value: 'subscript',
  },
  {
    id: 642,
    name: 'fa-subway',
    value: 'subway',
  },
  {
    id: 643,
    name: 'fa-suitcase',
    value: 'suitcase',
  },
  {
    id: 644,
    name: 'fa-sun-o',
    value: 'sun o',
  },
  {
    id: 645,
    name: 'fa-superpowers',
    value: 'superpowers',
  },
  {
    id: 646,
    name: 'fa-superscript',
    value: 'superscript',
  },
  {
    id: 647,
    name: 'fa-support',
    value: 'support',
  },
  {
    id: 648,
    name: 'fa-table',
    value: 'table',
  },
  {
    id: 649,
    name: 'fa-tablet',
    value: 'tablet',
  },
  {
    id: 650,
    name: 'fa-tachometer',
    value: 'tachometer',
  },
  {
    id: 651,
    name: 'fa-tag',
    value: 'tag',
  },
  {
    id: 652,
    name: 'fa-tags',
    value: 'tags',
  },
  {
    id: 653,
    name: 'fa-tasks',
    value: 'tasks',
  },
  {
    id: 654,
    name: 'fa-taxi',
    value: 'taxi',
  },
  {
    id: 655,
    name: 'fa-telegram',
    value: 'telegram',
  },
  {
    id: 656,
    name: 'fa-television',
    value: 'television',
  },
  {
    id: 657,
    name: 'fa-tencent-weibo',
    value: 'tencent weibo',
  },
  {
    id: 658,
    name: 'fa-terminal',
    value: 'terminal',
  },
  {
    id: 659,
    name: 'fa-text-height',
    value: 'text height',
  },
  {
    id: 660,
    name: 'fa-text-width',
    value: 'text width',
  },
  {
    id: 661,
    name: 'fa-th',
    value: 'th',
  },
  {
    id: 662,
    name: 'fa-th-large',
    value: 'th large',
  },
  {
    id: 663,
    name: 'fa-th-list',
    value: 'th list',
  },
  {
    id: 664,
    name: 'fa-themeisle',
    value: 'themeisle',
  },
  {
    id: 665,
    name: 'fa-thermometer',
    value: 'thermometer',
  },
  {
    id: 666,
    name: 'fa-thermometer-0',
    value: 'thermometer 0',
  },
  {
    id: 667,
    name: 'fa-thermometer-1',
    value: 'thermometer 1',
  },
  {
    id: 668,
    name: 'fa-thermometer-2',
    value: 'thermometer 2',
  },
  {
    id: 669,
    name: 'fa-thermometer-3',
    value: 'thermometer 3',
  },
  {
    id: 670,
    name: 'fa-thermometer-4',
    value: 'thermometer 4',
  },
  {
    id: 671,
    name: 'fa-thermometer-empty',
    value: 'thermometer empty',
  },
  {
    id: 672,
    name: 'fa-thermometer-full',
    value: 'thermometer full',
  },
  {
    id: 673,
    name: 'fa-thermometer-half',
    value: 'thermometer half',
  },
  {
    id: 674,
    name: 'fa-thermometer-quarter',
    value: 'thermometer quarter',
  },
  {
    id: 675,
    name: 'fa-thermometer-three-quarters',
    value: 'thermometer three quarters',
  },
  {
    id: 676,
    name: 'fa-thumb-tack',
    value: 'thumb tack',
  },
  {
    id: 677,
    name: 'fa-thumbs-down',
    value: 'thumbs down',
  },
  {
    id: 678,
    name: 'fa-thumbs-o-down',
    value: 'thumbs o down',
  },
  {
    id: 679,
    name: 'fa-thumbs-o-up',
    value: 'thumbs o up',
  },
  {
    id: 680,
    name: 'fa-thumbs-up',
    value: 'thumbs up',
  },
  {
    id: 681,
    name: 'fa-ticket',
    value: 'ticket',
  },
  {
    id: 682,
    name: 'fa-times',
    value: 'times',
  },
  {
    id: 683,
    name: 'fa-times-circle',
    value: 'times circle',
  },
  {
    id: 684,
    name: 'fa-times-circle-o',
    value: 'times circle o',
  },
  {
    id: 685,
    name: 'fa-times-rectangle',
    value: 'times rectangle',
  },
  {
    id: 686,
    name: 'fa-times-rectangle-o',
    value: 'times rectangle o',
  },
  {
    id: 687,
    name: 'fa-tint',
    value: 'tint',
  },
  {
    id: 688,
    name: 'fa-toggle-down',
    value: 'toggle down',
  },
  {
    id: 689,
    name: 'fa-toggle-left',
    value: 'toggle left',
  },
  {
    id: 690,
    name: 'fa-toggle-off',
    value: 'toggle off',
  },
  {
    id: 691,
    name: 'fa-toggle-on',
    value: 'toggle on',
  },
  {
    id: 692,
    name: 'fa-toggle-right',
    value: 'toggle right',
  },
  {
    id: 693,
    name: 'fa-toggle-up',
    value: 'toggle up',
  },
  {
    id: 694,
    name: 'fa-trademark',
    value: 'trademark',
  },
  {
    id: 695,
    name: 'fa-train',
    value: 'train',
  },
  {
    id: 696,
    name: 'fa-transgender',
    value: 'transgender',
  },
  {
    id: 697,
    name: 'fa-transgender-alt',
    value: 'transgender alt',
  },
  {
    id: 698,
    name: 'fa-trash',
    value: 'trash',
  },
  {
    id: 699,
    name: 'fa-trash-o',
    value: 'trash o',
  },
  {
    id: 700,
    name: 'fa-tree',
    value: 'tree',
  },
  {
    id: 701,
    name: 'fa-trello',
    value: 'trello',
  },
  {
    id: 702,
    name: 'fa-tripadvisor',
    value: 'tripadvisor',
  },
  {
    id: 703,
    name: 'fa-trophy',
    value: 'trophy',
  },
  {
    id: 704,
    name: 'fa-truck',
    value: 'truck',
  },
  {
    id: 705,
    name: 'fa-try',
    value: 'try',
  },
  {
    id: 706,
    name: 'fa-tty',
    value: 'tty',
  },
  {
    id: 707,
    name: 'fa-tumblr',
    value: 'tumblr',
  },
  {
    id: 708,
    name: 'fa-tumblr-square',
    value: 'tumblr square',
  },
  {
    id: 709,
    name: 'fa-turkish-lira',
    value: 'turkish lira',
  },
  {
    id: 710,
    name: 'fa-tv',
    value: 'tv',
  },
  {
    id: 711,
    name: 'fa-twitch',
    value: 'twitch',
  },
  {
    id: 712,
    name: 'fa-twitter',
    value: 'twitter',
  },
  {
    id: 713,
    name: 'fa-twitter-square',
    value: 'twitter square',
  },
  {
    id: 714,
    name: 'fa-umbrella',
    value: 'umbrella',
  },
  {
    id: 715,
    name: 'fa-underline',
    value: 'underline',
  },
  {
    id: 716,
    name: 'fa-undo',
    value: 'undo',
  },
  {
    id: 717,
    name: 'fa-universal-access',
    value: 'universal access',
  },
  {
    id: 718,
    name: 'fa-university',
    value: 'university',
  },
  {
    id: 719,
    name: 'fa-unlink',
    value: 'unlink',
  },
  {
    id: 720,
    name: 'fa-unlock',
    value: 'unlock',
  },
  {
    id: 721,
    name: 'fa-unlock-alt',
    value: 'unlock alt',
  },
  {
    id: 722,
    name: 'fa-unsorted',
    value: 'unsorted',
  },
  {
    id: 723,
    name: 'fa-upload',
    value: 'upload',
  },
  {
    id: 724,
    name: 'fa-usb',
    value: 'usb',
  },
  {
    id: 725,
    name: 'fa-usd',
    value: 'usd',
  },
  {
    id: 726,
    name: 'fa-user',
    value: 'user',
  },
  {
    id: 727,
    name: 'fa-user-circle',
    value: 'user circle',
  },
  {
    id: 728,
    name: 'fa-user-circle-o',
    value: 'user circle o',
  },
  {
    id: 729,
    name: 'fa-user-md',
    value: 'user md',
  },
  {
    id: 730,
    name: 'fa-user-o',
    value: 'user o',
  },
  {
    id: 731,
    name: 'fa-user-plus',
    value: 'user plus',
  },
  {
    id: 732,
    name: 'fa-user-secret',
    value: 'user secret',
  },
  {
    id: 733,
    name: 'fa-user-times',
    value: 'user times',
  },
  {
    id: 734,
    name: 'fa-users',
    value: 'users',
  },
  {
    id: 735,
    name: 'fa-vcard',
    value: 'vcard',
  },
  {
    id: 736,
    name: 'fa-vcard-o',
    value: 'vcard o',
  },
  {
    id: 737,
    name: 'fa-venus',
    value: 'venus',
  },
  {
    id: 738,
    name: 'fa-venus-double',
    value: 'venus double',
  },
  {
    id: 739,
    name: 'fa-venus-mars',
    value: 'venus mars',
  },
  {
    id: 740,
    name: 'fa-viacoin',
    value: 'viacoin',
  },
  {
    id: 741,
    name: 'fa-viadeo',
    value: 'viadeo',
  },
  {
    id: 742,
    name: 'fa-viadeo-square',
    value: 'viadeo square',
  },
  {
    id: 743,
    name: 'fa-video-camera',
    value: 'video camera',
  },
  {
    id: 744,
    name: 'fa-vimeo',
    value: 'vimeo',
  },
  {
    id: 745,
    name: 'fa-vimeo-square',
    value: 'vimeo square',
  },
  {
    id: 746,
    name: 'fa-vine',
    value: 'vine',
  },
  {
    id: 747,
    name: 'fa-vk',
    value: 'vk',
  },
  {
    id: 748,
    name: 'fa-volume-control-phone',
    value: 'volume control phone',
  },
  {
    id: 749,
    name: 'fa-volume-down',
    value: 'volume down',
  },
  {
    id: 750,
    name: 'fa-volume-off',
    value: 'volume off',
  },
  {
    id: 751,
    name: 'fa-volume-up',
    value: 'volume up',
  },
  {
    id: 752,
    name: 'fa-warning',
    value: 'warning',
  },
  {
    id: 753,
    name: 'fa-wechat',
    value: 'wechat',
  },
  {
    id: 754,
    name: 'fa-weibo',
    value: 'weibo',
  },
  {
    id: 755,
    name: 'fa-weixin',
    value: 'weixin',
  },
  {
    id: 756,
    name: 'fa-whatsapp',
    value: 'whatsapp',
  },
  {
    id: 757,
    name: 'fa-wheelchair',
    value: 'wheelchair',
  },
  {
    id: 758,
    name: 'fa-wheelchair-alt',
    value: 'wheelchair alt',
  },
  {
    id: 759,
    name: 'fa-wifi',
    value: 'wifi',
  },
  {
    id: 760,
    name: 'fa-wikipedia-w',
    value: 'wikipedia w',
  },
  {
    id: 761,
    name: 'fa-window-close',
    value: 'window close',
  },
  {
    id: 762,
    name: 'fa-window-close-o',
    value: 'window close o',
  },
  {
    id: 763,
    name: 'fa-window-maximize',
    value: 'window maximize',
  },
  {
    id: 764,
    name: 'fa-window-minimize',
    value: 'window minimize',
  },
  {
    id: 765,
    name: 'fa-window-restore',
    value: 'window restore',
  },
  {
    id: 766,
    name: 'fa-windows',
    value: 'windows',
  },
  {
    id: 767,
    name: 'fa-won',
    value: 'won',
  },
  {
    id: 768,
    name: 'fa-wordpress',
    value: 'wordpress',
  },
  {
    id: 769,
    name: 'fa-wpbeginner',
    value: 'wpbeginner',
  },
  {
    id: 770,
    name: 'fa-wpexplorer',
    value: 'wpexplorer',
  },
  {
    id: 771,
    name: 'fa-wpforms',
    value: 'wpforms',
  },
  {
    id: 772,
    name: 'fa-wrench',
    value: 'wrench',
  },
  {
    id: 773,
    name: 'fa-xing',
    value: 'xing',
  },
  {
    id: 774,
    name: 'fa-xing-square',
    value: 'xing square',
  },
  {
    id: 775,
    name: 'fa-y-combinator',
    value: 'y combinator',
  },
  {
    id: 776,
    name: 'fa-y-combinator-square',
    value: 'y combinator square',
  },
  {
    id: 777,
    name: 'fa-yahoo',
    value: 'yahoo',
  },
  {
    id: 778,
    name: 'fa-yc',
    value: 'yc',
  },
  {
    id: 779,
    name: 'fa-yc-square',
    value: 'yc square',
  },
  {
    id: 780,
    name: 'fa-yelp',
    value: 'yelp',
  },
  {
    id: 781,
    name: 'fa-yen',
    value: 'yen',
  },
  {
    id: 782,
    name: 'fa-yoast',
    value: 'yoast',
  },
  {
    id: 783,
    name: 'fa-youtube',
    value: 'youtube',
  },
  {
    id: 784,
    name: 'fa-youtube-play',
    value: 'youtube play',
  },
  {
    id: 785,
    name: 'fa-youtube-square',
    value: 'youtube square',
  },
]
