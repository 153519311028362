import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { CORE_CONFIG } from '../../../core/core.config'
import { StoreAuthService, UserService } from '../../../core/services'

@Component({
  selector: 'ef-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  storeAuthService: StoreAuthService
  isPasswordVisible: boolean = false
  titles = [
    { title: 'Mr.', value: 'mr' },
    { title: 'Mrs.', value: 'mrs' }
  ]
  form: FormGroup
  submitted: boolean = false
  companyId: string = 'h345h34g5hjj' // temp data will get from route params
  registrToken: string = '34g5v63jg5463g54' // temp data will get from route params
  companyName: string = 'Good company' // temp data will get from route params
  onRegistrSpinner: boolean = false
  passwPattern: string = CORE_CONFIG.PASSWORD_PATTERN

  constructor(private _router: Router, private _userService: UserService, storeAuthService: StoreAuthService) {
    this.storeAuthService = storeAuthService
  }

  ngOnInit() {
    // const routeParams = this._activatedRoute.snapshot.queryParamMap
    // this.companyId = routeParams.get('companyId')
    // this.registrToken = routeParams.get('registrToken')
    // this.companyName = routeParams.get('companyName')

    this.form = new FormGroup({})

    this.storeAuthService.getOrganization().subscribe(org => {
      if (org) {
        this.companyName = org.displayName
      }
    })
  }

  // view items for correct password

  hasLowerCase() {
    return this.form.controls.password && this.form.controls.password.value
      ? /[a-z]/g.test(this.form.controls.password.value)
      : false
  }

  hasUpperCase() {
    return this.form.controls.password && this.form.controls.password.value
      ? /[A-Z]/g.test(this.form.controls.password.value)
      : false
  }

  hasNumber() {
    return this.form.controls.password && this.form.controls.password.value
      ? /[0-9]/g.test(this.form.controls.password.value)
      : false
  }

  hasSpecial() {
    return this.form.controls.password && this.form.controls.password.value
      ? /[\$@#%\^\&*?!.()+=-]/g.test(this.form.controls.password.value)
      : false
  }

  continue() {
    this.submitted = true
    if (this.form.valid && this.form.controls.password.value === this.form.controls.confirmPassword.value) {
      this.onRegistrSpinner = true
      //TODO replace with userservice fields, need to populate
      //Need to remove password unless we generate it for them

      // this._userService
      //   .post({
      //     avatar: '',
      //     phone: '',
      //     position: '',
      //     positionDescript: '',
      //     responsibility: '',
      //     roles: [],
      //     timeZone: '',
      //     // title: this.form.controls.title.value,
      //     name: this.form.controls.title.value,
      //     // password: this.form.controls.title.value,
      //     // companyId: this.companyId,
      //     // registrToken: this.registrToken,
      //     companyName: this.companyName
      //   })
      //   .subscribe(res => {
      //     this.onRegistrSpinner = false
      //     // redirect to company workspace login page
      //     console.info('todo - redirect to company workspace login page')
      //     this._router.navigate(['login'])
      //   })
    }
  }

  togglePasswVisibility(isVisible: boolean) {
    this.isPasswordVisible = isVisible
  }
}
