export class IAuthUser {
  authId: string
  id: string
  name: string
  responsibility: string
  roles: string[]
  phone: string
  timeZone: string
  avatar: string
  position: string
  positionDescript: string
  companyName: string
  orgId: string
}
