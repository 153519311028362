import { Component, Input, OnInit } from '@angular/core'
import * as models from '../../../../../core/models'
import { NotificationService } from '../../../../../core/services'

@Component({
  selector: 'ef-simple-notification-item',
  templateUrl: './simple-notification-item.component.html',
  styleUrls: ['./simple-notification-item.component.scss']
})
export class SimpleNotificationItemComponent implements OnInit {
  @Input() notification: models.IAppNotificationSimple

  constructor(private _notificationService: NotificationService) {}

  ngOnInit() {}

  close() {
    this._notificationService.deleteNotificPush(this.notification.id)
  }
}
