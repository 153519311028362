import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ACTION_ROUTES, MAIN_APP_ROUTES } from '../../../core/enum'
/*import { ACTION_ROUTES, MAIN_APP_ROUTES } from '@/app/core/enum'*/

@Component({
  selector: 'ef-processes-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class ProcessesEmptyListComponent implements OnInit {
  redirectUrl: string
  constructor(protected router: Router) {}

  ngOnInit() {
    this.redirectUrl = '/' + MAIN_APP_ROUTES.CLIENT + '/' + ACTION_ROUTES.ACTION_LAUNCH_LIST
  }
}
