import { NavigationItem } from '../../../core/models/navigation/navigation-items'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as enumsCore from '../../../../app/core/enum'
import * as enumsProcesses from '@/app/modules/processes/enum'
import { NavigationTogglingService } from '../../../core/services/navigation/navigation-toggling.service'

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(270deg)' })),
      state('expanded', style({ transform: 'rotate(360deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
    ])
  ]
})
export class NavigationComponent implements OnInit {
  expanded: boolean = false
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded
  @Input() item: NavigationItem
  @Input() depth: number

  constructor(public navService: NavigationTogglingService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.includes(this.item.route)
        this.ariaExpanded = this.expanded
      }
    })
  }

  onItemSelected(item: NavigationItem) {
    if (!item.children || !item.children.length) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false
      this.router.onSameUrlNavigation = 'reload'
      if (item.route) {
        if (
          item.route.includes(enumsCore.ACTION_ROUTES.ACTION_EDIT_LIST) &&
          !item.route.includes(enumsProcesses.ACTION_BUILD_TAB_LIST.WORKFLOW_BUILDER)
        ) {
          this.router.navigate([item.route, Math.abs(item.id)], { state: { id: item.id } })
        } else this.router.navigate([item.route])
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded
      if (item.route?.includes(enumsCore.ACTION_ROUTES.ACTION_EDIT_LIST)) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false
        this.router.onSameUrlNavigation = 'reload'
        if (this.depth > 0) this.router.navigate([item.route, Math.abs(item.id)], { state: { id: item.id } })
        else this.router.navigate([item.route], { state: { id: item.id } })
      }
    }
  }

  setParentDisplacement() {
    let displacement = this.depth <= 1 ? 10 : 25
    return this.depth * displacement + 'px'
  }
  setChildDisplacement() {
    let displacement =
      this.item.children?.length > 0 ? (this.depth <= 1 ? 25 : 10) : this.item.children?.length === 0 ? 50 : 15
    return this.depth * displacement + 'px'
  }

  isActive(itemRoute: string): boolean {
    if (
      itemRoute.includes(enumsCore.ACTION_ROUTES.ACTION_EDIT_LIST) &&
      !itemRoute.includes(enumsProcesses.ACTION_BUILD_TAB_LIST.WORKFLOW_BUILDER)
    ) {
      if (this.depth > 0) {
        itemRoute = itemRoute.concat('/' + Math.abs(this.item.id))
      }
    }
    return this.router.isActive(itemRoute, true)
  }
}
