export class LeftNavDivision {
  index: number
  title: string
  controls: string[]
}
export class LeftNavConfigData {
  title: string
  icon: string
}
export class LeftNavConfig {
  // tabs: LeftNavConfigData;
  // button: LeftNavConfigData;
  section: LeftNavConfigData
  repeater: LeftNavConfigData
  divider: LeftNavConfigData
  spacer: LeftNavConfigData
  anchorLink: LeftNavConfigData
  title: LeftNavConfigData
  input: LeftNavConfigData
  textArea: LeftNavConfigData
  checkbox: LeftNavConfigData
  radio: LeftNavConfigData
  dropdown: LeftNavConfigData
  dateCompare: LeftNavConfigData
  datePicker: LeftNavConfigData
  imageAttachment: LeftNavConfigData
  embedVideo: LeftNavConfigData
  fileAttachment: LeftNavConfigData
}
