import { Component, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-notific-settings',
  templateUrl: './notific-settings.component.html',
  styleUrls: ['./notific-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  isFirstBubble = true

  @Output() close = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onDelete() {
    this.close.emit()
  }
  onEmail() {
    this.close.emit()
  }
  onShare() {
    this.close.emit()
  }

  onActionOutside() {
    if (!this.isFirstBubble) {
      this.close.emit()
    }
    this.isFirstBubble = false
  }
}
