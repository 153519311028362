// lib
import { IDepartment, IRoleName, IUser, IUserRolePermission } from '@/app/shared/models'
import { Action } from '@ngrx/store'
// app
import { ITeamDepartment, ITeamPosition, ITeamRole } from '../../models'

export enum TeamActionTypes {
  // department
  SetDepartmentMemberList = '[Department] Set Department Member List',
  SetDepartmentList = '[Department] Set Department List',
  AddDepartment = '[Department] Add Department',

  SetRoleMemberList = '[Roles] Set Role Member List',
  SetRoleList = '[Roles] Set Role List',

  // position
  AddPosition = '[Position] Add Position',

  // member
  SetCurrUserProfile = '[Members] Set Current UserProfile',
  SetMemberList = '[Members] Set Member List',
  SetRolePermissionList = '[Permissions] Set Permission List'
}

// department
export class SetDepartmentMemberList implements Action {
  readonly type = TeamActionTypes.SetDepartmentMemberList
  constructor(public payload: { list: ITeamDepartment[] }) {}
}

export class SetDepartmentList implements Action {
  readonly type = TeamActionTypes.SetDepartmentList
  constructor(public payload: { list: IDepartment[] }) {}
}

export class SetRoleMemberList implements Action {
  readonly type = TeamActionTypes.SetRoleMemberList
  constructor(public payload: { list: ITeamRole[] }) {}
}

export class SetRoleList implements Action {
  readonly type = TeamActionTypes.SetRoleList
  constructor(public payload: { list: IRoleName[] }) {}
}

export class SetMemberList implements Action {
  readonly type = TeamActionTypes.SetMemberList
  constructor(public payload: { list: IUser[] }) {}
}
export class AddDepartment implements Action {
  readonly type = TeamActionTypes.AddDepartment
  constructor(public payload: ITeamDepartment) {}
}

// position
export class AddPosition implements Action {
  readonly type = TeamActionTypes.AddPosition
  constructor(public payload: { departmentId: string; position: ITeamPosition }) {}
}

export class SetRolePermissionList implements Action {
  readonly type = TeamActionTypes.SetRolePermissionList
  constructor(public payload: { list: IUserRolePermission[] }) {}
}

// member
export class SetCurrUserProfile implements Action {
  readonly type = TeamActionTypes.SetCurrUserProfile
  constructor(public payload: IUser) {}
}

export type TeamActions =
  // department
  | AddDepartment
  | SetDepartmentMemberList
  | SetDepartmentList
  | SetRoleMemberList
  | SetRoleList
  | SetRolePermissionList
  // position
  | AddPosition

  // member
  | SetCurrUserProfile
  | SetMemberList
