// app
import { Component, OnInit, Output, EventEmitter } from '@angular/core'

// libs
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'ef-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss'],
})
export class ImgCropperComponent implements OnInit {
  file: File
  imageChangedEvent: any = ''
  croppedFile: File
  croppedImage: any = ''

  @Output() croppedResult: EventEmitter<{ file: File; base64: string }> = new EventEmitter()

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.croppedFile = new File([event.file], 'picture')
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  save() {
    this.croppedResult.emit({
      file: this.croppedFile,
      base64: this.croppedImage,
    })
    this.activeModal.close()
  }

  cancel() {
    this.activeModal.close()
    this.croppedResult.emit()
  }
}
