// ang
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ISearchGlobalFilterItem, ISearchGlobalResultUser } from '../../../../../core/models'
// lib
// app
import { SearchGlobalHttpService } from '../../../../../core/services'

@Component({
  selector: 'ef-search-global-results-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class SearchGlobalResultsUsersComponent implements OnInit, OnChanges {
  @Input() searchValue: string

  departmentList: ISearchGlobalFilterItem[] = []
  roleNamesList: ISearchGlobalFilterItem[] = []
  userList: ISearchGlobalResultUser[] = []
  throttle: any[] = []
  selectedDepartmentIdList: string[]
  selectedRoleNamesList: string[]

  constructor(private _httpService: SearchGlobalHttpService) {}

  ngOnInit() {
    this._getDepartmentListAsync()
    this._getRolesListAsync()
    this._updateView()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchValue && !changes.searchValue.firstChange) {
      this._updateViewWithDelay(1000)
    }
  }

  setSelectedDepartmentIdList(departmentIdList: string[]) {
    this.selectedDepartmentIdList = departmentIdList
    this._updateViewWithDelay(1000)
  }

  setSelectedRoleNamesList(roleNamesList: string[]) {
    this.selectedRoleNamesList = roleNamesList
    this._updateViewWithDelay(1000)
  }

  private _updateViewWithDelay(delayMs: number) {
    this.throttle.forEach(func => clearTimeout(func))
    const delay = setTimeout(() => {
      this._updateView()
    }, delayMs)
    this.throttle.push(delay)
  }

  private _updateView() {
    this._httpService
      .getUserList({
        searchValue: this.searchValue,
        departmentIdList: this.selectedDepartmentIdList,
        roleNamesList: this.selectedRoleNamesList
      })
      .subscribe(res => {
        this.userList = res
      })
  }

  private _getRolesListAsync() {
    this._httpService.getTeamList().subscribe(res => {
      this.roleNamesList = res.map(f => {
        return {
          id: f.id,
          name: f.name,
          isSelected: false
        }
      })
    })
  }

  private _getDepartmentListAsync() {
    this._httpService.getFolderList().subscribe(res => {
      if (res && Array.isArray(res)) {
        this.departmentList = res.map(f => {
          return {
            id: f.id,
            name: f.name,
            isSelected: false
          }
        })

        this._sortByAlphabit(this.departmentList)
      }
    })
  }

  private _sortByAlphabit(list: ISearchGlobalFilterItem[]) {
    list.sort(function(a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }
}
