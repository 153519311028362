// ang
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppAuthGuard } from '../app.authguard'
import { AppPermissionGuard } from '../app.roleguard'
import * as molecule from '../components/molecules'
// app
import * as organism from '../components/organisms'
import { CORE_CONFIG } from './core.config'
import * as enums from './enum'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: enums.MAIN_APP_ROUTES.DASHBOARD
  },
  {
    path: 'login',
    component: AppAuthGuard,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'invite/:id',
    component: organism.InviteComponent
  },
  {
    path: 'recovery',
    component: organism.RecoveryComponent
  },
  {
    path: 'registration',
    component: organism.RegistrationComponent,
    canActivate: [AppPermissionGuard],
    data: { permissions: [CORE_CONFIG.MAP_PERMISSIONS.User_Management.Locked_Out_Register_User] }
  },
  {
    path: 'notifications',
    component: molecule.NotificFullListComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'profile-auth',
    component: organism.ProfileAuthComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'terms-of-use',
    component: molecule.TermsOfUseComponent
  },
  {
    path: 'unauthorized',
    component: organism.UnauthorizedComponent
  },
  // MODULES

  {
    path: enums.MAIN_APP_ROUTES.DASHBOARD,
    loadChildren: () => import('../../app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: enums.MAIN_APP_ROUTES.PROCESSES,
    loadChildren: () => import('../../app/modules/processes/processes.module').then(m => m.ProcessesModule),
    canActivate: [AppAuthGuard],
    canLoad: [AppPermissionGuard],
    data: { permissions: [CORE_CONFIG.MAP_PERMISSIONS.Processes.Create_Edit_Process] }
  },
  {
    path: enums.MAIN_APP_ROUTES.CLIENT,
    loadChildren: () => import('../../app/modules/processes/processes.module').then(m => m.ProcessesModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: enums.MAIN_APP_ROUTES.MY_WORK,
    loadChildren: () => import('../../app/modules/my-work/my-work.module').then(m => m.MyWorkModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: enums.MAIN_APP_ROUTES.TEAM,
    loadChildren: () => import('../../app/modules/team/team.module').then(m => m.TeamModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: enums.MAIN_APP_ROUTES.COMPANY_SETTINGS,
    loadChildren: () =>
      import('../../app/modules/company-settings/company-settings.module').then(m => m.CompanySettingsModule),
    canActivate: [AppAuthGuard],
    canLoad: [AppPermissionGuard],
    data: { permissions: [CORE_CONFIG.MAP_PERMISSIONS.Tenant.Site_Config] }
  },
  {
    path: '**',
    component: organism.PageNotFoundComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AppAuthGuard, AppPermissionGuard],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
