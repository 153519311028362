export class SelectControlItem {
  id?: number
  index?: number
  name: string
  value: string
  title?: string
  parentListItemId?: string
  // additionalData?: any; // it can be any additional data, simple value, object, array that need in specific context
  group?: string // for groupBy
}
