import { Control } from './control.model'
import { PageBehaviour } from './page-behaviour.model'

export class Section {
  id: string
  type: string // 'section' || 'repeater'
  pageBehaviours: PageBehaviour[]
  settings: SectionSettings
  layouts?: SectionLayout[]
  repeaterItems?: IRepeaterItem[]
}

export class SectionLayout {
  id: string
  value: string
  controls: Control[]
}

export class IRepeaterItem {
  id: string
  layouts: SectionLayout[]
}

export class SectionSettings {
  title: string
  showTitle: boolean
  layoutType: string
  isShow: boolean
  isDisabled: boolean

  // repeater
  repeaterAmount?: number // amount of repeats
  repeaterRow?: number // row length of each repeat item
  repeaterCol?: number
  allowNewRepeats?: boolean
}
