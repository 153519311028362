import { DataSettingItem } from './control.model'
import { CONFIRM_DIALOG_ACTIONS } from '../../../shared/enum/index'

export class PageBehaviour {
  id: string
  isNew?: boolean
  displayName: string
  type: string
  conditions: PageBehaviourCondition[]
  actions: PageBehaviourAction[]
  calculations: PageBehaviourCalculation[]
  enableInverseActions: boolean
  isDisabled: boolean
  sourceControlType?: string
}

export class PageBehaviourConditionCheckbox {
  itemId: string
  name: string
  isChecked: boolean
}

export class PageBehaviourCondition {
  id: string
  booleanOperator: string
  controlId: string
  comparison: string
  value: string
  controlType: string
  dataType: string
  dateCompareRangeType?: string // from date || to date
  checkboxConditionList?: PageBehaviourConditionCheckbox[]
}

export class PageBehaviourAction {
  id: string
  actionType: string
  performInverseAction: boolean
  itemId: string
  targetType: string
  targetItemId: string[]
  fromControlId: string
  value: string
  valueList: DataSettingItem[]
}

export class PageBehaviourCalculation {
  id: string
  control: any
  actionType: string
  set: any[]
}

export interface IPageBehaviourCalculation {
  confirmationDialogAction?: CONFIRM_DIALOG_ACTIONS
  pageBehaviourCalculation?: PageBehaviourCalculation
}
