import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ef-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input() isOn: boolean = false
  @Input() disabled: boolean = false
  @Input() viewType: string = 'square' // square || circle

  disableAnimation: boolean = true

  @Output() valueChanges: EventEmitter<boolean> = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onChangeValue() {
    if (!this.disabled) {
      this.disableAnimation = false
      this.valueChanges.emit(!this.isOn)
    }
  }
}
