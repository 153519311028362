export enum SAVE_CHANGES_ACTIONS {
  SAVE = 'save',
  DONT_SAVE = 'dontSave',
  CANCEL = 'cancel',
}

export enum SNACK_BAR_TYPE {
  SUCCESS = 'success-snack-bar',
  ERROR = 'error-snack-bar',
  INFO = 'info-snack-bar',
  WARNING = 'warning-snack-bar',
  DEFAULT = 'default-snack-bar'
}
