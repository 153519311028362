// ang
import { Component, OnInit } from '@angular/core'

// app

@Component({
  selector: 'ef-notific-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class NotificEmptyListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
