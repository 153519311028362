import { Injectable } from '@angular/core'
import * as $ from 'jquery'
import { DeviceDetectorService } from 'ngx-device-detector'

@Injectable({
  providedIn: 'root'
})
export class AutoScrollService {
  constructor(private _deviceDetectorService: DeviceDetectorService) {}

  //
  disableScrollForTouchScreenDevices(element?: any) {
    if (this._deviceDetectorService.isTablet() || this._deviceDetectorService.isMobile()) {
      if (element) {
        $(element).addClass('ef-disable-scroll')
      } else {
        $('body').addClass('ef-disable-scroll')
        $('html').addClass('ef-disable-scroll')
        $('.js-ef-scroll-container').removeClass('ef-disable-scroll')
      }
    }
  }
  enableScrollForTouchScreenDevices(element?: any) {
    if (this._deviceDetectorService.isTablet() || this._deviceDetectorService.isMobile()) {
      if (element) {
        $(element).removeClass('ef-disable-scroll')
      } else {
        $('body').removeClass('ef-disable-scroll')
        $('html').removeClass('ef-disable-scroll')
        $('.js-ef-scroll-container').removeClass('ef-disable-scroll')
      }
    }
  }
}
