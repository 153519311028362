/*import { environment } from '@/environments/environment'*/
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../core/services'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  loginUrl = environment.serverUrl + '/login'

  constructor(private _router: Router, public authService: AuthService) {}

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this._router.navigate(['login'])
      //window.location.href = this.loginUrl
    }
  }
}
