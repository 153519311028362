import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Component({
  selector: 'ef-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input() type: string = 'simple' // 'simple'(small circle) || 'pretty'(from form controls)
  @Input() title: string
  @Input() value: any
  @Input() currValue: any
  @Input() disabled: boolean = false
  @Input() cssClass: string

  @Input() formCtrl: AbstractControl

  @Output() valueChanged: EventEmitter<any> = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  change() {
    if (this.valueChanged) {
      this.valueChanged.emit(this.value)
    }
    if (this.formCtrl) {
      this.formCtrl.setValue(this.value)
    }
  }

  ngOnDestroy() {}
}
