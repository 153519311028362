import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[efClickStopPropagation]',
})
export class PropagationStopDirective {
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation()
  }
}
