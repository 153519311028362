// ang
import { Component, OnInit, EventEmitter, ElementRef, Input, Output, SimpleChanges } from '@angular/core'
import { AbstractControl, FormControl, Validators, FormGroup } from '@angular/forms'

// libs
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader'

// app
import { UtilsService } from '../../../shared/services/'
import { Control } from '../../../modules/processes/models/'

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @Input() btnTitle: string = 'upload'
  @Input() control: Control
  @Input() acceptedType = 'image'
  @Input() imageUrl = ''
  @Input() parentForm: FormGroup
  @Input() controlName: string
  @Input() disabled: boolean = false
  @Input() required: boolean = false
  @Input() showErrorStyles: boolean = false
  @Input() onDropZone: boolean = true
  // TODO - another validation fields (size, type)

  // form
  abstractControl: AbstractControl
  formControlName: string
  form: FormGroup
  formControl: FormControl = new FormControl()

  // data
  showSpinner: boolean = false
  options: UploaderOptions
  formData: FormData
  files: UploadFile[]
  uploadInput: EventEmitter<UploadInput>
  humanizeBytes: Function
  dragOver: boolean

  @Output() uploadFile: EventEmitter<File> = new EventEmitter()

  constructor(public elementRef: ElementRef, private _utilsService: UtilsService) {
    this.options = { concurrency: 1, maxUploads: 3 }
    this.files = [] // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>() // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes
  }

  ngOnInit() {
    if (this.parentForm) {
      this.formControlName = this.controlName || 'fileUploader_' + this._utilsService.generateUniqueId()
      const validators = [this.required ? Validators.required : null].filter(validator => validator)
      this.formControl = new FormControl(undefined, validators)
      this.parentForm.addControl(this.formControlName, this.formControl)
      this.abstractControl = this.parentForm.controls[this.formControlName]
      if (this.disabled) {
        this.abstractControl.disable()
      }
    } else {
      if (this.disabled) {
        this.formControl.disable()
      }
    }
    if (this.acceptedType === 'image') {
      this.options.allowedContentTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp']
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && !changes.disabled.firstChange) {
      const formControl = this.parentForm ? this.abstractControl : this.formControl
      changes.disabled.currentValue ? formControl.disable() : formControl.enable()
    }
  }

  ngOnDestroy() {
    if (this.parentForm) {
      this.parentForm.removeControl(this.formControlName)
    }
  }

  onUploadOutput(output: UploadOutput): void {
    if (!this.disabled) {
      switch (output.type) {
        case 'allAddedToQueue':
          const event: UploadInput = {
            type: 'uploadAll',
            url: 'https://httpbin.org/post',
            method: 'POST',
            data: { foo: 'bar' },
          }
          this.uploadInput.emit(event)
          break
        case 'addedToQueue':
          if (typeof output.file !== 'undefined') {
            this.files.push(output.file)
          }
          break
        case 'uploading':
          if (typeof output.file !== 'undefined') {
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id)
            this.files[index] = output.file
          }
          break
        case 'removed':
          this.files = this.files.filter((file: UploadFile) => file !== output.file)
          break
        case 'dragOver':
          this.dragOver = true
          break
        case 'dragOut':
        case 'drop':
          this.dragOver = false
          break
        case 'start':
          this.showSpinner = true
          break
        case 'done':
          this.showSpinner = false
          this.uploadFile.emit(output.file.nativeFile)
          break
        default:
          this.showSpinner = false
          break
      }
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: 'https://httpbin.org/post',
      method: 'POST',
      data: { foo: 'bar' },
    }
    this.uploadInput.emit(event)
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id })
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id })
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' })
  }
}
