import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'ef-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() title: string
  @Input() value: boolean = false
  @Input() disabled: boolean = false
  @Input() disableActions: boolean = false // if true - than all clicks by checkbox will not change status of checkbox inside, all changes have to be maked outside by attribute [value]

  // for checkbox list
  @Input() formArray: FormArray
  @Input() formArrayIndex: number

  // one checkbox
  @Input() parentForm: FormGroup
  @Input() controlName: string

  formControl: FormControl
  abstractControl: AbstractControl

  @Output() changeStatus: EventEmitter<boolean> = new EventEmitter()

  constructor() {}

  ngOnInit() {
    this.formControl = new FormControl(this.value)
    // single checkbox
    if (this.parentForm && this.controlName) {
      this.parentForm.addControl(this.controlName, this.formControl)
      this.abstractControl = this.parentForm.controls[this.controlName]
      // checkbox list
    } else if (this.formArray && typeof this.formArrayIndex === 'number') {
      this.formControl = new FormControl(this.value)
      this.formArray.setControl(this.formArrayIndex, this.formControl)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && !changes.value.firstChange) {
      this.formControl.setValue(changes.value.currentValue)
    }
  }

  change() {
    if (!this.disableActions) {
      const val = !this.formControl.value
      if (this.changeStatus) {
        this.changeStatus.emit(val)
      }
      this.formControl.setValue(val)
    }
  }

  ngOnDestroy() {
    if (this.parentForm && this.controlName) {
      this.parentForm.removeControl(this.controlName)
    } else if (this.formArray && typeof this.formArrayIndex === 'number') {
      this.formArray.removeAt(this.formArrayIndex)
    }
  }
}
