import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AuthService } from '../services'

@Injectable({
  providedIn: 'root'
})
export class OrganisationIdInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const orgId = this.authService.getOrgId()
    const modifiedRequest = request.clone({
      setHeaders: {
        'X-Org': orgId
      }
    })

    return next.handle(modifiedRequest)
  }
}
