// ang
import { Component, Input, OnInit } from '@angular/core'
// app
import { CORE_CONFIG } from '../../../../../core/core.config'
import { ISearchGlobalHintItem, ISearchGlobalHintType } from '../../../../../core/models'

@Component({
  selector: 'ef-search-global-hint-item',
  templateUrl: './hint-item.component.html',
  styleUrls: ['./hint-item.component.scss']
})
export class SearchGlobalHintItemComponent implements OnInit {
  @Input() searchValue: string
  @Input() hint: ISearchGlobalHintItem

  config: ISearchGlobalHintType

  constructor() {}

  ngOnInit() {
    this.config = CORE_CONFIG.SEARCH_GLOBAL_HINT_TYPE_LIST?.filter(item => item.value === this.hint.type)[0]
  }
}
