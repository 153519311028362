import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'ef-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // const routeParams = this._activatedRoute.snapshot
    // const id = routeParams.companyId;
  }
}
