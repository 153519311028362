// import { environment } from '@/environments/environment'
import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'ef-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  submitted: boolean = false
  form: FormGroup

  // loginUrl = environment.serverUrl + '/login'

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({})
  }
}
