// ang
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
// app
import { SHARED_CONFIG } from '../../../shared/shared.config'

@Component({
  selector: 'ef-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() base64: string
  @Input() source: string
  @Input() name: string
  @Input() isRound: boolean = true

  initials: string
  isLightType: boolean = false
  defaultBgColor: string

  constructor() {}

  ngOnInit() {
    this._initAvatar()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.source && !changes.source.firstChange) {
      this._initAvatar()
    }
  }

  private _initAvatar() {
    if (!this.source && this.name) {
      this._initDefaultAvatar()
    }
  }

  private _initDefaultAvatar() {
    this._initInitials()
    this._initBackgroundColor()
  }

  private _initInitials() {
    let initials = ''
    const arr = this.name.split(' ')
    arr.forEach(word => {
      initials += word.charAt(0)
    })
    if (initials.length > 0) {
      if (initials.length > 4) {
        this.initials = initials.substring(0, 3)
      } else {
        this.initials = initials
      }
    }
  }

  private _initBackgroundColor() {
    const firstInitial = this.initials?.charAt(0).toLowerCase()
    let color
    SHARED_CONFIG.AVATAR_BG_COLORS.some(colorConfig => {
      if (colorConfig.charachters.indexOf(firstInitial) > -1) {
        color = colorConfig.color
        return true
      }
    })
    if (color) {
      this.defaultBgColor = color
    } else {
      this.isLightType = true
      this.defaultBgColor = SHARED_CONFIG.AVATAR_BG_COLORS_OTHER
    }
  }
}
