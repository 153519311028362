import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'ef-input-title',
  templateUrl: './input-title.component.html',
  styleUrls: ['./input-title.component.scss'],
})
export class InputTitleComponent implements OnInit {
  @Input() title: string
  @Input() required: boolean = false // todo - replace input to top level define class (like with _disable)

  constructor() {}

  ngOnInit() {}
}
