import { AssignedUser } from '@/app/modules/dashboard/models'

export class IAdminProcessItem {
  processId: string
  processTemplateName: string
  processUniqueName: string
  versionNo: number
  isMarked: boolean
  activeStageName: string
  activeStageId: string
  activeStageDeadline: string
  assignedUser: AssignedUser
}
