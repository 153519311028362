// ang
import { Component, OnInit, Output, EventEmitter } from '@angular/core'

// lib
import { Subscription } from 'rxjs'

// app
import { IAppNotification } from '../../../../core/models'
import { NotificationService, StoreNotificationsService } from '../../../../core/services'

@Component({
  selector: 'ef-notific-dropdown',
  templateUrl: './notific-dropdown.component.html',
  styleUrls: ['./notific-dropdown.component.scss'],
})
export class NotificDropdownComponent implements OnInit {
  showMarkAllSpinner: boolean = false
  showInitSpinner: boolean = false
  showMoreSpinner: boolean = false
  timestamp: string
  notificList = []
  subNotificList: Subscription

  @Output() close: EventEmitter<any> = new EventEmitter()

  constructor(private _notificService: NotificationService, private _storeService: StoreNotificationsService) {}

  ngOnInit() {
    this._notificService.isDropdownListOpen = true
    this.subNotificList = this._storeService.getNotificDropdownList().subscribe(list => {
      this.notificList = list
      this._sortListByDate(this.notificList)
      this._setTimestamp(list)
    })
    if (this.notificList.length === 0) {
      this.getNotificListAsync(true)
    }
  }

  ngOnDestroy() {
    this._storeService.actionSetNotificDropdownList([])
    this._notificService.isDropdownListOpen = false
  }

  private _sortListByDate(list: IAppNotification[]) {
    if (list) {
      list.sort(function(a, b) {
        const aMs = new Date(a.createdAt).getTime()
        const bMs = new Date(b.createdAt).getTime()
        if (aMs < bMs) {
          return 1
        }
        if (aMs > bMs) {
          return -1
        }
        return 0
      })
    }
  }

  getNotificListAsync(isInit: boolean) {
    if (isInit) {
      this.showInitSpinner = true
    } else {
      this.showMoreSpinner = true
    }

    this._notificService
      .getNotificListAsync({
        amount: 10,
        timestamp: this.timestamp,
      })
      .subscribe((res: IAppNotification[]) => {
        if (isInit) {
          this._storeService.actionSetNotificDropdownList(res)
          this._notificService.newNotificAmount = 0
        } else {
          this._storeService.actionAddNotificDropdownList(res)
        }
        this.showInitSpinner = false
        this.showMoreSpinner = false
      })
  }

  loadMore() {
    this.getNotificListAsync(false)
  }

  markAllIsRead() {
    this.showMarkAllSpinner = true
    this._notificService.setAllNotificIsReadAsync().subscribe(res => {
      this.showMarkAllSpinner = false
      this._storeService.actionSetAllNotificIsRead()
    })
  }

  private _setTimestamp(list: IAppNotification[]) {
    list.forEach(item => {
      if (!this.timestamp || new Date(item.createdAt).getTime() < new Date(this.timestamp).getTime()) {
        this.timestamp = item.createdAt
      }
    })
  }
}
