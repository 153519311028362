import { IClientOverviewActivityItemLog } from './client-overview-activity-item-log.model'

export class IClientOverviewActivityItem {
  id: string

  // 'created task', assigned task
  actionType: string

  // user (that created task, do reassign)
  initiatorId: string
  initiatorName: string
  initiatorAvatar: string

  // user that will execute task
  executorId: string
  executorName: string
  executorAvatar: string

  lastActivityDate: string

  // logs
  logs: IClientOverviewActivityItemLog[]
}
