export enum MAIN_APP_ROUTES {
  PROCESSES = 'process',
  CLIENT = 'client',
  DASHBOARD = 'dashboard',
  MY_WORK = 'my-work',
  TEAM = 'team',
  COMPANY_SETTINGS = 'company-settings'
}

// ACTIONS (PROCESSES)

export enum ACTION_ROUTES {
  ACTION_LAUNCH_LIST = 'launch',
  ACTION_EDIT_LIST = 'editprocess',
  ACTION_MY_WORK = 'my-work',
  ACTION_OVERVIEW = 'overview',
  ACTION_LAUNCH_START = 'launch-start',
  CLIENT_EDIT = 'edit',
  CLIENT_HISTORY = 'history'
}

// DASHBOARD

export enum DASHBOARD_ROUTES {}

// MY WORK

export enum MY_WORK_ROUTES {}

// TEAM

export enum TEAM_ROUTES {}

// COMPANY SETTINGS

export enum COMPANY_SETTINGS_ROUTES {}
