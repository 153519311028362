// ang
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterModule } from '@angular/router'
// lib
import { StoreModule } from '@ngrx/store'
// components
import * as atom from '../components/atoms'
import * as molecule from '../components/molecules'
import * as organism from '../components/organisms'
import { SharedModule } from '../shared/shared.module'
import { CoreRoutingModule } from './core-routing.module'
import { CanDeactivateGuard } from './guards'
import * as interceptors from './interceptors'
import * as services from './services'
import * as fromCore from './states/'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreRoutingModule,
    SharedModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    StoreModule.forFeature('coreState', fromCore.reducers, {
      metaReducers: fromCore.metaReducers
    })
  ],

  declarations: [
    molecule.FooterComponent,

    // auth
    // organism.LoginComponent,
    organism.RecoveryComponent,
    organism.RegistrationComponent,
    // organism.AuthCallbackComponent,
    organism.InviteComponent,

    organism.PageNotFoundComponent,
    organism.UnauthorizedComponent,
    organism.SearchGlobalInputComponent,
    organism.SearchGlobalHintsComponent,
    organism.SearchGlobalHintItemComponent,
    organism.SearchGlobalResultsComponent,
    organism.SearchGlobalResultsProcessesComponent,
    organism.SearchGlobalResultsActionsComponent,
    organism.SearchGlobalResultsUsersComponent,
    organism.SearchGlobalResultsProcessItemComponent,
    organism.SearchGlobalFilterSelectorComponent,
    organism.SearchGlobalResultsUserItemComponent,
    molecule.NavigationComponent,
    organism.ProfileAuthComponent,
    organism.ProfileAuthOverviewComponent,
    organism.ProfileAuthStatisticsComponent,
    molecule.NotificFullListComponent,
    molecule.NotificItemComponent,
    molecule.NotificationSettingsComponent,
    molecule.NotificDropdownComponent,
    molecule.NotificPushListComponent,
    organism.NotificationItemComponent,
    organism.SimpleNotificationItemComponent,
    organism.SnackbarComponent,
    molecule.NotificEmptyListComponent,
    atom.ErrorComponent,
    molecule.TermsOfUseComponent

    // processesComponents.ProcessHeaderPanelComponent,
    // // folders
    // processesComponents.ActionsComponent,
    // processesComponents.ActionComponent,
    // processesComponents.ActionNavComponent,
    // processesComponents.CategoryCreateComponent,
    // processesComponents.FolderInsideComponent,
    // processesComponents.FolderNavComponent,
    // processesComponents.FolderSettingsComponent,
    // processesComponents.FolderMoveComponent,
    // processesComponents.FolderEditComponent,
    // processesComponents.FolderDeleteComponent,
    // processesComponents.ActionNavSettingsComponent,

    // pipes
  ],

  exports: [
    RouterModule,
    organism.SearchGlobalInputComponent,
    molecule.NavigationComponent,
    molecule.NotificDropdownComponent,
    molecule.NotificItemComponent,
    molecule.NotificPushListComponent,
    organism.NotificationItemComponent,
    organism.SimpleNotificationItemComponent,
    organism.SnackbarComponent
  ],

  providers: [
    // services
    services.HttpErrorHandler,
    services.SearchGlobalHttpService,
    services.ErrorService,
    services.NotificationService,
    services.StoreNotificationsService,
    services.AuthService,
    // interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.OrganisationIdInterceptor,
      multi: true
    },
    //TODO look at this
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: interceptors.OcpApimKeyInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.CorsInterceptor,
      multi: true
    },
    CanDeactivateGuard
    /*
     processesServices.StoreActionsService,
     processesServices.ProcessService,
     processesServices.UtilsProcessesService,
     processesServices.RoutingService,
    */
  ],

  entryComponents: [organism.SearchGlobalResultsComponent]
})
export class CoreModule {}
