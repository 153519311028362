import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Event, NavigationEnd, Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class NavigationTogglingService {
  public currentUrl = new BehaviorSubject<string>(undefined)
  isOpen = true
  showBackground = false

  constructor(private _router: Router) {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects)
      }
    })
  }

  hide() {
    setTimeout(() => {
      this.isOpen = false
      this.showBackground = false
    })
  }

  show(showBg: boolean) {
    setTimeout(() => {
      this.isOpen = true
      this.showBackground = showBg
    })
  }
}
