// ang
import { Component, Input, OnInit } from '@angular/core'
import { IDeltaTime } from '../../../shared/models/'
// lib
// app
import { UtilsService } from '../../../shared/services'

@Component({
  selector: 'ef-passed-time',
  templateUrl: './passed-time.component.html',
  styleUrls: ['./passed-time.component.scss']
})
export class PassedTimeComponent implements OnInit {
  @Input() time: string

  daysAm: number
  hoursAm: number
  minutesAm: number
  secondsAm: number
  interval: any

  constructor(private _utilsService: UtilsService) {}

  ngOnInit() {
    this._init()
    this.interval = setInterval(() => {
      this._init()
    }, 60 * 1000)
  }

  _init() {
    const dayMs = 24 * 60 * 60 * 1000
    const now = new Date().getTime()
    const time = new Date(this.time).getTime()
    const delta = now - time
    const prettyDelta: IDeltaTime = this._utilsService.getPrettyPassedTime(delta)
    this.daysAm = prettyDelta.days
    this.hoursAm = prettyDelta.hours
    this.minutesAm = prettyDelta.minutes
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }
}
