import { ISearchInputFilter } from '@/app/shared/interfaces/search-input-filter.interface';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ef-search-input-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class SearchInputFilterComponent implements OnInit {

  public filter: ISearchInputFilter
  @Output() output = new EventEmitter<ISearchInputFilter>();
  
  constructor() {
    this.onReset()
  }

  ngOnInit(): void {

  }

  public changeSpendTime(value) {
    this.filter.currentValue = value
  }

  public onChangeStatus(event, name){
    const headers: Array<Object> = Object.keys(this.filter.status).map(key => {
      return { name: key, value: !event }
    });
    headers.map(item => {
       this.filter.status[item['name']] = item['name'] == name
    })
  }

  public onSave(){
    this.output.emit(this.filter)
  }

  public onReset(){
    this.filter = {
      status: {isAll: true, isActive: false, isDraft: false},
      spendTime: [{ value: 'days',  title: 'Days'}, { value: 'months',  title: 'Months'}, { value: 'years',  title: 'Years'}],
      currentValue: 'days',
      lowValue: 5,
      highValue: 20,
      options: {floor: 0, ceil: 50}
    }
  }
}
