// ang
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
// app
import { UtilsService } from '../../../shared/services'
import { SHARED_CONFIG } from '../../../shared/shared.config'

@Component({
  selector: 'ef-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  // main data
  @Input() type: string = 'input' //input || textarea
  @Input() dataType: string = 'text' // text || number || password
  @Input() disabled: boolean
  @Input() readonly: boolean = false // special for ngx-clipboard (not working with disable)
  @Input() placeholder: string
  @Input() isSmall: boolean
  @Input() leftIconClass: string // left icon (css class - fa or custom icons)
  @Input() isPasswordVisible: boolean = false // toggle passwword visibility for confirmation password input

  // form
  @Input() parentForm: FormGroup
  @Input() submitted: boolean
  @Input() controlName: string
  @Input() initValue: any

  // validation
  @Input() min: number
  @Input() max: number
  @Input() maxLength: number
  @Input() minLength: number
  @Input() required: boolean
  @Input() pattern: string
  @Input() showErrorStyles: boolean = false

  // functionality
  @Input() showClipboard: boolean = false
  @Input() clipboardTitle: string = 'copy'
  @Input() clipboardPrefix: any

  @Input() cssClass: string

  form: FormGroup
  formControl: FormControl = new FormControl()
  abstractControl: AbstractControl
  isOutsideChange: boolean = false
  isFocused: boolean = false
  isPasswVisible: boolean = false
  inputType: string // cash input type for toggling password visibility
  onClipboard: boolean = false

  @Output() onPasswordVisibility: EventEmitter<boolean> = new EventEmitter()
  @Output() changeValue: EventEmitter<any> = new EventEmitter()
  @Output() keyUpEnter: EventEmitter<any> = new EventEmitter()
  @Output() keyUp: EventEmitter<any> = new EventEmitter()
  @Output() blur: EventEmitter<any> = new EventEmitter()
  @Output() clipboardDone: EventEmitter<any> = new EventEmitter()

  @ViewChild('inputElem') inputElem: ElementRef

  constructor(private _utilsService: UtilsService) {}

  ngOnInit() {
    this.inputType = this.dataType
    let pattern: any = this.pattern
    if (pattern === 'youTubeLink') {
      pattern = SHARED_CONFIG.VALIDATION_YOUTUBE_LINK
    } else if (pattern === 'email') {
      pattern = SHARED_CONFIG.EMAIL_PATTERN
    }
    // create validators
    const validators = [
      typeof this.min === 'number' ? Validators.min(this.min) : null,
      typeof this.max === 'number' ? Validators.max(this.max) : null,
      typeof this.minLength === 'number' ? Validators.minLength(this.minLength) : null,
      typeof this.maxLength === 'number' ? Validators.maxLength(this.maxLength) : null,
      this.required ? Validators.required : null,
      pattern ? Validators.pattern(pattern) : null
    ].filter(validator => validator)
    this.formControl = new FormControl(this.initValue, validators)
    // attach control to parent form
    if (this.parentForm) {
      this.controlName = this.controlName || `input_${this.type}_${this._utilsService.generateUniqueId()}`
      this.parentForm.addControl(this.controlName, this.formControl)
      this.abstractControl = this.parentForm.controls[this.controlName]
    }
    const formControl = this.parentForm ? this.abstractControl : this.formControl
    if (this.disabled) {
      formControl.disable()
    }
    formControl.valueChanges.subscribe((newValue: any) => {
      if (newValue !== this.initValue) {
        this.changeValue.emit(newValue)
        this.initValue = newValue
      }
    })

    // clipboard (init/show clipboard button only after input rendering)
    if (this.showClipboard) {
      setTimeout(() => {
        this.onClipboard = true
      })
    }
  }

  copied(ev) {
    if (this.clipboardDone) {
      const val = [this.clipboardPrefix ? this.clipboardPrefix : '', ev.content].join('')
      const selBox = document.createElement('textarea')
      selBox.style.position = 'fixed'
      selBox.style.left = '0'
      selBox.style.top = '0'
      selBox.style.opacity = '0'
      selBox.value = val
      document.body.appendChild(selBox)
      selBox.focus()
      selBox.select()
      document.execCommand('copy')
      document.body.removeChild(selBox)
      this.clipboardDone.emit(val)
    }
  }

  private _initFocusEvents() {
    this.inputElem.nativeElement.onblur = () => {
      this.focusOut()
    }

    this.inputElem.nativeElement.onfocus = () => {
      this.focusIn()
    }
  }

  keyUpEnterEvent() {
    if (this.keyUpEnter) {
      this.keyUpEnter.emit()
    }
  }

  keyUpEvent() {
    if (this.keyUp) {
      this.keyUp.emit()
    }
  }

  focusIn() {
    this.isFocused = true
  }

  focusOut() {
    this.isFocused = false
    if (this.blur) {
      this.blur.emit()
    }
  }

  togglePasswVisibility() {
    this.isPasswVisible = !this.isPasswVisible
    this.dataType = this.isPasswVisible ? 'text' : 'password'
    if (this.onPasswordVisibility) {
      this.onPasswordVisibility.emit(this.isPasswVisible)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && !changes.disabled.firstChange && typeof changes.disabled.currentValue === 'boolean') {
      const formControl = this.parentForm ? this.abstractControl : this.formControl
      changes.disabled.currentValue ? formControl.disable() : formControl.enable()
    }
    if (
      changes.initValue &&
      !changes.initValue.firstChange &&
      changes.initValue.currentValue !== changes.initValue.previousValue
    ) {
      const formControl = this.parentForm ? this.abstractControl : this.formControl
      formControl.setValue(changes.initValue.currentValue)
    }
    if (changes.isPasswordVisible && !changes.isPasswordVisible.firstChange) {
      this.dataType = changes.isPasswordVisible.currentValue ? 'text' : 'password'
    }
  }

  ngOnDestroy() {
    if (this.parentForm) {
      this.parentForm.removeControl(this.controlName)
    }
  }
}
